import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Tag extends React.Component {
    render() {
        const { children, className, inverse, type, block, ...rest } = this.props;

        const isInversed = inverse || this.context.inverse;

        return (
            <div
                className={classnames(
                    'tag',
                    { 'tag--inverse': isInversed },
                    { 'tag--block': block },
                    { [`tag--${type}`]: !!type },
                    className
                )}
                {...rest}
            >
                {children}
            </div>
        );
    }
}

Tag.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    inverse: PropTypes.bool,
    block: PropTypes.bool,
    type: PropTypes.string,
};

Tag.contextTypes = {
    inverse: PropTypes.bool,
};

export default Tag;
