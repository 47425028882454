import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { post } from 'miniruche/services/api';
import { Page, Section, Heading, Button, Input, Text } from 'miniruche/ui';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.send = this.send.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.state = {
            email: '',
            status: 'ready',
        };
    }

    onEmailChange(e) {
        this.setState({
            email: e.target.value,
        });
    }

    onSuccess() {
        this.setState({
            status: 'success',
        });
    }

    onError() {
        this.setState({
            status: 'error',
        });
    }

    send() {
        this.setState({
            status: 'ready',
        });
        post('miniruche/password_reset/', {
            email: this.state.email,
            redirectPath: new URLSearchParams(this.props.location.search).get('redirectPath'),
        }).then(this.onSuccess, this.onError);
    }

    render() {
        const { trans } = this.props;

        return (
            <Page blue>
                <Section small>
                    <Heading rank={1} size="large" className="u-spacing-stack-l">
                        {trans('login.forgotPassword')}
                    </Heading>
                    {this.state.status === 'success' ? (
                        <Text>{trans('miniruche.login.forgotPassword.success')}</Text>
                    ) : (
                        <div>
                            <Input
                                autofocus
                                className="u-spacing-stack-l"
                                label={trans('miniruche.login.forgotPassword.email')}
                                value={this.state.email}
                                onChange={this.onEmailChange}
                                errors={
                                    this.state.status === 'error'
                                        ? [trans('miniruche.login.forgotPassword.emailInvalid')]
                                        : []
                                }
                            />
                            <Button block variant="primary" onClick={this.send}>
                                {trans('miniruche.session.forgot.changePassword')}
                            </Button>
                        </div>
                    )}
                </Section>
            </Page>
        );
    }
}

ForgotPassword.propTypes = {
    trans: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
};

export default connectToI18n(({ location, trans }) => (
    <ForgotPassword location={location} trans={trans} />
));
