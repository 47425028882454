import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import Address from 'components/Address.jsx';
import SimpleMap from 'components/Maps/SimpleMap.jsx';
import { Heading, Section, Text, RevealContactInformationLink } from 'miniruche/ui';

class MinirucheMapSection extends React.Component {
    static propTypes = {
        miniruche: PropTypes.object.isRequired,
        withContactInformation: PropTypes.bool,
        zoom: PropTypes.number,
        trans: PropTypes.func.isRequired,
    };

    static defaultProps = {
        withContactInformation: false,
        zoom: 16,
    };

    render() {
        const { miniruche, withContactInformation, zoom, trans } = this.props;

        if (!miniruche.meetingPoint) {
            return null;
        }

        return (
            <div className="u-spacing-stack-m">
                <Section noVerticalPadding>
                    <Heading rank={2} size="large" className="u-spacing-stack-xs">
                        {trans('miniruche.account.address')}
                    </Heading>
                    <Address
                        address={miniruche.meetingPoint.fullAddress}
                        className="u-spacing-stack-m"
                    />
                    {withContactInformation && miniruche.contactInformation && (
                        <div className="u-spacing-stack-m">
                            <Text strong>
                                {trans('miniruche.account.facilitator')}
                                {miniruche.contactInformation.name}
                            </Text>
                            <RevealContactInformationLink
                                email={miniruche.contactInformation.email}
                                phone={miniruche.contactInformation.phone}
                            >
                                {trans('miniruche.account.contact')}
                            </RevealContactInformationLink>
                        </div>
                    )}
                </Section>
                <Section noVerticalPadding>
                    <SimpleMap
                        className="miniRucheAssemblyMap"
                        places={[{ entity: miniruche, placeType: 'pickup' }]}
                        defaultZoom={zoom}
                    />
                </Section>
            </div>
        );
    }
}

export default connectToI18n(MinirucheMapSection);
