import React from 'react';
import PropTypes from 'prop-types';
import Tag from 'miniruche/ui/content/Tag.jsx';
import classnames from 'classnames';

class FormErrors extends React.Component {
    render() {
        const { errors, className } = this.props;

        if (errors.length === 0) {
            return null;
        }

        return (
            <div className={classnames('formErrors', className)}>
                {errors.map(error => (
                    <Tag type="warning" key={error}>
                        {error}
                    </Tag>
                ))}
            </div>
        );
    }
}

FormErrors.propTypes = {
    errors: PropTypes.array,
    className: PropTypes.string,
};

FormErrors.defaultProps = {
    errors: [],
};

export default FormErrors;
