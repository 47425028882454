import { setItem, getItem } from 'modules/localstorage';
import * as R from 'ramda';
import moment from 'moment';
import { momentNow } from 'models/date';

function getPickups() {
    const pickupsJSON = getItem('miniruche_pickups');
    return pickupsJSON ? JSON.parse(pickupsJSON) : [];
}

function setPickups(pickups) {
    setItem('miniruche_pickups', JSON.stringify(pickups));
}

// Prevents filling the localStorage indefinitely
function deleteExpiredPickups() {
    const pickups = getPickups();
    const filteredPickups = R.pickBy(entry => {
        return moment(entry.expirationDate).isAfter();
    }, pickups);
    setPickups(filteredPickups);
}

export function fetchPickups(saleId) {
    deleteExpiredPickups();
    const pickups = getPickups();
    const salePickups = pickups[saleId] ? pickups[saleId].pickups : {};
    return Promise.resolve(salePickups);
}

export function savePickups(saleId, pickups) {
    const savedPickups = getPickups();
    savedPickups[saleId] = {
        expirationDate: momentNow()
            .add(1, 'week')
            .format(),
        pickups,
    };
    setPickups(savedPickups);
    return Promise.resolve();
}
