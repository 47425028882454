import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Section, Heading, Split, Grow, Button, Text, Input } from 'miniruche/ui';
import classnames from 'classnames';
import { wait } from 'modules/utils/promise';

class EmailForm extends React.Component {
    static propTypes = {
        trans: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
        canGoToNextStep: PropTypes.bool.isRequired,
        currentStep: PropTypes.number.isRequired,
        stepCount: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        lookupEmail: PropTypes.func.isRequired,
        errors: PropTypes.array,
    };

    static defaultProps = {
        errors: [],
    };

    state = {
        status: 'INITIAL_LOADING',
    };

    componentDidMount() {
        // Wait 1ms to make sure the transition is triggered.
        wait(1).then(() => {
            this.setState({
                status: 'READY',
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.status === 'LOADING') {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                status: 'READY',
            });
        }
    }

    onChange = event => {
        this.props.onChange(event.target.value);
    };

    submit = () => {
        this.props.lookupEmail(this.props.email);
        this.setState({
            status: 'LOADING',
        });
    };

    render() {
        const {
            trans,
            previous,
            canGoToNextStep,
            currentStep,
            stepCount,
            email,
            errors,
        } = this.props;

        return (
            <div
                className={classnames('u-fade', {
                    'u-fade-in': this.state.status !== 'INITIAL_LOADING',
                })}
            >
                <Section small>
                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                        {currentStep}/{stepCount}
                        <br />
                        {trans('miniruche.email.heading')}
                    </Heading>
                    <Text className="u-spacing-stack-m">{trans('miniruche.email.text')}</Text>
                </Section>
                <Section small>
                    <Input autofocus value={email} onChange={this.onChange} errors={errors} />
                </Section>
                <Section small>
                    <Split gutter="medium">
                        <Grow weight={0}>
                            <Button onClick={previous} variant="outline" icon="angle-left" />
                        </Grow>
                        <Grow weight={1} className="u-right">
                            <Button
                                block
                                variant="primary"
                                onClick={canGoToNextStep ? this.submit : null}
                                disabled={!canGoToNextStep || this.state.status === 'LOADING'}
                            >
                                {trans('miniruche.create.nextStep')}
                            </Button>
                        </Grow>
                    </Split>
                </Section>
            </div>
        );
    }
}

export default connectToI18n(EmailForm);
