import 'app/scripts/polyfills';
import React from 'react';
import PropType from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import installI18nContext from 'modules/i18n/installI18nContext';
import Sales from './pages/sales/Sales.jsx';
import Create from './pages/create/Create.jsx';
import CreationSuccess from './pages/create/success/Success.jsx';
import Share from './pages/create/share/Share.jsx';
import Assemblies from './pages/admin/assemblies/Assemblies.jsx';
import AdminSales from './pages/admin/sales/Sales.jsx';
import AdminSale from './pages/admin/sales/SaleContainer.jsx';
import Pickup from './pages/admin/sales/Pickup.jsx';
import Edit from './pages/admin/edit/Edit.jsx';
import Login from './pages/login/Login.jsx';
import ForgotPassword from './pages/login/ForgotPassword.jsx';
import ChangePassword from './pages/login/ChangePassword.jsx';
import Register from './pages/register/Register.jsx';
import Examples from './ui/examples/Examples.jsx';
import ShoppingApp from './modules/shopping';
import './TemporaryI18n';

/* eslint react/no-multi-comp: 0 */
const CreateWithStandaloneAddressFrom = props => <Create standaloneAddress {...props} />;

class App extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={Create} />
                <Route exact path="/create" component={CreateWithStandaloneAddressFrom} />
                <Route exact path="/new" component={Create} />
                <Route path="/examples" component={Examples} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/change-password/:userId/:token" component={ChangePassword} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/new/:slug/created" component={CreationSuccess} />
                <Route exact path="/new/:slug/share" component={Share} />
                <Route exact path="/:slug/admin/edit" component={Edit} />
                <Route exact path="/:slug/admin/assemblies" component={Assemblies} />
                <Route exact path="/:slug/admin/sales" component={AdminSales} />
                <Route exact path="/:slug/admin/sales/:saleId" component={AdminSale} />
                <Route exact path="/:slug/admin/sales/:saleId/pickup" component={Pickup} />
                <Route exact path="/:slug" component={Sales} />
                <Route path="/:slug/sales/:saleId" component={ShoppingApp} />
            </Switch>
        );
    }
}

App.propTypes = {
    i18n: PropType.string,
};

export default installI18nContext(App);
