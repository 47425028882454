import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import classnames from 'classnames';
import Split from './Split.jsx';
import Tab from './Tab.jsx';

export class TabSet extends React.Component {
    static propTypes = {
        tabs: PropTypes.array.isRequired,
        value: PropTypes.string,
        inverse: PropTypes.bool,
        onChange: PropTypes.func,
        className: PropTypes.string,
    };

    static contextTypes = {
        inverse: PropTypes.bool,
    };

    setActiveTab = value => {
        this.props.onChange(value);
    };

    render() {
        const { tabs, value, inverse, className } = this.props;

        const isInversed = inverse || this.context.inverse;

        return (
            <Split className={classnames('tabset', { 'tabset--inverse': isInversed }, className)}>
                {tabs.map(option => {
                    return (
                        <Tab
                            key={option.value}
                            value={option.value}
                            label={option.label}
                            isActive={option.value === value}
                            setActiveTab={this.setActiveTab}
                        />
                    );
                })}
            </Split>
        );
    }
}

export default connectToI18n(TabSet);
