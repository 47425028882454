import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import LoginForm from 'miniruche/components/session/LoginForm.jsx';
import KycInfoForm from 'miniruche/components/session/KycInfoForm.jsx';
import RegisterForm from 'miniruche/components/session/RegisterForm.jsx';
import ForgotPasswordModal from 'miniruche/components/session/ForgotPasswordModal.jsx';
import { grant } from 'modules/oauth';
import { Section, Heading, Split, Grow, Button, Text, Link } from 'miniruche/ui';
import classnames from 'classnames';
import { patch, post } from 'miniruche/services/api';
import { fetchUser } from 'miniruche/services/user/user.service';
import { wait } from 'modules/utils/promise';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';

class LoginOrRegisterForm extends React.Component {
    static propTypes = {
        trans: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
        email: PropTypes.string.isRequired,
        save: PropTypes.func.isRequired,
        emailExists: PropTypes.bool.isRequired,
        currentStep: PropTypes.number.isRequired,
        stepCount: PropTypes.number.isRequired,
    };

    state = {
        status: 'INITIAL_LOADING',
        password: '',
        firstName: '',
        lastName: '',
        street: '',
        city: {
            zipCode: '',
            id: null,
            name: null,
        },
        birthday: {
            day: '',
            month: '',
            year: '',
        },
        nationality: 'FR',
        errors: {},
        isModalVisible: false,
        isLoggedIn: false,
        hasValidKycInfo: false,
        userId: null,
    };

    componentDidMount() {
        // Wait 1ms to make sure the transition is triggered.
        wait(1).then(() => this.setState({ status: 'READY' }));
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    };

    next = () => {
        this.setState({ status: 'LOADING' });

        const { emailExists } = this.props;
        const { isLoggedIn, hasValidKycInfo } = this.state;

        if (emailExists && !isLoggedIn) {
            this.login();
        } else if (emailExists && isLoggedIn && !hasValidKycInfo) {
            this.patchUser();
        } else {
            this.register();
        }
    };

    login = () => {
        grant('password', {
            username: this.props.email,
            password: this.state.password,
        }).then(
            () => {
                addUniversalEvent({
                    eventCategory: 'Onboarding',
                    eventAction: 'logged_in',
                    eventLabel: 'signed_in',
                });
                fetchUser().then(user => {
                    const hasValidKycInfo = R.none(
                        R.isNil,
                        R.props(['birthday', 'nationality'], user)
                    );
                    if (hasValidKycInfo) {
                        this.props.save();
                    } else {
                        this.setState({
                            status: 'READY',
                            isLoggedIn: true,
                            hasValidKycInfo: false,
                            userId: R.prop('id', user),
                        });
                    }
                });
            },
            () => {
                this.setState(previousState => ({
                    status: 'READY',
                    errors: {
                        ...previousState.errors,
                        login: ['Mot de passe incorrect'],
                    },
                }));
            }
        );
    };

    patchUser = () => {
        patch(`users/${this.state.userId}`, {
            birthday: `${this.state.birthday.year}-${this.state.birthday.month}-${this.state.birthday.day}`,
            nationality: this.state.nationality,
        }).then(
            () => {
                this.props.save();
            },
            err => {
                if (err.status === 400) {
                    const errors = R.pipe(
                        R.path(['responseJSON', 'detail', 'errors']),
                        R.defaultTo({})
                    )(err);
                    this.setState({
                        errors,
                        status: 'READY',
                    });
                }
            }
        );
    };

    register = () => {
        post('users/', {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.props.email,
            plainPassword: this.state.password,
            hasAcceptedTOS: true,
            address: {
                city: this.state.city.id,
                street: this.state.street,
            },
            birthday: `${this.state.birthday.year}-${this.state.birthday.month}-${this.state.birthday.day}`,
            nationality: this.state.nationality,
        }).then(
            () => {
                grant('password', {
                    username: this.props.email,
                    password: this.state.password,
                }).then(() => {
                    addUniversalEvent({
                        eventCategory: 'Onboarding',
                        eventAction: 'logged_in',
                        eventLabel: 'signed_up',
                    });
                    this.props.save();
                });
            },
            err => {
                if (err.status === 400) {
                    const errors = R.pipe(
                        R.path(['responseJSON', 'detail', 'errors']),
                        R.defaultTo({})
                    )(err);
                    this.setState({
                        errors,
                        status: 'READY',
                    });
                }
            }
        );
    };

    showModal = () => {
        this.setState({ isModalVisible: true });
    };

    hideModal = () => {
        this.setState({ isModalVisible: false });
    };

    renderFormSection = () => {
        const { email, emailExists, currentStep, stepCount, trans } = this.props;

        const {
            password,
            firstName,
            lastName,
            street,
            city,
            birthday,
            nationality,
            errors,
            isLoggedIn,
            hasValidKycInfo,
        } = this.state;

        if (emailExists && !isLoggedIn) {
            return (
                <Section small>
                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                        {currentStep}/{stepCount}
                        <br />
                        {trans('miniruche.account.connection')}
                    </Heading>
                    <Text className="u-spacing-stack-s">{trans('miniruche.account.already')}</Text>
                    <Text className="u-spacing-stack-xl">
                        {trans('miniruche.account.fillpassword')}
                    </Text>
                    <LoginForm
                        className="u-spacing-stack-m"
                        email={email}
                        password={password}
                        onChange={this.onChange}
                        errors={errors.login}
                        hideEmail
                        hasTitle
                    />
                    <Link onClick={this.showModal}>{trans('login.forgotPassword')}</Link>
                </Section>
            );
        }

        if (emailExists && isLoggedIn && !hasValidKycInfo) {
            return (
                <Section small>
                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                        {currentStep}/{stepCount}
                        <br />
                        {trans('miniruche.account.moredata')}
                    </Heading>
                    <Text className="u-spacing-stack-xl">{trans('miniruche.account.kyc')}</Text>
                    <KycInfoForm
                        onChange={this.onChange}
                        errors={errors}
                        birthday={birthday}
                        nationality={nationality}
                    />
                </Section>
            );
        }

        return (
            <Section small>
                <Heading rank={1} size="large" className="u-spacing-stack-xl">
                    {currentStep}/{stepCount}
                    <br />
                    {trans('miniruche.account.title')}
                </Heading>
                <RegisterForm
                    onChange={this.onChange}
                    firstName={firstName}
                    lastName={lastName}
                    street={street}
                    city={city}
                    email={email}
                    password={password}
                    errors={errors}
                    birthday={birthday}
                    nationality={nationality}
                    hideEmail
                />
            </Section>
        );
    };

    render() {
        const { trans, previous } = this.props;

        const { status, isModalVisible } = this.state;

        return (
            <div className={classnames('u-fade', { 'u-fade-in': status !== 'INITIAL_LOADING' })}>
                {this.renderFormSection()}
                <Section small>
                    <Split gutter="medium">
                        <Grow weight={0}>
                            <Button onClick={previous} variant="outline" icon="angle-left" />
                        </Grow>
                        <Grow weight={1} className="u-right">
                            <Button
                                block
                                variant="primary"
                                onClick={this.next}
                                disabled={status === 'LOADING'}
                            >
                                {trans('miniruche.create.nextStep')}
                            </Button>
                        </Grow>
                    </Split>
                </Section>
                {isModalVisible && (
                    <ForgotPasswordModal
                        email={this.props.email}
                        redirectPath="/new"
                        close={this.hideModal}
                    />
                )}
            </div>
        );
    }
}

export default connectToI18n(LoginOrRegisterForm);
