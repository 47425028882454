import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import AssemblyBox from 'miniruche/components/assembly/AssemblyBox.jsx';
import AssemblyModal from 'miniruche/components/assembly/AssemblyModal.jsx';
import AssembliesSearch from 'miniruche/components/assembly/AssembliesSearch.jsx';
import classnames from 'classnames';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';
import { wait } from 'modules/utils/promise';
import { Section, Heading, Button, Text, Link } from 'miniruche/ui';

export class AssembliesForm extends React.Component {
    static propTypes = {
        nearbyAssemblies: PropTypes.array.isRequired,
        selectedAssemblies: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        canGoToNextStep: PropTypes.bool.isRequired,
        currentStep: PropTypes.number.isRequired,
        stepCount: PropTypes.number.isRequired,
    };

    state = {
        status: 'INITIAL_LOADING',
        assemblyModal: null,
        isSearchVisible: false,
        searchResults: [],
    };

    componentDidMount() {
        // Wait 1ms to make sure the transition is triggered.
        wait(1).then(() => {
            this.setState({
                status: 'READY',
            });
        });
    }

    showSearch = () => {
        addUniversalEvent({
            eventCategory: 'Onboarding',
            eventAction: 'search_other_ruche',
        });
        this.setState({
            isSearchVisible: true,
        });
    };

    showResults = searchResults => {
        this.setState({
            searchResults,
        });
    };

    showModal = assemblyUuid => {
        const searchResultAssemblyIndex = R.findIndex(
            R.propEq('uuid', assemblyUuid),
            this.state.searchResults
        );
        if (searchResultAssemblyIndex !== -1) {
            return this.setState({
                assemblyModal: this.state.searchResults[searchResultAssemblyIndex],
            });
        }
        const assemblyIndex = R.findIndex(
            R.propEq('uuid', assemblyUuid),
            this.props.nearbyAssemblies
        );
        return this.setState({
            assemblyModal: this.props.nearbyAssemblies[assemblyIndex],
        });
    };

    hideModal = () => {
        this.setState({
            assemblyModal: null,
        });
    };

    addRelation = assemblyUuid => {
        const searchResultAssemblyIndex = R.findIndex(
            R.propEq('uuid', assemblyUuid),
            this.state.searchResults
        );
        const nearbyAssemblyIndex = R.findIndex(
            R.propEq('uuid', assemblyUuid),
            this.props.nearbyAssemblies
        );
        this.props.onChange(R.append(assemblyUuid, this.props.selectedAssemblies));
    };

    removeRelation = assemblyUuid => {
        const searchResultAssemblyIndex = R.findIndex(
            R.propEq('uuid', assemblyUuid),
            this.state.searchResults
        );
        const nearbyAssemblyIndex = R.findIndex(
            R.propEq('uuid', assemblyUuid),
            this.props.nearbyAssemblies
        );
        this.props.onChange(R.reject(R.equals(assemblyUuid), this.props.selectedAssemblies));
    };

    render() {
        const {
            nearbyAssemblies,
            selectedAssemblies,
            trans,
            next,
            canGoToNextStep,
            currentStep,
            stepCount,
        } = this.props;

        return (
            <div className={classnames('u-fade', { 'u-fade-in': this.state.status === 'READY' })}>
                <Section small>
                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                        {currentStep}/{stepCount}
                        <br />
                        {trans('miniruche.assemblies.hives')}
                    </Heading>
                    <Text className="u-spacing-stack-m">{trans('miniruche.assemblies.desc')}</Text>
                </Section>
                <Section small noPadding>
                    {nearbyAssemblies.map(assembly => {
                        return (
                            <AssemblyBox
                                key={assembly.uuid}
                                assembly={assembly}
                                showModal={this.showModal}
                                addAssembly={this.addRelation}
                                removeAssembly={this.removeRelation}
                                added={R.contains(assembly.uuid, selectedAssemblies)}
                                className="u-spacing-stack-m"
                            />
                        );
                    })}
                </Section>
                <Section small>
                    <div className="u-center u-spacing-stack-l">
                        {trans('global.or')}{' '}
                        <Link onClick={this.showSearch}>
                            {trans('miniruche.assemblies.search')}
                        </Link>
                    </div>
                    {this.state.isSearchVisible && (
                        <AssembliesSearch
                            searchResults={this.state.searchResults}
                            showResults={this.showResults}
                            onSearch={this.onSearch}
                            showModal={this.showModal}
                            addAssembly={this.addRelation}
                            deleteAssembly={this.removeRelation}
                            assembliesCurrentlyAdded={R.fromPairs(
                                selectedAssemblies.map(a => [a, true])
                            )}
                        />
                    )}
                </Section>
                <Section small>
                    <Button
                        block
                        variant="primary"
                        onClick={canGoToNextStep ? next : null}
                        disabled={!canGoToNextStep}
                    >
                        {trans('miniruche.create.nextStep')}
                    </Button>
                </Section>
                {this.state.assemblyModal && (
                    <AssemblyModal assembly={this.state.assemblyModal} hideModal={this.hideModal} />
                )}
            </div>
        );
    }
}

export default connectToI18n(AssembliesForm);
