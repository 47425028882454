import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import * as R from 'ramda';
import { SAVING } from 'modules/utils/ajaxStatuses';
import { Button, Heading, Input, Switch, FormErrors } from 'miniruche/ui';
import { dayName } from './timeslot.model';

export class SingleTimeSlotSetting extends React.Component {
    static propTypes = {
        availableAssemblies: PropTypes.array.isRequired,
        availableDays: PropTypes.array.isRequired,
        errors: PropTypes.array.isRequired,
        onDeleteTimeSlot: PropTypes.func.isRequired,
        onSaveTimeSlot: PropTypes.func.isRequired,
        status: PropTypes.string.isRequired,
        timeslot: PropTypes.object,
        i18n: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        assemblies: this.props.timeslot ? this.props.timeslot.assemblies : [],
        day: this.props.timeslot
            ? this.props.timeslot.day
            : R.prop('value', R.head(this.props.availableDays)),
        timeRange: this.props.timeslot
            ? this.props.timeslot.timeRange
            : {
                  start: '12:00:00',
                  end: '13:00:00',
              },
    };

    onSelectDay = day => {
        this.setState({ day });
    };

    onSelectTimeRange = timeRange => {
        this.setState({ timeRange });
    };

    onSwitchAssembly = (uuid, checked) => {
        this.setState(R.evolve({ assemblies: checked ? R.append(uuid) : R.without([uuid]) }));
    };

    onClickSave = () => {
        const { assemblies, day, timeRange } = this.state;

        this.props.onSaveTimeSlot(day, timeRange, assemblies);
    };

    onClickDelete = () => {
        const { day } = this.state;

        this.props.onDeleteTimeSlot(day);
    };

    render() {
        const {
            availableAssemblies,
            availableDays,
            errors,
            status,
            timeslot,
            trans,
            i18n,
        } = this.props;

        const { assemblies, day, timeRange } = this.state;

        return (
            <div>
                <Heading rank={2} size="medium" className="u-spacing-stack-m">
                    {timeslot
                        ? trans('miniruche.admin.singleTimeSlot.headingWithDay', {
                              '%dayName%': dayName(day, i18n).toLowerCase(),
                          })
                        : trans('miniruche.admin.singleTimeSlot.headingWithoutDay')}
                </Heading>
                {!timeslot && (
                    <Input
                        type="dropdown"
                        options={availableDays}
                        value={day}
                        onChange={event => this.onSelectDay(event.target.value)}
                    />
                )}
                <Input
                    type="timerange"
                    intervalInMinutes={15}
                    value={timeRange}
                    onChange={obj => this.onSelectTimeRange(obj.value)}
                />
                <Heading rank={2} size="medium" className="u-spacing-stack-m">
                    {trans('miniruche.admin.singleTimeSlot.assemblies')}
                </Heading>
                <div className="u-spacing-stack-l">
                    {R.pipe(
                        R.sortBy(R.prop('name')),
                        R.map(assembly => (
                            <div key={assembly.uuid} className="u-spacing-stack-xs">
                                <Switch
                                    name={assembly.uuid}
                                    label={assembly.name}
                                    value={R.contains(assembly.uuid, assemblies)}
                                    onChange={checked =>
                                        this.onSwitchAssembly(assembly.uuid, checked)
                                    }
                                    size="small"
                                />
                            </div>
                        ))
                    )(availableAssemblies)}
                </div>
                <Button
                    block
                    variant="primary"
                    disabled={status === SAVING}
                    onClick={this.onClickSave}
                    className="u-spacing-stack-s"
                >
                    {trans('global.saveChanges')}
                </Button>
                {timeslot && (
                    <Button
                        block
                        variant="warning"
                        disabled={status === SAVING}
                        onClick={this.onClickDelete}
                        className="u-spacing-stack-s"
                    >
                        {trans('miniruche.admin.singleTimeSlot.removeSlot')}
                    </Button>
                )}
                <div className="u-center">
                    <FormErrors errors={errors} />
                </div>
            </div>
        );
    }
}

export default connectToI18n(SingleTimeSlotSetting);
