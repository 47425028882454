import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Text } from 'miniruche/ui';

class LikeAuthors extends React.Component {
    render() {
        const { filteredUser, authors, ...rest } = this.props;

        const featuredAuthor = R.find(R.complement(R.equals(filteredUser)), authors);
        const otherAuthorsCount = authors.length - 1;

        if (!featuredAuthor) {
            return null;
        }

        if (otherAuthorsCount === 0) {
            return (
                <Text type="success" {...rest}>
                    {featuredAuthor} recommande ce produit.
                </Text>
            );
        }

        if (otherAuthorsCount === 1) {
            return (
                <Text type="success" {...rest}>
                    {featuredAuthor} et 1 autre personne recommandent ce produit.
                </Text>
            );
        }

        return (
            <Text type="success" {...rest}>
                {featuredAuthor} et {otherAuthorsCount} autres personnes recommandent ce produit.
            </Text>
        );
    }
}

LikeAuthors.propTypes = {
    filteredUser: PropTypes.string,
    authors: PropTypes.array,
};

LikeAuthors.defaultProps = {
    filteredUser: '',
    authors: [],
};

export default LikeAuthors;
