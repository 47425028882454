import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import superToggle from 'miniruche/hoc/superToggle';

export class MultipleInputOption extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        toggle: PropTypes.func.isRequired,
        isSelected: PropTypes.bool,
    };

    static contextTypes = {
        inverse: PropTypes.bool,
    };

    onClick = () => {
        this.props.toggle('isSelected');
    };

    render() {
        const { label, isSelected } = this.props;

        return (
            <button
                type="button"
                className={classnames('multipleInputOption', {
                    'multipleInputOption--selected': isSelected,
                    'multipleInputOption--inverse': this.context.inverse,
                })}
                onClick={this.onClick}
            >
                {label}
            </button>
        );
    }
}

export default superToggle(MultipleInputOption);
