import { get } from 'miniruche/services/api';
import * as R from 'ramda';

const sortProductTypes = R.sortWith([
    R.ascend(R.prop('subCategoryName')),
    R.ascend(R.prop('name')),
]);

const filterByCategoryId = categoryId => {
    if (!categoryId) {
        return R.identity;
    }
    return R.filter(R.propEq('categoryId', categoryId));
};

export function fetchProductTypes(payload) {
    const { saleId, categoryId } = payload;

    return get(`distributions/${saleId}/product_types`).then(
        R.pipe(R.prop('ProductTypes'), filterByCategoryId(categoryId), sortProductTypes)
    );
}
