import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import Quantity from 'components/Offers/Quantity.jsx';
import { capitalizeFirstLetterOnly } from 'modules/utils/string';
import { Text, GroupItem, Split, Grow, CheckButton } from 'miniruche/ui';

export class PickupItem extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.string.isRequired,
        item: PropTypes.object.isRequired,
        pickupAll: PropTypes.func.isRequired,
        releaseAll: PropTypes.func.isRequired,
        wasPickedUp: PropTypes.bool,
    };

    toggleWasPickedUp = () => {
        if (this.props.wasPickedUp) {
            this.props.releaseAll(this.props.item.orderItems);
        } else {
            this.props.pickupAll(this.props.item.orderItems);
        }
    };

    render() {
        const { wasPickedUp, item, i18n } = this.props;

        return (
            <GroupItem className="u-clickable" onClick={this.toggleWasPickedUp}>
                <Split gutter="medium">
                    <Grow weight={0}>
                        <CheckButton small checked={wasPickedUp} />
                    </Grow>
                    <Grow weight={1}>
                        <Text className="u-spacing-stack-xxs">
                            {item.quantity} × {capitalizeFirstLetterOnly(item.product.name)}
                        </Text>
                        <Text>
                            <Quantity
                                offer={item.offer}
                                strategy={item.offer.quantityStrategy}
                                i18n={i18n}
                            />
                        </Text>
                    </Grow>
                </Split>
            </GroupItem>
        );
    }
}

export default connectToI18n(PickupItem);
