import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function alignSelfFromProp(align) {
    if (align === 'top' || align === 'left') {
        return 'flex-start';
    }
    if (align === 'bottom' || align === 'right') {
        return 'flex-end';
    }
    if (align === 'center') {
        return 'center';
    }
    return null;
}

class Grow extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        weight: PropTypes.number,
        align: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        weight: 1,
    };

    render() {
        const { children, weight, align, className, ...rest } = this.props;

        return (
            <div
                className={classnames({ grow: weight > 0 }, className)}
                style={{ flexGrow: weight, alignSelf: alignSelfFromProp(align) }}
                {...rest}
            >
                {children}
            </div>
        );
    }
}

export default Grow;
