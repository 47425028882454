import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import * as R from 'ramda';
import superToggle from 'miniruche/hoc/superToggle';
import { smsLink } from 'modules/utils/links';
import Participant from 'miniruche/components/participant/Participant.jsx';
import { Heading, Button, GroupItem, Split, Grow } from 'miniruche/ui';

export class SaleParticipants extends React.Component {
    static propTypes = {
        className: PropTypes.string.isRequired,
        sale: PropTypes.object.isRequired,
        isExpanded: PropTypes.bool,
        toggle: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
    };

    defaultProps = {
        isExpanded: false,
    };

    toggleIsExpanded = () => {
        this.props.toggle('isExpanded');
    };

    render() {
        const { className, sale, isExpanded, trans } = this.props;

        if (sale.participantsWhoOrdered.length === 0) {
            return null;
        }

        const phoneNumbers = R.pipe(R.pluck('phone'), R.uniq)(sale.participantsWhoOrdered);

        return (
            <div className={className}>
                <Heading rank={2} size="medium" className="u-spacing-stack-m">
                    {trans('miniruche.sale.participants.heading', {
                        '%participantsCount%': sale.participantsWhoOrdered.length,
                    })}
                </Heading>
                <div>
                    <GroupItem className="u-clickable" onClick={this.toggleIsExpanded}>
                        <Split align="center">
                            <Grow weight={1}>{trans('miniruche.sale.participants.see')}</Grow>
                            <Grow weight={0}>
                                <Button
                                    small
                                    icon={isExpanded ? 'angle-up' : 'angle-down'}
                                    variant="clear"
                                />
                            </Grow>
                        </Split>
                    </GroupItem>
                    {isExpanded && phoneNumbers.length > 0 && (
                        <GroupItem className="u-hidden-desktop">
                            <Button
                                block
                                variant="outline"
                                icon="share-sms"
                                target="_blank"
                                href={smsLink(phoneNumbers)}
                            >
                                {trans('miniruche.sale.participants.writeAll')}
                            </Button>
                        </GroupItem>
                    )}
                    {isExpanded &&
                        sale.participantsWhoOrdered.map(participant => {
                            return (
                                <GroupItem>
                                    <Participant participant={participant} />
                                </GroupItem>
                            );
                        })}
                </div>
            </div>
        );
    }
}

export default connectToI18n(superToggle(SaleParticipants));
