import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getTimesWithInterval, getLocalizedTime } from 'modules/utils/dateAndTime';
import Dropdown from './Dropdown.jsx';

function timeSequenceToDropdownOptions(timeSequence, locale) {
    return timeSequence.map(time => {
        return {
            value: time,
            label: getLocalizedTime(locale, time),
        };
    });
}

export class TimeInput extends React.Component {
    static propTypes = {
        intervalInMinutes: PropTypes.number,
        i18n: PropTypes.string.isRequired,
    };

    static defaultProps = {
        intervalInMinutes: 30,
    };

    constructor(props) {
        super(props);
        const timeSequence = getTimesWithInterval(props.intervalInMinutes);
        this.state = {
            options: timeSequenceToDropdownOptions(timeSequence, props.i18n),
        };
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.intervalInMinutes !== this.props.intervalInMinutes ||
            prevProps.i18n !== this.props.i18n
        ) {
            const timeSequence = getTimesWithInterval(this.props.intervalInMinutes);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                options: timeSequenceToDropdownOptions(timeSequence, this.props.i18n),
            });
        }
    }
    render() {
        const { options } = this.state;

        return <Dropdown {...this.props} options={options} />;
    }
}

export default connectToI18n(TimeInput);
