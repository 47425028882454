import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import Quantity from 'components/Offers/Quantity.jsx';
import { Heading, Modal, Text, ListItem, Split, Grow, Button, Link } from 'miniruche/ui';

class UnavailableProductsModal extends React.Component {
    render() {
        const { orderItems, close, saleURL, confirmOrder, transChoice, trans } = this.props;

        return (
            <Modal
                close={close}
                title={
                    <Heading rank={2} size="medium">
                        {transChoice('miniruche.shopping.unavailableProducts', orderItems.length)}
                    </Heading>
                }
            >
                <Text className="u-spacing-stack-s">
                    {transChoice('miniruche.shopping.lowStock', orderItems.length)}
                </Text>
                <Text className="u-spacing-stack-m">
                    {trans('miniruche.shopping.modifiedBasket')}
                </Text>
                <div className="u-spacing-stack-m">
                    {orderItems.map(orderItem => {
                        return (
                            <ListItem key={orderItem.offer.id}>
                                <Split>
                                    <Grow weight={1}>
                                        <Text linethrough>
                                            {orderItem.quantity + orderItem.currentQuantity}
                                        </Text>
                                    </Grow>
                                    <Grow weight={8}>
                                        <Text strong>
                                            {orderItem.currentQuantity} × {orderItem.product.name}
                                        </Text>
                                        <Text>{orderItem.farmName}</Text>
                                    </Grow>
                                    <Grow weight={4} className="u-right">
                                        <Text>
                                            <Quantity
                                                offer={orderItem.offer}
                                                strategy={orderItem.quantityStrategy}
                                            />
                                        </Text>
                                    </Grow>
                                </Split>
                            </ListItem>
                        );
                    })}
                </div>
                <Text className="u-spacing-stack-l">
                    {trans('miniruche.shopping.validateBasket')}
                </Text>
                <Button
                    block
                    className="u-spacing-stack-m"
                    variant="primary"
                    onClick={confirmOrder}
                >
                    {trans('basket.stockAlert.validateBasket')}
                </Button>
                <div className="u-center u-spacing-stack-s">
                    <Link to={saleURL}>{trans('products.productPage.backToSale')}</Link>
                </div>
            </Modal>
        );
    }
}

UnavailableProductsModal.propTypes = {
    orderItems: PropTypes.array.isRequired,
    close: PropTypes.func.isRequired,
    saleURL: PropTypes.string.isRequired,
    confirmOrder: PropTypes.func.isRequired,
    transChoice: PropTypes.func.isRequired,
    trans: PropTypes.func.isRequired,
};

export default connectToI18n(UnavailableProductsModal);
