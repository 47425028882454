import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { capitalizeFirstLetter } from 'modules/utils/string';
import { getLocalizedTime, getLocalizedDate } from 'modules/utils/dateAndTime';
import PageHeader from 'miniruche/ui/layout/PageHeader.jsx';
import SaleStats from 'miniruche/components/sale/SaleStats.jsx';
import SaleParticipants from 'miniruche/components/sale/SaleParticipants.jsx';
import ConveyorsBox from 'miniruche/components/sale/ConveyorsBox.jsx';
import ConveyorsModal from 'miniruche/components/sale/ConveyorsModal.jsx';
import PauseSection from 'miniruche/components/sale/PauseSection.jsx';
import NoParticipants from 'miniruche/components/sale/NoParticipants.jsx';
import { Page, Section } from 'miniruche/ui';

export class Sale extends React.Component {
    static propTypes = {
        i18n: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        sale: PropTypes.object.isRequired,
        addConveyor: PropTypes.func.isRequired,
        removeConveyor: PropTypes.func.isRequired,
        togglePause: PropTypes.func.isRequired,
        showConveyorsModal: PropTypes.func.isRequired,
        closeConveyorsModal: PropTypes.func.isRequired,
        isConveyorsModalVisible: PropTypes.bool.isRequired,
        potentialConveyors: PropTypes.array.isRequired,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const {
            sale,
            addConveyor,
            removeConveyor,
            togglePause,
            showConveyorsModal,
            closeConveyorsModal,
            isConveyorsModalVisible,
            potentialConveyors,
            trans,
            i18n,
            slug,
        } = this.props;

        if (sale.orderCount === 0) {
            return (
                <Page blue>
                    <PageHeader
                        title={capitalizeFirstLetter(
                            trans('miniruche.assembly.distributionTimeWithDay', {
                                '%day%': getLocalizedDate(i18n, 'I', sale.date),
                                '%start%': getLocalizedTime(
                                    i18n,
                                    sale.assembly.timeslots[0].starting
                                ),
                                '%end%': getLocalizedTime(i18n, sale.assembly.timeslots[0].ending),
                            })
                        )}
                        backHref={`/${slug}/admin/sales`}
                        backText={trans('miniruche.admin.assemblies.backToDashboard')}
                    />
                    <Section>
                        <NoParticipants slug={slug} className="u-spacing-stack-l" />
                        <PauseSection sale={sale} togglePause={togglePause} />
                    </Section>
                </Page>
            );
        }

        return (
            <Page blue>
                <PageHeader
                    title={capitalizeFirstLetter(
                        trans('miniruche.assembly.distributionTimeWithDay', {
                            '%day%': getLocalizedDate(i18n, 'I', sale.date),
                            '%start%': getLocalizedTime(i18n, sale.assembly.timeslots[0].starting),
                            '%end%': getLocalizedTime(i18n, sale.assembly.timeslots[0].ending),
                        })
                    )}
                    backHref={`/${slug}/admin/sales`}
                    backText={trans('miniruche.admin.assemblies.backToDashboard')}
                />
                <Section>
                    <SaleParticipants sale={sale} className="u-spacing-stack-l" />
                    <SaleStats sale={sale} className="u-spacing-stack-l" />
                    <div className="u-spacing-stack-l">
                        <ConveyorsBox sale={sale} showConveyorsModal={showConveyorsModal} />
                    </div>
                    {sale.status === 'sale.opened' && (
                        <PauseSection sale={sale} togglePause={togglePause} />
                    )}
                    {isConveyorsModalVisible && (
                        <ConveyorsModal
                            sale={sale}
                            close={closeConveyorsModal}
                            addConveyor={addConveyor}
                            removeConveyor={removeConveyor}
                            potentialConveyors={potentialConveyors}
                        />
                    )}
                </Section>
            </Page>
        );
    }
}

export default connectToI18n(Sale);
