import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import ConveyorsList from 'miniruche/components/sale/ConveyorsList.jsx';
import { Box, Text, Button } from 'miniruche/ui';

export class ConveyorsBox extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        showConveyorsModal: PropTypes.func.isRequired,
        sale: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
    };

    render() {
        const { className, showConveyorsModal, sale, trans, transChoice } = this.props;

        return (
            <Box className={className}>
                <Text strong className="u-spacing-stack-s">
                    {trans('miniruche.sale.conveyorsBox.text')}
                </Text>
                <div className="u-spacing-stack-m">
                    <ConveyorsList conveyors={sale.conveyors} />
                    {transChoice('miniruche.sale.conveyorsBox.details', sale.conveyors.length)}
                </div>
                <Button
                    block
                    variant="primary"
                    onClick={showConveyorsModal}
                    className="u-spacing-stack-m"
                    disabled={
                        !R.contains(sale.status, [
                            'sale.opened',
                            'sale.finalization',
                            'distribution.opened',
                        ])
                    }
                >
                    {trans('miniruche.sale.conveyorsBox.organize')}
                </Button>
                <Text size="small">{trans('miniruche.sale.participation')}</Text>
            </Box>
        );
    }
}

export default connectToI18n(ConveyorsBox);
