import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { clear, grant } from 'modules/oauth';
import Impersonate from 'modules/impersonate';
import { READY, SAVING } from 'modules/utils/ajaxStatuses';
import { captureException } from 'modules/error-reporting';
import {
    Page,
    Section,
    Heading,
    Split,
    Grow,
    Link,
    Button,
    Text,
    FormField,
    FormErrors,
    FormLabel,
} from 'miniruche/ui';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.state = {
            error: null,
            status: READY,
        };
    }

    componentDidMount() {
        clear();
        this.emailInput.focus();
    }

    login(event) {
        event.preventDefault();
        const redirectPath = new URLSearchParams(this.props.location.search).get('redirectPath');
        this.setState({
            status: SAVING,
        });
        grant('password', {
            username: this.emailInput.value,
            password: this.passwordInput.value,
            remember: true,
        }).then(
            () => {
                Impersonate.clear();
                if (redirectPath) {
                    this.props.history.push(redirectPath);
                } else {
                    this.props.history.goBack();
                }
            },
            error => {
                let errorType;
                let errorMessage;
                if (error.status === 400) {
                    errorType = 'credentials';
                    errorMessage = R.path(['responseJSON', 'error'], error);
                } else if (error.status === 0 && !navigator.onLine) {
                    errorType = 'offline';
                    errorMessage = 'offline';
                } else {
                    errorType = 'unhandled';
                    errorMessage = 'unhandled error';
                }
                this.setState({
                    error: errorType,
                    status: READY,
                });
                if (errorType === 'unhandled') {
                    captureException(new Error(errorMessage));
                }
            }
        );
    }

    render() {
        const { trans } = this.props;
        const { status, error } = this.state;

        let errorMessage;

        switch (error) {
            case null:
                errorMessage = null;
                break;
            case 'credentials':
                errorMessage = trans('"session.loginForm.invalidPassword"');
                break;
            case 'offline':
                errorMessage = trans('miniruche.login.login.notConnected');
                break;
            default:
                errorMessage = trans('miniruche.login.login.error');
        }

        return (
            <Page blue>
                <Section small>
                    <div className="u-center u-spacing-stack-xl">
                        <img width="200" src="/assets/images/logos/logo-miniruche.svg" />
                    </div>
                    <Heading rank={1} size="large" className="u-spacing-stack-l">
                        {trans('login.login')}
                    </Heading>
                    <form>
                        <div className="u-spacing-stack-xl">
                            <FormField>
                                <FormLabel label={trans('login.email')} />
                                <input
                                    type="email"
                                    ref={c => {
                                        this.emailInput = c;
                                    }}
                                    className="formInput formInput--inverse"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                />
                            </FormField>
                            <FormField>
                                <FormLabel label={trans('global.password')} />
                                <input
                                    type="password"
                                    ref={c => {
                                        this.passwordInput = c;
                                    }}
                                    className="formInput formInput--inverse"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                />
                            </FormField>
                            <FormErrors errors={errorMessage ? [errorMessage] : []} />
                        </div>
                        <Split align="center" className="u-spacing-stack-xl">
                            <Grow>
                                <Button
                                    block
                                    variant="primary"
                                    onClick={status === SAVING ? null : this.login}
                                    disabled={status === SAVING}
                                >
                                    {trans('login.login')}
                                </Button>
                            </Grow>
                            <Grow className="u-right">
                                <Link
                                    to={`/forgot-password${
                                        this.props.location ? this.props.location.search : ''
                                    }`}
                                >
                                    {trans('login.forgotPassword')}
                                </Link>
                            </Grow>
                        </Split>
                        <Text className="u-spacing-stack-s">
                            {trans('miniruche.login.login.foodAssemblyAccountQuestion')}
                        </Text>
                        <Link
                            to={`/register${this.props.location ? this.props.location.search : ''}`}
                        >
                            {trans('miniruche.login.login.join')}
                        </Link>
                    </form>
                </Section>
            </Page>
        );
    }
}

Login.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    trans: PropTypes.func.isRequired,
};

export default connectToI18n(({ location, history, i18n, trans }) => (
    <Login i18n={i18n} location={location} history={history} trans={trans} />
));
