import React from 'react';
import PropTypes from 'prop-types';
import { INITIAL_LOADING, LOADING, READY } from 'modules/utils/ajaxStatuses';
import { get } from 'miniruche/services/api';
import AssemblyBox from 'miniruche/components/assembly/AssemblyBox.jsx';
import { Split, Grow, Text, Button, Input } from 'miniruche/ui';
import connectToI18n from 'modules/i18n/connectToI18n';

class AssembliesSearch extends React.Component {
    constructor(props) {
        super(props);
        this.onSearchInput = this.onSearchInput.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.state = {
            status: INITIAL_LOADING,
            search: '',
        };
    }

    onSearchInput(e) {
        this.setState({
            search: e.target.value,
        });
    }

    onSearch() {
        this.setState({
            status: LOADING,
        });
        this.props.onSearch(this.state.search);
        get(`miniruche/assemblies/find/${this.state.search}`).then(searchResults => {
            this.props.showResults(searchResults);
            this.setState({
                status: READY,
            });
        });
    }

    render() {
        const {
            searchResults,
            showModal,
            addAssembly,
            deleteAssembly,
            assembliesCurrentlyAdded,
            trans,
        } = this.props;

        return (
            <div>
                <Split gutter="medium" className="u-spacing-stack-m">
                    <Grow weight={3}>
                        <Input
                            autofocus
                            placeholder={trans('miniruche.assemblies.name')}
                            value={this.state.search}
                            onChange={this.onSearchInput}
                        />
                    </Grow>
                    <Grow weight={1}>
                        <Button block variant="primary" onClick={this.onSearch}>
                            {trans('miniruche.assemblies.find')}
                        </Button>
                    </Grow>
                </Split>
                {this.state.status === READY && searchResults.length === 0 && (
                    <Text className="u-spacing-stack-m">
                        {trans('miniruche.assemblies.noresult')}
                    </Text>
                )}
                {searchResults.map(assembly => {
                    return (
                        <AssemblyBox
                            key={assembly.uuid}
                            className="u-spacing-stack-m"
                            assembly={assembly}
                            showModal={showModal}
                            addAssembly={addAssembly}
                            removeAssembly={deleteAssembly}
                            added={!!assembliesCurrentlyAdded[assembly.uuid]}
                        />
                    );
                })}
            </div>
        );
    }
}

AssembliesSearch.propTypes = {
    searchResults: PropTypes.array.isRequired,
    showResults: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    onSearch: PropTypes.func,
    addAssembly: PropTypes.func.isRequired,
    deleteAssembly: PropTypes.func.isRequired,
    assembliesCurrentlyAdded: PropTypes.object.isRequired,
    trans: PropTypes.func.isRequired,
};

AssembliesSearch.defaultProps = {
    onSearch: () => {},
};

export default connectToI18n(AssembliesSearch);
