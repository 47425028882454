import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { momentNow } from 'models/date';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getLocalizedDate } from 'modules/utils/dateAndTime';

class RemainingDays extends React.Component {
    render() {
        const { saleEndDate, i18n, trans } = this.props;

        const saleEndDay = moment(saleEndDate).dayOfYear();
        const saleEndYear = moment(saleEndDate).year();
        const now = momentNow();
        // Sales ends at 3am but we want to display the day before so we compare day-to-day.
        const diffDate = now
            .clone()
            .dayOfYear(saleEndDay)
            .year(saleEndYear)
            .subtract(1, 'days');
        const remainingDays = diffDate.diff(now, 'days');

        if (remainingDays <= 0) {
            return <strong>{trans('miniruche.sale.remainingDays.today')}</strong>;
        }

        if (remainingDays === 1) {
            return <strong>{trans('miniruche.sale.remainingDays.tomorrow')}</strong>;
        }

        if (remainingDays < 4) {
            return (
                <strong>
                    {trans('miniruche.sale.remainingDays.soon', {
                        '%remainingDays%': remainingDays,
                    })}
                </strong>
            );
        }

        return (
            <span>
                {trans('miniruche.sale.remainingDays.date', {
                    '%date%': getLocalizedDate(i18n, 'I', diffDate),
                })}
            </span>
        );
    }
}

RemainingDays.propTypes = {
    saleEndDate: PropTypes.string.isRequired,
    i18n: PropTypes.string.isRequired,
    trans: PropTypes.func.isRequired,
};

export default connectToI18n(RemainingDays);
