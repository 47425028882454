import { get } from 'miniruche/services/api';

let _localCountriesCache = [];

const getCountries = () =>
    new Promise(resolve => {
        if (_localCountriesCache.length > 0) {
            resolve(_localCountriesCache);
        }

        get('countries/').then(({ countries }) => {
            _localCountriesCache = countries;
            resolve(countries);
        });
    });

export { getCountries };
