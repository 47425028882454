import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'miniruche/services/api';
import ProductDetails from 'miniruche/modules/shopping/components/product/ProductDetails.jsx';
import { INITIAL_LOADING, LOADING_MORE, READY } from 'modules/utils/ajaxStatuses';

export class Product extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        saleId: PropTypes.number.isRequired,
        categoryId: PropTypes.number.isRequired,
        productId: PropTypes.number.isRequired,
        closeModal: PropTypes.func,
        addToBasket: PropTypes.func,
        initialProduct: PropTypes.object,
    };

    constructor(props) {
        super(props);
        const initialProduct = props.initialProduct;

        this.state = {
            product: initialProduct || {},
            status: initialProduct ? LOADING_MORE : INITIAL_LOADING,
        };
    }

    componentDidMount() {
        const productRequest = get(
            `miniruche/${this.props.slug}/sales/${this.props.saleId}/products/${this.props.productId}`
        );
        productRequest.then(this.onDataLoaded);
    }

    onDataLoaded = product => {
        this.setState(previousState => {
            // Keep the basket data for offers
            const offers = previousState.product.offers
                ? previousState.product.offers
                : product.offers;
            return {
                product: {
                    ...product,
                    offers: offers.sort((o1, o2) => (o1.price.amount < o2.price.amount ? -1 : 1)),
                },
                status: READY,
            };
        });
    };

    render() {
        const { slug, saleId, categoryId, closeModal, addToBasket } = this.props;

        const { product, status } = this.state;

        if (status === INITIAL_LOADING) {
            return <div className="miniRucheWrapper" />;
        }

        return (
            <div className="miniRucheWrapper">
                <ProductDetails
                    slug={slug}
                    saleId={saleId}
                    categoryId={categoryId}
                    product={product}
                    addToBasket={addToBasket}
                    status={status}
                    closeModal={closeModal}
                />
            </div>
        );
    }
}

export default Product;
