import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import classnames from 'classnames';
import Button from './Button.jsx';
import ShareButton from './ShareButton.jsx';
import Grow from 'miniruche/ui/layout/Grow.jsx';
import Split from 'miniruche/ui/layout/Split.jsx';

function canUseShareApi() {
    return typeof navigator !== 'undefined' && !!navigator.share;
}

class URLShare extends React.Component {
    constructor(props) {
        super(props);
        this._canShare = canUseShareApi();
    }

    copyToClipboard = () => {
        this.props.onShare('copy');
        this.URLInput.select();
        document.execCommand('Copy');
    };

    share = () => {
        this.props.onShare('browser');
        navigator.share({
            title: this.props.messageTitle,
            text: this.props.messageContent,
            url: this.props.url,
        });
    };

    render() {
        const {
            url,
            className,
            inverse,
            messageContent,
            onShare,
            messageTitle,
            trans,
            ...rest
        } = this.props;

        const isInversed = inverse || this.context.inverse;

        return (
            <div className={className}>
                <div
                    className={classnames(
                        'URLShare',
                        { 'URLShare--inverse': isInversed },
                        { 'u-spacing-stack-s': !this._canShare }
                    )}
                    {...rest}
                >
                    <Split>
                        <Grow weight={1}>
                            <input
                                className="URLShare-input"
                                ref={c => (this.URLInput = c)}
                                value={url}
                                readOnly
                            />
                        </Grow>
                        <Grow weight={0}>
                            {this._canShare ? (
                                <Button
                                    className="URLShare-button"
                                    variant="primary"
                                    onClick={this.share}
                                >
                                    {trans('global.share')}
                                </Button>
                            ) : (
                                <Button
                                    className="URLShare-button"
                                    variant="primary"
                                    onClick={this.copyToClipboard}
                                >
                                    {trans('global.copy')}
                                </Button>
                            )}
                        </Grow>
                    </Split>
                </div>
                {!this._canShare && (
                    <div>
                        <ShareButton
                            className="u-spacing-inline-xs u-hidden-desktop"
                            url={url}
                            message={messageContent}
                            type="facebook"
                            onShare={this.props.onShare}
                        />
                        <ShareButton
                            className="u-spacing-inline-xs"
                            url={url}
                            message={messageContent}
                            type="email"
                            onShare={this.props.onShare}
                        />
                        <ShareButton
                            className="u-spacing-inline-xs u-hidden-desktop"
                            url={url}
                            message={messageContent}
                            type="whatsapp"
                            onShare={this.props.onShare}
                        />
                        <ShareButton
                            className="u-spacing-inline-xs u-hidden-desktop"
                            url={url}
                            message={messageContent}
                            type="sms"
                            onShare={this.props.onShare}
                        />
                    </div>
                )}
            </div>
        );
    }
}

URLShare.propTypes = {
    url: PropTypes.string.isRequired,
    messageTitle: PropTypes.string.isRequired,
    messageContent: PropTypes.string.isRequired,
    className: PropTypes.string,
    inverse: PropTypes.bool,
    onShare: PropTypes.func,
    trans: PropTypes.func.isRequired,
};

URLShare.defaultProps = {
    onShare: () => {},
};

URLShare.contextTypes = {
    inverse: PropTypes.bool,
};

export default connectToI18n(URLShare);
