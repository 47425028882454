import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import * as R from 'ramda';
import { join } from 'modules/utils/string';

class ConveyorsList extends React.PureComponent {
    render() {
        const { conveyors, className, trans } = this.props;

        if (conveyors.length === 0) {
            return null;
        }

        const names = R.pluck('name', conveyors);

        return (
            <span className={className}>
                {join(names, {
                    separator: ', ',
                    lastSeparator: `&nbsp${trans('global.and')}&nbsp`,
                })}
            </span>
        );
    }
}

ConveyorsList.propTypes = {
    conveyors: PropTypes.array,
    className: PropTypes.string,
    trans: PropTypes.func,
};

ConveyorsList.defaultProps = {
    conveyors: [],
};

export default connectToI18n(ConveyorsList);
