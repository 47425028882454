import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import classnames from 'classnames';
import { getLocalizedTime } from 'modules/utils/dateAndTime';
import { Link } from 'react-router-dom';
import { Banner, Text, Button, Heading, Split, Grow } from 'miniruche/ui';

export class CurrentDayOrders extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        sales: PropTypes.array.isRequired,
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
    };

    render() {
        const { slug, sales, trans, transChoice, i18n } = this.props;

        if (sales.length === 0) {
            return null;
        }

        const formatTimeSlot = sale => {
            // TODO: may be wrong for multi-timeslot assemblies
            return trans('miniruche.currentDayOrders.pickup', {
                '%start%': getLocalizedTime(i18n, sale.assembly.timeslots[0].starting),
                '%end%': getLocalizedTime(i18n, sale.assembly.timeslots[0].ending),
            });
        };

        return (
            <Banner color="yellow">
                <Heading rank={1} size="large" className="u-spacing-stack-m">
                    {transChoice('miniruche.currentDayOrders.title', sales.length)}
                </Heading>
                {sales.map((sale, index) => (
                    <Link
                        key={sale.id}
                        to={`/${slug}/admin/sales/${sale.id}/pickup`}
                        className={classnames('u-blockLink', {
                            'u-spacing-stack-l': index < sales.length - 1,
                            'u-spacing-stack-s': index === sales.length - 1,
                        })}
                    >
                        <Split align="center" gutter="small">
                            <Grow weight={1}>
                                <Heading rank={2} className="u-spacing-stack-xxs" size="small">
                                    {formatTimeSlot(sale)}
                                </Heading>
                                <Text className="u-spacing-stack-xxs" size="small">
                                    {sale.assemblyName}
                                </Text>
                            </Grow>
                            <Grow weight={0}>
                                <Button variant="white" icon="angle-right" />
                            </Grow>
                        </Split>
                    </Link>
                ))}
            </Banner>
        );
    }
}

export default connectToI18n(CurrentDayOrders);
