import React from 'react';
import PropTypes from 'prop-types';

import { Heading, Text, URLShare } from 'miniruche/ui';
import connectToI18n from 'modules/i18n/connectToI18n';

export class NoParticipants extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        slug: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const { className, slug, trans } = this.props;

        return (
            <div className={className}>
                <Heading rank={2} size="medium" className="u-spacing-stack-s">
                    {trans('miniruche.sale.noparticipants.heading')}
                </Heading>
                <Text className="u-spacing-stack-m">
                    {trans('miniruche.sale.noparticipants.text')}
                </Text>
                <URLShare
                    url={`https://miniruche.com/${slug}`}
                    messageTitle={trans('miniruche.sale.noparticipants.urlTitle')}
                    messageContent={trans('miniruche.sale.noparticipants.urlContent')}
                />
            </div>
        );
    }
}

export default connectToI18n(NoParticipants);
