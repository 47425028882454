import React from 'react';
import PropTypes from 'prop-types';

class Request extends React.PureComponent {
    componentDidMount() {
        this._isMounted = true;
        this.send();
    }

    componentDidUpdate() {
        this.send();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSuccess(payload, response) {
        if (this._isMounted && this.props.payload === payload) {
            this.props.onSuccess(response, payload);
        }
    }

    onError(payload, error) {
        if (this._isMounted && this.props.payload === payload) {
            this.props.onError(error, payload);
        }
    }

    send() {
        const { handler, payload } = this.props;

        handler(payload).then(this.onSuccess.bind(this, payload), this.onError.bind(this, payload));
    }

    render() {
        return null;
    }
}

Request.propTypes = {
    handler: PropTypes.func.isRequired,
    payload: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
};

Request.defaultProps = {
    onSuccess: () => {},
    onError: () => {},
};

export default Request;
