import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { get } from 'miniruche/services/api';
import { Input } from 'miniruche/ui';

const sortByCountryCodeThenName = R.sortWith([
    R.ascend(R.prop('countryCode')),
    R.ascend(R.compose(R.toLower, R.prop('name'))),
]);

const appendCountryCodeToName = R.map(({ id, name, zipCode, countryCode }) => ({
    id,
    name: `${name} (${countryCode})`,
    zipCode,
}));

export class CityAutocomplete extends React.Component {
    static propTypes = {
        zipCode: PropTypes.string.isRequired,
        onZipCodeChange: PropTypes.func.isRequired,
        onCitySelected: PropTypes.func.isRequired,
    };

    static defaultProps = {
        blue: false,
        placeholder: null,
    };

    state = {
        suggestions: [],
    };

    onInput = zipCode => {
        this.props.onZipCodeChange(zipCode);
        if (zipCode) {
            this.fetchSuggestions(zipCode);
        }
    };

    onChange = suggestion => {
        this.props.onCitySelected(suggestion);
        this.setState({ suggestions: [] });
    };

    fetchSuggestions = zipCode => {
        if (!zipCode || zipCode.length < 3) {
            return null;
        }

        return get(`cities/?zipCode=${zipCode}&openCountryOnly=1`).then(({ cities }) => {
            this.setState({
                suggestions: R.pipe(sortByCountryCodeThenName, appendCountryCodeToName)(cities),
            });
        });
    };

    render() {
        const { zipCode, ...rest } = this.props;

        const { suggestions } = this.state;

        return (
            <Input
                type="autocomplete"
                options={suggestions}
                onChange={this.onChange}
                onInput={this.onInput}
                value={zipCode}
                {...rest}
            />
        );
    }
}

export default connectToI18n(CityAutocomplete);
