import React from 'react';
import PropTypes from 'prop-types';

export default function toggleProp(Component) {
    return class extends React.Component {
        static propTypes = {
            onChange: PropTypes.func,
            defaultValue: PropTypes.object,
            name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        };

        static defaultProps = {
            onChange: () => {},
            defaultValue: {},
        };

        constructor(props) {
            super(props);
            this.state = props.defaultValue;
        }

        toggle = (prop, value) => {
            const isControlled = this.props[prop] === true || this.props[prop] === false;
            const hasValue = value === true || value === false;
            const name = this.props.name;

            if (hasValue) {
                const nextState = { [prop]: value };
                if (isControlled) {
                    return this.props.onChange({ name, value: nextState });
                }
                return this.setState(nextState, () =>
                    this.props.onChange({ name, value: nextState })
                );
            }

            if (isControlled) {
                return this.props.onChange({ name, value: { [prop]: !this.props[prop] } });
            }

            return this.setState(
                previousState => ({ [prop]: !previousState[prop] }),
                () => this.props.onChange({ name, value: { [prop]: this.state[prop] } })
            );
        };

        render() {
            const props = {
                ...this.state,
                ...this.props,
                toggle: this.toggle,
            };

            return <Component {...props} />;
        }
    };
}
