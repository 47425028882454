import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import AddressAutocomplete from 'miniruche/components/address/AddressAutocomplete.jsx';
import { Section, Heading, Button, Text } from 'miniruche/ui';

export class AddressForm extends React.Component {
    static propTypes = {
        address: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        canGoToNextStep: PropTypes.bool.isRequired,
        currentStep: PropTypes.number.isRequired,
        stepCount: PropTypes.number.isRequired,
        trans: PropTypes.func.isRequired,
        errors: PropTypes.array,
    };

    render() {
        const {
            address,
            next,
            canGoToNextStep,
            currentStep,
            stepCount,
            trans,
            onChange,
            errors,
        } = this.props;

        return (
            <Fragment>
                <Section small>
                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                        {currentStep}/{stepCount}
                        <br />
                        {trans('miniruche.account.place')}
                    </Heading>
                    <Text>{trans('miniruche.account.placeText')}</Text>
                </Section>
                <Section small>
                    <AddressAutocomplete
                        autofocus
                        address={address}
                        onAddressChange={onChange}
                        placeholder={trans('miniruche.addressForm.inputPlaceholder')}
                        errors={errors}
                    />
                </Section>
                <Section small>
                    <Button
                        block
                        variant="primary"
                        onClick={canGoToNextStep ? next : null}
                        disabled={!canGoToNextStep}
                    >
                        {trans('miniruche.create.nextStep')}
                    </Button>
                </Section>
            </Fragment>
        );
    }
}

export default connectToI18n(AddressForm);
