import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Heading, Text, Icon, Button, FormErrors } from 'miniruche/ui';
import connectToI18n from 'modules/i18n/connectToI18n';

const SettingChangeConfirmationModal = ({
    actionsDisabled,
    onCancel,
    onConfirm,
    errors,
    trans,
}) => {
    return (
        <Modal close={onCancel} closeDisabled={actionsDisabled}>
            <Heading rank={2} className="u-spacing-stack-m">
                <Icon type="warning" className="u-spacing-inline-s" size="l" />
                {trans('global.warning')}
            </Heading>
            <Text className="u-spacing-stack-l">
                {trans('miniruche.admin.fees.newFeesDetails')}
            </Text>
            <Button
                block
                className="u-spacing-stack-s"
                disabled={actionsDisabled}
                onClick={onConfirm}
                variant="primary"
            >
                {trans('global.saveChanges')}
            </Button>
            <Button block disabled={actionsDisabled} onClick={onCancel} variant="outline">
                {trans('global.cancel')}
            </Button>
            <div className="u-center">
                <FormErrors errors={errors} />
            </div>
        </Modal>
    );
};

SettingChangeConfirmationModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    trans: PropTypes.func.isRequired,
};

export default connectToI18n(SettingChangeConfirmationModal);
