import React from 'react';
import PropTypes from 'prop-types';

class FormLabel extends React.Component {
    render() {
        const { label } = this.props;

        if (!label) {
            return null;
        }

        return <label className="formLabel">{label}</label>;
    }
}

FormLabel.propTypes = {
    label: PropTypes.string,
};

export default FormLabel;
