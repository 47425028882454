import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Image extends React.Component {
    render() {
        const { rounded, bordered, fluid, inline, className, ...rest } = this.props;

        return (
            <img
                className={classnames(
                    'image',
                    { 'image--rounded': rounded },
                    { 'image--bordered': bordered },
                    { 'image--fluid': fluid },
                    { 'image--inline': inline },
                    className
                )}
                {...rest}
            />
        );
    }
}

Image.propTypes = {
    rounded: PropTypes.bool,
    bordered: PropTypes.bool,
    fluid: PropTypes.bool,
    inline: PropTypes.bool,
    className: PropTypes.string,
};

export default Image;
