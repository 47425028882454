import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { READY, SAVING } from 'modules/utils/ajaxStatuses';
import { patch } from 'miniruche/services/api';
import { Split, Heading, Text, Input, Button } from 'miniruche/ui';
import connectToI18n from 'modules/i18n/connectToI18n';
import SettingChangeConfirmationModal from './SettingChangeConfirmationModal.jsx';

const FeeSetting = ({ miniruche, trans }) => {
    const [errors, setErrors] = useState([]);
    const [shouldDisplayFixedFeeSavedText, setShouldDisplayFixedFeeSavedText] = useState(false);
    const [feeType, setFeeType] = useState(miniruche.fee.type);
    const [fee, setFee] = useState(miniruche.fee);
    const [previousFee, setPreviousFee] = useState(miniruche.fee);
    const [status, setStatus] = useState(READY);
    const [fixedFeeInputVal, setFixedFeeInputVal] = useState(
        miniruche.fee?.price?.amount ? miniruche.fee?.price?.amount / 100 : ''
    );

    const onFeeRateChange = event => {
        setFee({ type: 'percentage', rate: parseFloat(event.target.value) });
        setPreviousFee(fee);
        setFixedFeeInputVal('');
    };

    const onFeeTypeChange = event => {
        setFeeType(event.target.value);
    };

    const onFixedFeeInputChange = event => {
        setFixedFeeInputVal(event.target.value);
    };

    const onFixedFeeSaveBtnClicked = () => {
        setFee({
            type: 'fixed',
            price: { amount: fixedFeeInputVal * 100, currency: 'EUR' },
        });
        setPreviousFee(fee);
    };

    const onCancel = () => {
        setErrors([]);
        setFeeType(previousFee.type);
        setFee(previousFee);
        setStatus(READY);
        setFixedFeeInputVal(previousFee?.price?.amount ? previousFee?.price?.amount / 100 : '');
    };

    const onConfirm = () => {
        const params = {};

        if (fee.type === 'fixed') {
            params.feePrice = { amount: fixedFeeInputVal * 100, currency: 'EUR' };
        } else {
            params.feeRate = fee.rate;
        }

        patch(`miniruche/admin/${miniruche.slug}`, params).then(
            () => {
                if (fee.type === 'fixed') {
                    setShouldDisplayFixedFeeSavedText(true);
                }

                setPreviousFee(fee);
                setStatus(READY);
            },
            () => {
                setErrors(['La sauvegarde a échoué']);
                setStatus(READY);
            }
        );

        setStatus(SAVING);
    };

    return (
        <div>
            <Heading rank={3} size="medium" className="u-spacing-stack-m">
                {trans('miniruche.admin.edit.feesHeading')}
            </Heading>
            <Text
                className="u-spacing-stack-m"
                dangerouslySetInnerHTML={{
                    __html: trans('miniruche.admin.feesWithFixedPrice.text'),
                }}
            />
            <Split vertical>
                <label className="u-spacing-stretch-s">
                    <input
                        type="radio"
                        name="fee_type"
                        value="percentage"
                        onChange={onFeeTypeChange}
                        checked={feeType === 'percentage'}
                        className="u-spacing-inline-s"
                    />
                    {trans('miniruche.admin.fees.percentage')}
                </label>
                {feeType === 'percentage' && (
                    <Split className="u-pl-l">
                        <label className="u-spacing-stretch-s">
                            <input
                                type="radio"
                                name="fee"
                                value="0"
                                onChange={onFeeRateChange}
                                checked={fee.rate === 0}
                                className="u-spacing-inline-s"
                            />
                            0%
                        </label>
                        <label className="u-spacing-stretch-s">
                            <input
                                type="radio"
                                name="fee"
                                value="0.05"
                                onChange={onFeeRateChange}
                                checked={fee.rate === 0.05}
                                className="u-spacing-inline-s"
                            />
                            5%
                        </label>
                        <label className="u-spacing-stretch-s">
                            <input
                                type="radio"
                                name="fee"
                                value="0.1"
                                onChange={onFeeRateChange}
                                checked={fee.rate === 0.1}
                                className="u-spacing-inline-s"
                            />
                            10%
                        </label>
                    </Split>
                )}
                <label className="u-spacing-stretch-s">
                    <input
                        type="radio"
                        name="fee_type"
                        value="fixed"
                        onChange={onFeeTypeChange}
                        checked={feeType === 'fixed'}
                        className="u-spacing-inline-s"
                    />
                    {trans('miniruche.admin.fees.fixed')}
                </label>
                {feeType === 'fixed' && (
                    <div className="u-pl-xl">
                        <Split className="u-spacing-stack-xs">
                            <div className="input-money">
                                <Input
                                    type="number"
                                    step="0.1"
                                    value={fixedFeeInputVal}
                                    onChange={onFixedFeeInputChange}
                                    only
                                />
                                <div className="input-money__currency">€</div>
                            </div>
                            <Button
                                variant="outline"
                                className="send-fixed-fee"
                                onClick={onFixedFeeSaveBtnClicked}
                            >
                                <Text tag="span" strong>
                                    {trans('global.validate')}
                                </Text>
                            </Button>
                        </Split>
                        {shouldDisplayFixedFeeSavedText && (
                            <Text size="small">
                                {trans('miniruche.admin.fees.fixed.savedText')}
                            </Text>
                        )}
                    </div>
                )}
            </Split>
            {fee !== previousFee && (
                <SettingChangeConfirmationModal
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                    actionsDisabled={status === SAVING}
                    errors={errors}
                />
            )}
        </div>
    );
};

FeeSetting.propTypes = {
    miniruche: PropTypes.object.isRequired,
    trans: PropTypes.func.isRequired,
};

export default connectToI18n(FeeSetting);
