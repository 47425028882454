import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { INITIAL_LOADING, READY, SAVING } from 'modules/utils/ajaxStatuses';
import { patch } from 'miniruche/services/api';
import { fetchAdminMiniruche } from 'miniruche/services/miniruche/miniruche.service';
import Request from 'miniruche/effects/Request.jsx';
import { Page, Section, Stack, Box, Heading } from 'miniruche/ui';
import PageHeader from 'miniruche/ui/layout/PageHeader.jsx';
import {
    extractTimeSlots,
    registerTimeSlot,
    unregisterTimeSlot,
    getTranslatedWeekdays,
} from './timeslot.model';
import AddressSetting from './AddressSetting.jsx';
import FeeRateSetting from './FeeRateSetting.jsx';
import TimeSlotsSetting from './TimeSlotsSetting.jsx';
import VisibilitySetting from './VisibilitySetting.jsx';
import SingleTimeSlotSetting from './SingleTimeSlotSetting.jsx';
import FeeSetting from './FeeSetting.jsx';

const INITIAL_LOAD_PAGE = 'INITIAL_LOAD';
const ALL_SETTINGS_PAGE = 'ALL_SETTINGS';
const SINGLE_TIMESLOT_PAGE = 'SINGLE_TIMESLOT';

export class Edit extends React.Component {
    static propTypes = {
        i18n: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        activeDay: null,
        assemblies: {},
        errors: [],
        initialLoadRequest: ___BROWSER___
            ? {
                  payload: {
                      slug: this.props.slug,
                  },
              }
            : null,
        miniruche: {},
        page: INITIAL_LOAD_PAGE,
        status: INITIAL_LOADING,
        timeslots: {},
    };

    onInitialLoadSuccess = ([miniruche, assemblies]) => {
        this.setState({
            assemblies,
            miniruche,
            page: ALL_SETTINGS_PAGE,
            status: READY,
            timeslots: extractTimeSlots(miniruche, this.props.i18n),
        });
    };

    onEditTimeSlot = day => {
        this.setState({
            activeDay: day,
            page: SINGLE_TIMESLOT_PAGE,
        });
    };

    onNewTimeSlot = () => {
        this.setState({
            activeDay: null,
            page: SINGLE_TIMESLOT_PAGE,
        });
    };

    onBackToSettings = event => {
        event.preventDefault();
        this.setState({
            errors: [],
            page: ALL_SETTINGS_PAGE,
        });
    };

    onSaveTimeSlot = (day, timeRange, assemblies) => {
        const timeslots = registerTimeSlot(
            day,
            timeRange,
            assemblies,
            this.props.i18n
        )(this.state.timeslots);
        this.patchMiniruche(timeslots);
    };

    onDeleteTimeSlot = day => {
        const timeslots = unregisterTimeSlot(day)(this.state.timeslots);
        this.patchMiniruche(timeslots);
    };

    patchMiniruche = timeslots => {
        this.setState({
            errors: [],
            status: SAVING,
        });
        patch(`miniruche/admin/${this.props.slug}`, {
            pickUpTimeSlots: timeslots.days.map(day => ({
                dayOfWeek: day,
                timeStart: timeslots.timeRanges[day].start,
                timeEnd: timeslots.timeRanges[day].end,
                assemblies: timeslots.assemblies[day],
            })),
        }).then(
            () =>
                this.setState({
                    page: ALL_SETTINGS_PAGE,
                    status: READY,
                    timeslots,
                }),
            () =>
                this.setState({
                    errors: ['La sauvegarde a échoué'],
                    status: READY,
                })
        );
    };

    renderInitialLoadPage() {
        return (
            <Page blue>
                {this.state.initialLoadRequest && (
                    <Request
                        handler={fetchAdminMiniruche}
                        payload={this.state.initialLoadRequest.payload}
                        onSuccess={this.onInitialLoadSuccess}
                    />
                )}
            </Page>
        );
    }

    renderAllSettingsPage() {
        const { i18n, slug, trans } = this.props;
        const { miniruche, timeslots } = this.state;

        return (
            <Page blue>
                <PageHeader
                    title={trans('global.parameters')}
                    backHref={`/${slug}/admin/sales`}
                    backText={trans('miniruche.admin.assemblies.backToDashboard')}
                />
                <Section>
                    <Box>
                        <Heading rank={2} size="large" className="u-spacing-stack-l">
                            {trans('miniruche.account.address')}
                        </Heading>
                        <AddressSetting miniruche={miniruche} />
                    </Box>
                </Section>
                <Section>
                    <Box>
                        <Heading rank={2} size="large" className="u-spacing-stack-l">
                            {trans('miniruche.admin.edit.group')}
                        </Heading>
                        <Stack gutter="l">
                            {miniruche.isInSwitzerland && <FeeRateSetting miniruche={miniruche} />}
                            {!miniruche.isInSwitzerland && <FeeSetting miniruche={miniruche} />}
                            <TimeSlotsSetting
                                i18n={i18n}
                                onEditTimeSlot={this.onEditTimeSlot}
                                onNewTimeSlot={this.onNewTimeSlot}
                                timeslots={timeslots}
                            />
                            <VisibilitySetting miniruche={miniruche} />
                        </Stack>
                    </Box>
                </Section>
            </Page>
        );
    }

    renderSingleTimeSlotPage() {
        const { activeDay, assemblies, errors, miniruche, status, timeslots } = this.state;
        const { i18n, trans } = this.props;

        const availableDays = R.reject(
            R.propSatisfies(day => R.contains(day, timeslots.days), 'value'),
            getTranslatedWeekdays(i18n)
        );

        const timeslot =
            activeDay && R.contains(activeDay, timeslots.days)
                ? {
                      assemblies: timeslots.assemblies[activeDay],
                      day: activeDay,
                      timeRange: timeslots.timeRanges[activeDay],
                  }
                : null;

        return (
            <Page blue>
                <PageHeader
                    title={
                        timeslot
                            ? trans('miniruche.admin.modifyTimeslot')
                            : trans('miniruche.admin.addTimeslot')
                    }
                    backHandler={this.onBackToSettings}
                    backHref={`/${this.props.slug}/admin/edit`}
                    backText={trans('miniruche.admin.backToParameters')}
                />
                <Section>
                    <Box>
                        <SingleTimeSlotSetting
                            availableAssemblies={assemblies}
                            availableDays={availableDays}
                            errors={errors}
                            onDeleteTimeSlot={this.onDeleteTimeSlot}
                            onSaveTimeSlot={this.onSaveTimeSlot}
                            miniruche={miniruche}
                            status={status}
                            timeslot={timeslot}
                        />
                    </Box>
                </Section>
            </Page>
        );
    }

    render() {
        if (this.state.page === INITIAL_LOAD_PAGE) {
            return this.renderInitialLoadPage();
        }

        if (this.state.page === ALL_SETTINGS_PAGE) {
            return this.renderAllSettingsPage();
        }

        if (this.state.page === SINGLE_TIMESLOT_PAGE) {
            return this.renderSingleTimeSlotPage();
        }

        return null;
    }
}

export default connectToI18n(({ match, i18n, trans }) => (
    <Edit
        i18n={i18n}
        trans={trans}
        slug={match.params.slug}
        saleId={parseInt(match.params.saleId, 10)}
    />
));
