import React from 'react';
import { Section, Box, Heading, Button, CheckButton, Link } from 'miniruche/ui';

class Actions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
        };
        this.toggleChecked = this.toggleChecked.bind(this);
    }

    toggleChecked() {
        this.setState(previousState => ({
            checked: !previousState.checked,
        }));
    }

    render() {
        return (
            <div>
                <Section>
                    <Heading rank={2} size="large" className="u-spacing-stack-m">
                        Actions
                    </Heading>
                    <p className="u-spacing-stack-m">Buttons colors</p>
                    <p className="u-spacing-stack-s">
                        <Button>I'm a button</Button>
                    </p>
                    <p className="u-spacing-stack-s">
                        <Button variant="primary">I'm a primary button</Button>
                    </p>
                    <p className="u-spacing-stack-s">
                        <Button variant="outline">I'm an outline button</Button>
                    </p>
                    <p className="u-spacing-stack-s">
                        <Button variant="warning">I'm a warning button</Button>
                    </p>
                    <p className="u-spacing-stack-s">
                        <Button variant="success">I'm a success button</Button>
                    </p>
                </Section>
                <Section>
                    <p className="u-spacing-stack-m">Button with Icon</p>
                    <p className="u-spacing-stack-s">
                        <Button icon="angle-left">I'm a block button</Button>
                    </p>
                    <p className="u-spacing-stack-s">
                        <Button icon="angle-right" iconPosition="right">
                            I'm a block button
                        </Button>
                    </p>
                    <p className="u-spacing-stack-s">
                        <Button icon="ok" />
                    </p>
                    <p className="u-spacing-stack-s">
                        <Button icon="basket" variant="primary" />
                    </p>
                    <p className="u-spacing-stack-s">
                        <Button icon="angle-left" variant="outline" />
                    </p>
                </Section>
                <Section>
                    <p className="u-spacing-stack-m">Disabled button</p>
                    <p className="u-spacing-stack-s">
                        <Button disabled>I'm disabled</Button>
                    </p>
                </Section>
                <Section>
                    <p className="u-spacing-stack-m">Block Button</p>
                    <p className="u-spacing-stack-s">
                        <Button block>I'm a block button</Button>
                    </p>
                </Section>
                <Section>
                    <p className="u-spacing-stack-m">Buttons can behave as links</p>
                    <p className="u-spacing-stack-s">
                        <Button to="/fr/miniruche/examples/blue-page">Click me</Button>
                    </p>
                </Section>
                <Section>
                    <p className="u-spacing-stack-m">Buttons can be small</p>
                    <p className="u-spacing-stack-s">
                        <Button small>I'm small</Button>
                    </p>
                </Section>
                <Section alternate>
                    <p className="u-spacing-stack-m">Playing with backgrounds</p>
                    <Button>I look good on an alternate background</Button>
                </Section>
                <Section alternate>
                    <Box>
                        <Button>I look good in a box</Button>
                    </Box>
                </Section>
                <Section>
                    <p className="u-spacing-stack-m">Check Button</p>
                    <CheckButton onClick={this.toggleChecked} checked={this.state.checked} />
                </Section>
                <Section>
                    <p className="u-spacing-stack-m">Links are very simple.</p>
                    <p className="u-spacing-stack-m">
                        <Link>I look like a link</Link>
                    </p>
                    <p className="u-spacing-stack-s">
                        <Link to="/fr/miniruche/examples/page-sections">
                            I look like a link and you can click me
                        </Link>
                    </p>
                </Section>
            </div>
        );
    }
}

export default Actions;
