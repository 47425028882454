import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { capitalizeFirstLetter } from 'modules/utils/string';
import { getLocalizedTime } from 'modules/utils/dateAndTime';
import { Heading, Split, Grow, Icon, Text, Button } from 'miniruche/ui';
import { dayName, getTranslatedWeekdays } from './timeslot.model';

class TimeSlotsSetting extends React.Component {
    static propTypes = {
        i18n: PropTypes.string.isRequired,
        onEditTimeSlot: PropTypes.func.isRequired,
        onNewTimeSlot: PropTypes.func.isRequired,
        timeslots: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
    };

    onClickRow = day => {
        this.props.onEditTimeSlot(day);
    };

    onClickAdd = () => {
        this.props.onNewTimeSlot();
    };

    render() {
        const { i18n, timeslots, trans, transChoice } = this.props;

        return (
            <div>
                <Heading rank={3} size="medium" className="u-spacing-stack-m">
                    {trans('miniruche.admin.timeSlots.heading')}
                </Heading>
                {timeslots.days.map(day => {
                    const { start, end } = timeslots.timeRanges[day];
                    const assemblyCount = timeslots.assemblies[day].length;

                    return (
                        <div
                            key={day}
                            className="u-blockLink u-clickable"
                            onClick={() => this.onClickRow(day)}
                        >
                            <Split align="center" gutter="small" className="u-spacing-stack-s">
                                <Grow weight={1}>
                                    <Text strong>
                                        {capitalizeFirstLetter(dayName(day, i18n))}, de{' '}
                                        {getLocalizedTime(i18n, start)} à{' '}
                                        {getLocalizedTime(i18n, end)}
                                    </Text>
                                    <Text>
                                        {transChoice(
                                            'miniruche.admin.timeSlots.assemblies',
                                            assemblyCount,
                                            {
                                                '%assemblyCount%': assemblyCount,
                                            }
                                        )}
                                    </Text>
                                </Grow>
                                <Grow weight={0}>
                                    <Icon type="angle-right" />
                                </Grow>
                            </Split>
                        </div>
                    );
                })}
                {timeslots.days.length < getTranslatedWeekdays(i18n).length && (
                    <Button block variant="outline" onClick={this.onClickAdd}>
                        {trans('miniruche.admin.addTimeslot')}
                    </Button>
                )}
            </div>
        );
    }
}

export default connectToI18n(TimeSlotsSetting);
