import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from './Link.jsx';
import Text from 'miniruche/ui/content/Text.jsx';

class RevealContactInformationLink extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        email: PropTypes.string.isRequired,
        phone: PropTypes.string,
        small: PropTypes.bool,
    };

    state = {
        hidden: true,
    };

    onReveal = () => {
        this.setState({ hidden: false });
    };

    render() {
        const { children, className, email, phone, small } = this.props;

        return (
            <div className={className}>
                {this.state.hidden ? (
                    <Link onClick={this.onReveal} small={small}>
                        {children}
                    </Link>
                ) : (
                    <Fragment>
                        <Text>{email}</Text>
                        {phone && <Text>{phone}</Text>}
                    </Fragment>
                )}
            </div>
        );
    }
}

export default RevealContactInformationLink;
