import React from 'react';
import PropTypes from 'prop-types';
import FormInput from './FormInput.jsx';
import Dropdown from './Dropdown.jsx';
import Autocomplete from './Autocomplete.jsx';
import DateInput from './DateInput.jsx';
import Textarea from './Textarea.jsx';
import TimeInput from './TimeInput.jsx';
import TimerangeInput from './TimerangeInput.jsx';
import MultipleInput from './MultipleInput.jsx';

class InputSwitch extends React.Component {
    render() {
        const { options, ...rest } = this.props;

        if (rest.type === 'dropdown') {
            return <Dropdown options={options} {...rest} />;
        }

        if (rest.type === 'multiple') {
            return <MultipleInput options={options} {...rest} />;
        }

        if (rest.type === 'autocomplete') {
            return <Autocomplete options={options} {...rest} />;
        }

        if (rest.type === 'date') {
            return <DateInput {...rest} />;
        }

        if (rest.type === 'time') {
            return <TimeInput {...rest} />;
        }

        if (rest.type === 'timerange') {
            return <TimerangeInput {...rest} />;
        }

        if (rest.type === 'textarea') {
            return <Textarea {...rest} />;
        }

        return <FormInput {...rest} />;
    }
}

InputSwitch.propTypes = {
    options: PropTypes.array,
};

InputSwitch.defaultProps = {
    options: [],
};

export default InputSwitch;
