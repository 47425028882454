import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getLocalizedTime, getLocalizedDate } from 'modules/utils/dateAndTime';
import { Text } from 'miniruche/ui';

class DistributionDate extends React.PureComponent {
    render() {
        const { trans, i18n, sale, className } = this.props;

        return (
            <Text className={className}>
                {`${trans('global.distribution')} `}
                {trans('miniruche.assembly.distributionTimeWithDay', {
                    '%day%': getLocalizedDate(i18n, 'I', sale.date),
                    '%start%': getLocalizedTime(i18n, sale.assembly.timeslots[0].starting),
                    '%end%': getLocalizedTime(i18n, sale.assembly.timeslots[0].ending),
                })}
            </Text>
        );
    }
}

DistributionDate.propTypes = {
    trans: PropTypes.func.isRequired,
    i18n: PropTypes.string.isRequired,
    sale: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default connectToI18n(DistributionDate);
