import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

class Link extends React.Component {
    render() {
        const { children, to, className, small, ...rest } = this.props;

        const classes = classnames('link', { 'link--small': small }, className);

        if (to) {
            return (
                <RouterLink to={to} className={classes} {...rest}>
                    {children}
                </RouterLink>
            );
        }

        return (
            <button className={classes} {...rest}>
                {children}
            </button>
        );
    }
}

Link.propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
    className: PropTypes.string,
    small: PropTypes.bool,
};

export default Link;
