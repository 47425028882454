import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class GroupItem extends React.Component {
    getChildContext() {
        return {
            inverse: !this.props.inverse && !this.context.inverse,
        };
    }

    render() {
        const { children, inverse, className, spacing, onClick } = this.props;

        const isInversed = inverse || this.context.inverse;

        return (
            <div
                className={classnames(
                    'groupItem',
                    `u-spacing-${spacing}`,
                    { 'groupItem--inverse': isInversed },
                    className
                )}
                onClick={onClick}
            >
                {children}
            </div>
        );
    }
}

GroupItem.propTypes = {
    children: PropTypes.node,
    spacing: PropTypes.string,
    className: PropTypes.string,
    inverse: PropTypes.bool,
    onClick: PropTypes.func,
};

GroupItem.defaultProps = {
    spacing: 'inset-m',
};

GroupItem.contextTypes = {
    inverse: PropTypes.bool,
};

GroupItem.childContextTypes = {
    inverse: PropTypes.bool,
};

export default GroupItem;
