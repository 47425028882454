import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { clear } from 'modules/oauth';
import { redirectToLogin } from 'miniruche/services/api.js';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Section, Heading, Split, Grow, Icon, Text, Link } from 'miniruche/ui';

export class PageHeader extends React.Component {
    static propTypes = {
        title: PropTypes.node.isRequired,
        backHandler: PropTypes.func,
        backHref: PropTypes.string,
        backText: PropTypes.string,
        user: PropTypes.object,
        userLinks: PropTypes.bool,
        i18n: PropTypes.string.isRequired,
        className: PropTypes.string,
        extra: PropTypes.node,
        alternate: PropTypes.bool,
        trans: PropTypes.func.isRequired,
    };

    static defaultProps = {
        backHandler: null,
        backHref: null,
        backText: 'Retour',
        user: null,
        userLinks: false,
        alternate: true,
    };

    onBackClicked = event => {
        if (this.props.backHandler) {
            this.props.backHandler(event);
        }
    };

    logout = () => {
        clear();
        redirectToLogin();
    };

    login = () => {
        // TODO: record event?
        redirectToLogin();
    };

    render() {
        const {
            title,
            backHref,
            backText,
            user,
            userLinks,
            i18n,
            className,
            extra,
            alternate,
            trans,
        } = this.props;

        return (
            <Section
                alternate={alternate}
                className={classnames('miniRucheHeader', 'u-spacing-inset-s', className)}
            >
                <Split align="center" gutter="small">
                    <Grow weight={1}>
                        {backHref ? (
                            <RouterLink
                                to={backHref}
                                className="u-blockLink"
                                onClick={this.onBackClicked}
                            >
                                <Split align="center" gutter="small">
                                    <Grow weight={0}>
                                        <Icon type="angle-left" />
                                    </Grow>
                                    <Grow weight={1}>
                                        <Heading
                                            rank={1}
                                            size="small"
                                            className="u-spacing-stack-xxs"
                                        >
                                            {title}
                                        </Heading>
                                        <Text size="small">{backText}</Text>
                                    </Grow>
                                </Split>
                            </RouterLink>
                        ) : (
                            <div>
                                <Heading rank={1} size="small">
                                    {title}
                                </Heading>
                            </div>
                        )}
                    </Grow>
                    {userLinks && (
                        <Grow weight={0} className="u-right">
                            {user && <Text>{user.firstName}</Text>}
                            <Link small onClick={user ? this.logout : this.login}>
                                {user ? trans('menu.logOut') : trans('login.login')}
                            </Link>
                        </Grow>
                    )}
                    {extra && <Grow weight={0}>{extra}</Grow>}
                </Split>
            </Section>
        );
    }
}

export default connectToI18n(PageHeader);
