import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Text extends React.Component {
    render() {
        const { children, tag, size, className, type, strong, linethrough, ...rest } = this.props;

        const Tag = tag;

        return (
            <Tag
                className={classnames(
                    'text',
                    `text--${size}`,
                    { [`text--${type}`]: !!type },
                    { 'text--strong': !!strong },
                    { 'text--linethrough': !!linethrough },
                    className
                )}
                {...rest}
            >
                {children}
            </Tag>
        );
    }
}

Text.propTypes = {
    children: PropTypes.node,
    tag: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    strong: PropTypes.bool,
    linethrough: PropTypes.bool,
};

Text.defaultProps = {
    tag: 'p',
    size: 'medium',
};

export default Text;
