import Globalize from 'globalize';
import './globalize.culture.en-GB';
Globalize.addCultureInfo('en', 'en-GB');

import 'moment/locale/en-gb';

export default {
    code: 'en-GB',

    // bcode is here for the "backCode" ... the code as seen/sent by the back :O
    bCode: 'en_GB',

    name: 'English',
    shortcut: 'en',
    supportEmail: 'support@thefoodassembly.com',
    privacyEmail: 'privacy@thefoodassembly.com',

    trainingSite: {
        url: 'http://training.foodassembly.com',
        password: 'purple-carrot',
    },

    appStores: {
        apple: 'https://itunes.apple.com/gb/app/the-food-assembly/id1052198033?mt=8',
        google: 'https://play.google.com/store/apps/details?id=com.lrqdo&hl=en',
    },

    supportSite: {
        widgetDomain: 'thefoodassembly.zendesk.com',
        homepage: 'https://thefoodassembly.zendesk.com/hc/en-gb',
        host: 'https://thefoodassembly.zendesk.com/hc/en-gb/categories/200126242-Host-s-Corner',
        farmer:
            'https://thefoodassembly.zendesk.com/hc/en-gb/categories/200128252-Supply-an-Assembly',
        member: 'https://thefoodassembly.zendesk.com/hc/en-gb/categories/200124561-Member-s-corner',
        user: 'https://thefoodassembly.zendesk.com/hc/en-gb/categories/200124561-Member-s-corner',
        kycInformation:
            'https://thefoodassembly.zendesk.com/hc/en-gb/articles/207829229-Required-information-for-payments',
        proImprovementsHost: 'https://thefoodassembly.zendesk.com/hc/en-gb/articles/208559125',
        proImprovementsProd:
            'https://thefoodassembly.zendesk.com/hc/en-gb/articles/207835969-Improvement-in-your-Producer-Folder-section',
    },

    logos: {
        brown: '/assets/images/logos/brown/logo-en.svg',
        turquoise: '/assets/images/logos/turquoise/logo-en.svg',
        white: '/assets/images/logos/white/logo-en.svg',
        whiteOutlined: '/assets/images/logos/whiteOutlined/logo-en.svg',
        blue: '/assets/images/logos/blue/logo-en.svg',
        christmas: '/assets/images/logos/christmas/logo-en.svg',
    },

    social: {
        facebook: 'https://www.facebook.com/TheFoodAssembly',
        twitter: 'https://twitter.com/foodassembly',
        blog: 'https://magazine.laruchequiditoui.fr/',
        instagram: 'https://www.instagram.com/laruchequiditoui/',
        youtube: 'https://www.youtube.com/channel/UC3IIY5AMjmcLVqp8bVzgyWA',
        linkedin: 'https://www.linkedin.com/company/la-ruche-qui-dit-oui-',
    },

    links: {
        about: 'https://nous.laruchequiditoui.fr/',
        press: 'https://weare.thefoodassembly.com/press/',
        joinUs: 'https://weare.thefoodassembly.com/team-2/',
        order: 'https://laruchequiditoui.fr/en/assemblies',
        giftCards: 'https://laruchequiditoui.fr/en/giftcards',
        openRuche: 'https://laruchequiditoui.fr/en/p/open',
        provideRuche: 'https://laruchequiditoui.fr/en/p/provide',
        proposeLocal: '',
    },

    proposeLocalForm:
        'https://docs.google.com/forms/d/e/1FAIpQLSdERChkIDCt3TCeN81RNatjn4BJUq0I5k4EmknbXu3fOGi7hQ/viewform',

    dateFormats: {
        d: 'DD/MM/YYYY',
        D: 'dddd DD MMMM YYYY',
        I: 'dddd DD MMMM',
        i: 'DD MMMM YYYY',
        t: 'h:mm A',
        T: 'HH:mm:ss',
        f: 'DD MMMM YYYY HH:mm',
        F: 'DD MMMM YYYY HH:mm:ss',
        M: 'DD MMMM',
        Y: 'MMMM YYYY',
        shortDayDate: 'ddd DD MMMM',
        dateWithoutYear: 'MM/DD',
    },

    inlineLogo: require('./logo.svg'),
    zendeskLocale: 'en-gb',
    zendeskSearch: 'https://thefoodassembly.zendesk.com/api/v2/help_center/',
    zendeskCategories: {
        farmer: '200128252',
        member: '200124561',
        user: '200124561',
        host: '200126242',
    },

    cguPage: 'https://thefoodassembly.com/en/p/terms',
    homepage: 'https://thefoodassembly.com',
    minirucheHelpCenter: 'https://miniruche.zendesk.com/hc/fr',
};
