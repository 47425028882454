import isAppleDevice from 'modules/utils/isAppleDevice';

/**
 * Return a `sms:` HTML link.
 *
 * iOS 8+:
 *
 * ```
 * sms://open?addresses=<phone1,phone2>&body=<body text here>
 * ```
 *
 * Android:
 *
 * ```
 * sms:<phone1,phone2>?body=<body text here>
 * ```
 *
 * @param  {String|Array}  to
 * @param  {String}  message
 * @return {String}
 */
export function smsLink(to, message) {
    let recipients = Array.isArray(to) ? to.join(',') : to;

    if (isAppleDevice) {
        recipients = `//open?addresses=${recipients}`;
    }

    let body = '';
    if (message) {
        body = `${isAppleDevice ? '&' : '?'}body=${encodeURIComponent(message)}`;
    }

    return `sms:${recipients}${body}`;
}
