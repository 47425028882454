import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { sortAlpha } from 'modules/utils/list.js';
import { capitalizeFirstLetterOnly } from 'modules/utils/string';
import { get } from 'miniruche/services/api';
import { fetchPickups, savePickups } from 'miniruche/services/pickup/pickup.service.js';
import PickupGroup from 'miniruche/components/pickup/PickupGroup.jsx';
import { Page, Section, Text, TabSet, RevealContactInformationLink } from 'miniruche/ui';
import PageHeader from 'miniruche/ui/layout/PageHeader.jsx';

export class Pickup extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        saleId: PropTypes.number.isRequired,
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
    };

    state = {
        status: 'INITIAL_LOADING',
        activeTab: 'by_farm',
        farms: [],
        users: [],
        orderItems: [],
        offersIndexed: {},
        productsIndexed: {},
        pickups: {},
        orderPickupConstraints: {},
    };

    componentDidMount() {
        const fetchPickupsRequest = fetchPickups(this.props.saleId);
        const salePickupRequest = get(
            `miniruche/admin/${this.props.slug}/sales/${this.props.saleId}/orders`
        );
        Promise.all([fetchPickupsRequest, salePickupRequest]).then(this.onDataLoaded);
    }

    onDataLoaded = ([
        pickups,
        {
            farms,
            users,
            products,
            offers,
            orderItems,
            distributionStart,
            distributionEnd,
            miniRucheName,
            contactInformation,
            ordersCount,
            ordersPickupConstraints,
        },
    ]) => {
        const orderItemsByFarm = R.groupBy(R.prop('farmUuid'), orderItems);
        const orderItemsByUser = R.groupBy(R.prop('userUuid'), orderItems);
        const offersIndexed = R.indexBy(R.prop('uuid'), offers);
        const productsIndexed = R.indexBy(R.prop('uuid'), products);

        const groupByOffer = R.pipe(
            R.groupBy(R.prop('offerUuid')),
            R.mapObjIndexed((items, offerUuid) => ({
                offer: offersIndexed[offerUuid],
                product: productsIndexed[offersIndexed[offerUuid].productUuid],
                orderItems: R.pluck('id')(items),
                quantity: R.pipe(R.pluck('quantity'), R.sum)(items),
            })),
            R.values,
            sortAlpha(['product', 'name'])
        );

        const farmsWithPickupItems = farms.map(farm => ({
            ...farm,
            orderNumbers: R.pipe(R.pluck('orderNumber'), R.uniq)(orderItemsByFarm[farm.uuid]),
            pickupItems: groupByOffer(orderItemsByFarm[farm.uuid]),
        }));

        const usersWithPickupItems = users.map(user => ({
            ...user,
            orderNumbers: R.pipe(R.pluck('orderNumber'), R.uniq)(orderItemsByUser[user.uuid]),
            pickupItems: groupByOffer(orderItemsByUser[user.uuid]),
        }));

        this.setState({
            farms: farmsWithPickupItems,
            users: usersWithPickupItems,
            pickups,
            status: 'READY',
            distributionStart,
            distributionEnd,
            miniRucheName,
            contactInformation,
            ordersCount,
            ordersPickupConstraints,
        });
    };

    onTabChange = activeTab => {
        this.setState({
            activeTab,
        });
    };

    updatePickups = (orderItemIds, value) => {
        const keys = orderItemIds;
        const values = orderItemIds.map(R.always(value));
        const pickups = R.zipObj(keys, values);
        this.setState(
            previousState => ({
                pickups: {
                    ...previousState.pickups,
                    ...pickups,
                },
            }),
            () => savePickups(this.props.saleId, this.state.pickups)
        );
    };

    pickupAll = orderItemIds => this.updatePickups(orderItemIds, true);

    releaseAll = orderItemIds => this.updatePickups(orderItemIds, false);

    render() {
        const { slug, trans, transChoice } = this.props;

        const {
            activeTab,
            farms,
            users,
            pickups,
            status,
            ordersCount,
            ordersPickupConstraints,
            contactInformation,
        } = this.state;

        if (status === 'INITIAL_LOADING') {
            return <Page blue />;
        }

        return (
            <Page blue>
                <PageHeader
                    title={trans('miniruche.admin.sales.title')}
                    backHref={`/${slug}`}
                    backText={trans('miniruche.sale.backToSales')}
                />
                <Section>
                    <Text strong size="large" className="u-spacing-stack-m">
                        {ordersCount}
                        &nbsp;
                        {transChoice('global.ordersPluralized', ordersCount)}
                        {' - '}
                        {Math.max(Math.round(ordersPickupConstraints.totalWeightInMg / 1000000), 1)}
                        &nbsp;kg
                    </Text>
                    <div className="u-spacing-stack-l">
                        <Text strong>
                            {trans('user.roles.host')}&nbsp;:&nbsp;{contactInformation.name}
                        </Text>
                        <RevealContactInformationLink
                            email={contactInformation.email}
                            phone={contactInformation.phone}
                        >
                            {trans('global.contacting')}
                        </RevealContactInformationLink>
                    </div>
                    <TabSet
                        className="u-spacing-stack-m"
                        tabs={[
                            {
                                value: 'by_farm',
                                label: trans('dashboards.host.cards.perfectOrders.per.farm'),
                            },
                            {
                                value: 'by_user',
                                label: trans('miniruche.admin.sales.byUser'),
                            },
                        ]}
                        value={activeTab}
                        onChange={this.onTabChange}
                    />
                    {activeTab === 'by_user' && (
                        <div>
                            {users.map(user => {
                                return (
                                    <PickupGroup
                                        key={user.uuid}
                                        className="u-spacing-stack-m"
                                        label={`${user.firstName} ${user.lastName}`}
                                        items={user.pickupItems}
                                        orderNumbers={user.orderNumbers}
                                        pickups={pickups}
                                        pickupAll={this.pickupAll}
                                        releaseAll={this.releaseAll}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {activeTab === 'by_farm' && (
                        <div>
                            {farms.map(farm => {
                                return (
                                    <PickupGroup
                                        key={farm.uuid}
                                        className="u-spacing-stack-m"
                                        label={capitalizeFirstLetterOnly(farm.name)}
                                        items={farm.pickupItems}
                                        orderNumbers={farm.orderNumbers}
                                        pickups={pickups}
                                        pickupAll={this.pickupAll}
                                        releaseAll={this.releaseAll}
                                    />
                                );
                            })}
                        </div>
                    )}
                </Section>
            </Page>
        );
    }
}

export default connectToI18n(({ match, i18n, trans, transChoice }) => (
    <Pickup
        i18n={i18n}
        trans={trans}
        transChoice={transChoice}
        slug={match.params.slug}
        saleId={parseInt(match.params.saleId, 10)}
    />
));
