import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import connectToI18n from 'modules/i18n/connectToI18n';
import Price from 'components/Price.jsx';
import Quantity from 'components/Offers/Quantity.jsx';
import { getItem } from 'miniruche/modules/shopping/store/basketSelectors';
import { filerPhoto } from 'modules/utils/filer';
import { capitalizeFirstLetter } from 'modules/utils/string';
import {
    BASKET_INCREMENT_QUANTITY,
    BASKET_DECREMENT_QUANTITY,
} from 'miniruche/modules/shopping/store/basketActionTypes';
import { Split, Grow, Text, Image, Button } from 'miniruche/ui';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';

export class ProductSummary extends React.Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        quantityInBasket: PropTypes.number.isRequired,
        dispatch: PropTypes.func.isRequired,
    };

    onAdd = () => {
        addUniversalEvent({
            eventCategory: 'Checkout',
            eventAction: 'offer_added',
            eventLabel: this.props.item.offer.id,
            eventValue: this.props.item.offer.price.amount,
        });
        this.props.dispatch({
            type: BASKET_INCREMENT_QUANTITY,
            offer: this.props.item.offer,
        });
    };

    onRemove = () => {
        addUniversalEvent({
            eventCategory: 'Checkout',
            eventAction: 'offer_removed',
            eventLabel: this.props.item.offer.id,
            eventValue: this.props.item.offer.price.amount,
        });
        this.props.dispatch({
            type: BASKET_DECREMENT_QUANTITY,
            offer: this.props.item.offer,
        });
    };

    getImage = () => {
        const { item } = this.props;

        // TODO : replace with the product categoryId;
        const categoryId = 680;

        if (item.product.photo) {
            return filerPhoto(item.product.photo.id, 'small');
        }

        return `/assets/images/products/product-${categoryId}.png`;
    };

    render() {
        const { item, quantityInBasket } = this.props;

        if (quantityInBasket <= 0) {
            return null;
        }

        return (
            <Split gutter="small" className="u-spacing-stack-m">
                <Grow weight={0}>
                    <Image width="86" src={this.getImage()} />
                </Grow>
                <Grow weight={1}>
                    <Text>{capitalizeFirstLetter(item.product.name.toLowerCase())}</Text>
                    <Text className="u-spacing-stack-s">
                        <Quantity offer={item.offer} strategy={item.quantityStrategy} />
                    </Text>
                    <Split align="center">
                        <Grow weight={0}>
                            <Button icon="minus" small variant="outline" onClick={this.onRemove} />
                        </Grow>
                        <Grow weight={0}>
                            <div style={{ minWidth: '30px', textAlign: 'center' }}>
                                <Text strong size="large">
                                    {quantityInBasket}
                                </Text>
                            </div>
                        </Grow>
                        <Grow weight={0}>
                            <Button icon="plus" small variant="outline" onClick={this.onAdd} />
                        </Grow>
                    </Split>
                </Grow>
                <Grow weight={0}>
                    <Price
                        price={{
                            currency: item.offer.price.currency,
                            amount: quantityInBasket * item.offer.price.amount,
                        }}
                    />
                </Grow>
            </Split>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        quantityInBasket: getItem(state, {
            slug: props.slug,
            saleId: props.saleId,
            offerId: props.item.offer.id,
        }),
    };
}

export default connect(mapStateToProps)(connectToI18n(ProductSummary));
