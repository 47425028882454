import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import PickupConstraints from 'miniruche/components/sale/PickupConstraints.jsx';
import DistributionDate from 'miniruche/components/sale/DistributionDate.jsx';
import SaleStatus from 'miniruche/components/sale/SaleStatus.jsx';
import ConveyorsList from 'miniruche/components/sale/ConveyorsList.jsx';
import { Box, Heading, Text, Button, RevealContactInformationLink } from 'miniruche/ui';

class AdminSale extends React.Component {
    static propTypes = {
        sale: PropTypes.object.isRequired,
        miniRuche: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
    };

    render() {
        const { sale, miniRuche, trans, transChoice } = this.props;

        return (
            <Box>
                <Heading rank={3} size="small" className="u-spacing-stack-xs">
                    {sale.assembly.name}
                </Heading>
                <SaleStatus className="u-spacing-stack-m" sale={sale} />
                <DistributionDate className="u-spacing-stack-xs" sale={sale} />
                <PickupConstraints className="u-spacing-stack-xs" sale={sale} />
                {sale.conveyors.length > 0 && (
                    <div className="u-spacing-stack-m">
                        {transChoice('miniruche.sale.conveyorPluralized', sale.conveyors.length)}
                        {' : '}
                        <ConveyorsList className="u-spacing-stack-m" conveyors={sale.conveyors} />
                    </div>
                )}
                <div className="u-spacing-stack-m">
                    <Button
                        block
                        variant="primary"
                        to={`/${miniRuche.slug}/admin/sales/${sale.id}`}
                        className="u-spacing-stack-xs"
                    >
                        {trans('miniruche.sale.follow')}
                    </Button>
                </div>
                <Text strong>
                    {trans('hives.page.leaderRole')} : {sale.assembly.contactInformation.name}
                </Text>
                <RevealContactInformationLink
                    email={sale.assembly.contactInformation.email}
                    phone={sale.assembly.contactInformation.phone}
                >
                    {trans('global.contacting')}
                </RevealContactInformationLink>
            </Box>
        );
    }
}

export default connectToI18n(AdminSale);
