import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { READY, SAVING } from 'modules/utils/ajaxStatuses';
import { patch } from 'miniruche/services/api';
import { Split, Heading, Text } from 'miniruche/ui';
import SettingChangeConfirmationModal from './SettingChangeConfirmationModal.jsx';

class FeeRateSetting extends React.Component {
    static propTypes = {
        miniruche: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        errors: [],
        feeRate: parseFloat(this.props.miniruche.feeRate),
        previousFeeRate: parseFloat(this.props.miniruche.feeRate),
        status: READY,
    };

    onFeeRateChange = event => {
        const feeRate = parseFloat(event.target.value);
        const previousFeeRate = this.state.feeRate;
        this.setState({ feeRate, previousFeeRate });
    };

    onCancel = () => {
        const feeRate = this.state.previousFeeRate;
        this.setState({
            errors: [],
            feeRate,
            status: READY,
        });
    };

    onConfirm = () => {
        const feeRate = this.state.feeRate;
        patch(`miniruche/admin/${this.props.miniruche.slug}`, { feeRate }).then(
            () => this.setState({ previousFeeRate: feeRate, status: READY }),
            () => this.setState({ errors: ['La sauvegarde a échoué'], status: READY })
        );
        this.setState({ status: SAVING });
    };

    render() {
        const { errors, feeRate, previousFeeRate, status } = this.state;
        const { trans } = this.props;

        return (
            <div>
                <Heading rank={3} size="medium" className="u-spacing-stack-m">
                    {trans('miniruche.admin.edit.feesHeading')}
                </Heading>
                <Text className="u-spacing-stack-m">{trans('miniruche.admin.fees.text')}</Text>
                <Split>
                    <label className="u-spacing-stretch-s">
                        <input
                            type="radio"
                            name="fee"
                            value="0"
                            onChange={this.onFeeRateChange}
                            checked={feeRate === 0}
                            className="u-spacing-inline-s"
                        />
                        0%
                    </label>
                    <label className="u-spacing-stretch-s">
                        <input
                            type="radio"
                            name="fee"
                            value="0.05"
                            onChange={this.onFeeRateChange}
                            checked={feeRate === 0.05}
                            className="u-spacing-inline-s"
                        />
                        5%
                    </label>
                    <label className="u-spacing-stretch-s">
                        <input
                            type="radio"
                            name="fee"
                            value="0.1"
                            onChange={this.onFeeRateChange}
                            checked={feeRate === 0.1}
                            className="u-spacing-inline-s"
                        />
                        10%
                    </label>
                </Split>
                {feeRate !== previousFeeRate && (
                    <SettingChangeConfirmationModal
                        onCancel={this.onCancel}
                        onConfirm={this.onConfirm}
                        actionsDisabled={status === SAVING}
                        errors={errors}
                    />
                )}
            </div>
        );
    }
}

export default connectToI18n(FeeRateSetting);
