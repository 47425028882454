import React from 'react';
import { Section, Button, Input, Heading, Switch } from 'miniruche/ui';

class Forms extends React.Component {
    render() {
        return (
            <div>
                <Section>
                    <Heading rank={2} size="large" className="u-spacing-stack-m">
                        Forms
                    </Heading>
                    <Input type="email" label="E-mail" />
                    <Input
                        type="text"
                        label="First name"
                        errors={['This field is mandatory', 'Your name should be Bill']}
                    />
                    <Input
                        type="Password"
                        label="Password"
                        placeholder="Please enter your password here"
                    />
                    <Input type="time" label="Please select a time" intervalInMinutes={15} />
                    <Input
                        type="timerange"
                        label="Please select a time range"
                        intervalInMinutes={15}
                    />
                    <Input
                        label="Vegetables"
                        type="dropdown"
                        placeholder="Please pick one"
                        options={[
                            { label: 'Apple', value: 1 },
                            { label: 'Cabbage', value: 2 },
                            { label: 'Carrot', value: 3 },
                            { label: 'Cucumber', value: 4 },
                        ]}
                        errors={['Apple is not a vegetable']}
                    />
                    <Input
                        label="Multiple vegetables"
                        type="multiple"
                        placeholder="Please pick one"
                        options={[
                            { label: 'Apple', value: 1 },
                            { label: 'Cabbage', value: 2 },
                            { label: 'Carrot', value: 3 },
                            { label: 'Cucumber', value: 4 },
                        ]}
                        errors={['Apple is not a vegetable']}
                    />
                    <p className="u-spacing-stack-m">
                        <Input
                            only
                            type="text"
                            label="This label won't show up"
                            placeholder="Just an input"
                        />
                    </p>
                    <p className="u-spacing-stack-m">
                        <Switch
                            name="switch"
                            trueLabel="true"
                            falseLabel="false"
                            value={false}
                            onChange={() => {}}
                        />
                    </p>
                    <Button variant="primary">Submit</Button>
                </Section>
            </div>
        );
    }
}

export default Forms;
