import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';

import { Heading, Text, Button } from 'miniruche/ui';

export class PauseSection extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        sale: PropTypes.object.isRequired,
        togglePause: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const { className, sale, togglePause, trans } = this.props;

        return (
            <div className={className}>
                <Heading rank={2} size="medium" className="u-spacing-stack-s">
                    {trans('miniruche.sale.pauseSection.heading')}
                </Heading>
                <Text className="u-spacing-stack-m">
                    {trans('miniruche.sale.pauseSection.text')}
                </Text>
                <Button
                    block
                    variant={sale.isPaused ? 'warning' : 'outline'}
                    className="u-spacing-stack-l"
                    onClick={togglePause}
                >
                    {sale.isPaused
                        ? trans('miniruche.sale.pauseSection.saleStopped')
                        : trans('miniruche.sale.pauseSection.stopSale')}
                </Button>
            </div>
        );
    }
}

export default connectToI18n(PauseSection);
