import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Section extends React.Component {
    getChildContext() {
        return {
            inverse:
                (this.props.alternate && !this.context.inverse) ||
                (!this.props.alternate && this.context.inverse),
        };
    }

    render() {
        const classes = ['section'];
        if (this.props.alternate && !this.context.inverse) {
            classes.push('section--blue');
        }
        if (this.props.alternate && this.context.inverse) {
            classes.push('section--white');
        }
        if (this.props.noPadding) {
            classes.push('section--noPadding');
        }
        if (this.props.noVerticalPadding) {
            classes.push('section--noVerticalPadding');
        }
        if (this.props.small) {
            classes.push('section--small');
        }
        if (this.props.className) {
            classes.push(this.props.className);
        }
        return (
            <div className={classnames(classes)}>
                <div className="section-inner">{this.props.children}</div>
            </div>
        );
    }
}

Section.propTypes = {
    children: PropTypes.node,
    alternate: PropTypes.bool,
    noPadding: PropTypes.bool,
    noVerticalPadding: PropTypes.bool,
    small: PropTypes.bool,
    className: PropTypes.string,
};

Section.contextTypes = {
    inverse: PropTypes.bool,
};

Section.childContextTypes = {
    inverse: PropTypes.bool,
};

export default Section;
