import { get, post } from 'miniruche/services/api';
import * as R from 'ramda';
import {
    BASKET_INCREMENT_QUANTITY,
    BASKET_DECREMENT_QUANTITY,
    BASKET_SAVE,
    BASKET_SAVE_SUCCESS,
    BASKET_SAVE_ERROR,
    BASKET_FETCH,
    BASKET_FETCH_SUCCESS,
    BASKET_FETCH_ERROR,
} from './basketActionTypes';
import { SAVING, LOADING } from './basketStatuses';

function parseItems(serverBasket) {
    return R.pipe(
        R.propOr([], 'items'),
        R.map(item => [
            item.offer.id,
            {
                offerId: item.offer.id,
                unitPrice: item.offer.price,
                quantity: item.quantity,
            },
        ]),
        R.fromPairs
    )(serverBasket);
}

function getFee(serverBasket) {
    return R.path(['specialFees', 0], serverBasket);
}

function saveItems(state) {
    const url = `miniruche/${state.slug}/sale/${state.saleId}/basket`;
    const items = R.map(item => item.quantity, state.basket.items);
    return post(url, items);
}

function fetchItems(state) {
    const url = `miniruche/${state.slug}/sale/${state.saleId}/basket`;
    return get(url);
}

export default function basketsEffects(previousState, nextState, dispatch, action) {
    switch (action.type) {
        case BASKET_INCREMENT_QUANTITY:
        case BASKET_DECREMENT_QUANTITY:
            return dispatch({
                type: BASKET_SAVE,
            });

        case BASKET_FETCH:
            if (nextState.basket.status !== LOADING) {
                return null;
            }
            return fetchItems(nextState).then(
                serverBasket => {
                    dispatch({
                        type: BASKET_FETCH_SUCCESS,
                        items: parseItems(serverBasket),
                        fee: getFee(serverBasket),
                    });
                },
                () => {
                    dispatch({
                        type: BASKET_FETCH_ERROR,
                    });
                }
            );

        case BASKET_SAVE:
        case BASKET_SAVE_SUCCESS:
        case BASKET_SAVE_ERROR:
            if (nextState.basket.status !== SAVING || nextState.basket.scheduledSave) {
                return null;
            }
            return saveItems(nextState).then(
                serverBasket => {
                    dispatch({
                        type: BASKET_SAVE_SUCCESS,
                        items: parseItems(serverBasket),
                        fee: getFee(serverBasket),
                    });
                },
                () => {
                    dispatch({
                        type: BASKET_SAVE_ERROR,
                    });
                }
            );

        default:
            return null;
    }
}
