import Globalize from 'globalize';
import './globalize.culture.nl-NL';
Globalize.addCultureInfo('nl', 'nl-NL');

import moment from 'moment';
import 'moment/locale/nl';
moment.defineLocale('nl-NL', { parentLocale: 'nl' });

export default {
    code: 'nl-NL',

    // bcode is here for the "backCode" ... the code as seen/sent by the back :O
    bCode: 'nl_NL',

    name: 'Nederlands',
    shortcut: 'nl',
    supportEmail: 'helpdesk@boerenenburen.nl',
    privacyEmail: 'privacy@boerenenburen.nl',
    trainingSite: null,

    supportSite: {
        widgetDomain: 'boerenenburen.zendesk.com',
        kycInformation:
            'https://s3-eu-west-1.amazonaws.com/laruche-prod/BENLcom/2016.04.06-FAQMembers-AdditionalInformationbeforePaymentBoerenBuren.pdf',
        proImprovementsHost:
            'https://s3-eu-west-1.amazonaws.com/help-nl/HelpPageNLBuren-VERBETERINGEN+IN+DE+RUIMTE+VOOR+JE+BV-DOSSIER.pdf',
        proImprovementsProd:
            'https://s3-eu-west-1.amazonaws.com/help-nl/HelpPageNLBuren-VERBETERINGEN+IN+DE+RUIMTE+VOOR+JE+PRODUCENTEN-DOSSIER.pdf',
        host:
            'https://boerenenburen.zendesk.com/hc/nl/categories/201190425-Buurderij-Verantwoordelijken',
        farmer: 'https://boerenenburen.zendesk.com/hc/nl/categories/201190385-Producenten',
        member: 'https://boerenenburen.zendesk.com/hc/nl/categories/201190405-Buren',
        user: 'https://boerenenburen.zendesk.com/hc/nl/categories/201190405-Buren',
    },

    appStores: {
        apple: 'https://itunes.apple.com/be/app/boeren-buren/id1052198033?l=nl&mt=8',
        google: 'https://play.google.com/store/apps/details?id=com.lrqdo&hl=nl',
    },

    logos: {
        brown: '/assets/images/logos/brown/logo-nl.svg',
        turquoise: '/assets/images/logos/turquoise/logo-nl.svg',
        white: '/assets/images/logos/white/logo-nl.svg',
        whiteOutlined: '/assets/images/logos/whiteOutlined/logo-nl.svg',
        blue: '/assets/images/logos/blue/logo-nl.svg',
        christmas: '/assets/images/logos/christmas/logo-nl.svg',
    },

    social: {
        facebook: 'https://www.facebook.com/boerenenburen',
        twitter: 'https://twitter.com/BoerenenBuren',
        blog: 'http://blog.boerenenburen.be/',
        instagram: 'https://instagram.com/boerenenburen',
        youtube: 'https://vimeo.com/user45067441',
        linkedin: 'https://www.linkedin.com/company/la-ruche-qui-dit-oui-',
    },

    links: {
        about: 'https://wij.boerenenburen.nl/',
        press: 'https://wij.boerenenburen.nl/pers/',
        joinUs: 'https://wij.boerenenburen.nl/ons-team/',
        order: 'https://boerenenburen.nl/nl/assemblies',
        giftCards: 'https://boerenenburen.nl/nl/giftcards',
        openRuche: 'https://boerenenburen.nl/nl/p/open',
        provideRuche: 'https://boerenenburen.nl/nl/p/provide',
        ourDatas: 'https://wij.boerenenburen.nl/jouw-gegevens/',
        proposeLocal: '',
    },

    proposeLocalForm: 'https://forms.gle/bDGcgTzpLsLCx6tU9',

    dateFormats: {
        d: 'DD-M-YYYY',
        D: 'dddd DD MMMM YYYY',
        I: 'dddd DD MMMM',
        i: 'DD MMMM YYYY',
        t: 'HH:mm',
        T: 'HH:mm:ss',
        f: 'dddd DD MMMM YYYY HH:mm',
        F: 'dddd DD MMMM YYYY HH:mm:ss',
        M: 'DD MMMM',
        Y: 'MMMM YYYY',
        shortDayDate: 'ddd DD MMMM',
        dateWithoutYear: 'DD-MM',
    },

    inlineLogo: require('./logo.svg'),
    zendeskLocale: 'nl',
    zendeskSearch: 'https://boerenenburen.zendesk.com/api/v2/help_center/',
    zendeskCategories: {
        farmer: '201190385',
        member: '201190405',
        user: '201190405',
        host: '201190425',
    },

    cguPage: 'https://boerenenburen.nl/nl/p/terms',
    homepage: 'https://boerenenburen.nl',
    minirucheHelpCenter: 'https://boerenenburen.zendesk.com/hc/nl',
};
