import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Page extends React.Component {
    getChildContext() {
        return {
            inverse: !!this.props.blue,
        };
    }

    render() {
        const classes = ['page'];
        if (this.props.className) {
            classes.push(this.props.className);
        }
        if (this.props.blue) {
            classes.push('page--blue');
        }
        if (this.props.white) {
            classes.push('page--white');
        }
        return <div className={classnames(classes)}>{this.props.children}</div>;
    }
}

Page.propTypes = {
    children: PropTypes.node,
    blue: PropTypes.bool,
    white: PropTypes.bool,
    className: PropTypes.string,
};

Page.defaultProps = {
    white: true,
};

Page.childContextTypes = {
    inverse: PropTypes.bool,
};

export default Page;
