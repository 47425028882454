import { get, post, del } from 'miniruche/services/api';
import { setItem, getItem } from 'modules/localstorage';
import * as R from 'ramda';
import { shuffle } from 'underscore';

const sortOffers = R.evolve({
    offers: R.sortBy(R.path(['price', 'amount'])),
});

export function fetchProducts({ categoryId, saleId, slug }) {
    const query = {};

    if (categoryId) {
        query.category = categoryId;
    }

    return get(`miniruche/${slug}/sales/${saleId}/products`, query).then(R.map(sortOffers));
}

export function searchProducts({ keyword, saleId, slug }) {
    return get(`miniruche/${slug}/sales/${saleId}/search/${keyword}`).then(R.map(sortOffers));
}

export function fetchPreviouslyBoughtProductIds({ categoryId, saleId }) {
    const query = {
        onlyPreviouslyBought: true,
    };

    if (categoryId) {
        query.category = categoryId;
    }

    return get(`distributions/${saleId}/products`, query).then(
        R.pipe(R.prop('products'), R.pluck('id'))
    );
}

function getDismissedBanners() {
    const dismissedBannersJSON = getItem('miniruche_dismissed_banners');
    return dismissedBannersJSON ? JSON.parse(dismissedBannersJSON) : [];
}

function setDismissedBanners(dismissedBanners) {
    setItem('miniruche_dismissed_banners', JSON.stringify(dismissedBanners));
}

export function fetchDismissedBanners() {
    return Promise.resolve(getDismissedBanners());
}

export function like({ productId }) {
    return post(`miniruche/product/${productId}/like`);
}

export function unlike({ productId }) {
    return del(`miniruche/product/${productId}/like`);
}

export function dismissBanner({ productId }) {
    setDismissedBanners([...getDismissedBanners(), productId]);
    return Promise.resolve();
}

export const optimisticLike = author =>
    R.evolve({
        likes: R.inc(),
        likeAuthors: R.append(author),
        hasLiked: R.always(true),
    });

const removeFirst = e => a => (R.contains(e, a) ? R.remove(R.indexOf(e, a), 1, a) : a);

export const optimisticUnlike = author =>
    R.evolve({
        likes: R.dec(),
        likeAuthors: removeFirst(author),
        hasLiked: R.always(false),
    });

export const optimisticDismissBanner = R.evolve({
    isBannerDismissed: R.always(true),
});

// Relative weights based on BV-by-category stats
// Absent categories will never be picked
const categoryWeights = {
    '1': 10, // Fruits & vegetables
    '257': 6, // Meat
    '239': 4, // Dairy & eggs
    '492': 2, // Pantry
    '320': 2, // Bakery
    '415': 1, // Seafood
    '371': 1, // Beverages
    '670': 1, // Ready-to-eat
};
const compareByLikes = R.descend(R.prop('likes'));

export function getFeaturedProducts(payload, count) {
    return fetchProducts(payload).then(
        R.pipe(
            R.filter(product => R.has(product.rootCategoryId, categoryWeights)),
            shuffle,
            R.groupBy(R.prop('rootCategoryId')),
            R.mapObjIndexed((products, categoryId) =>
                R.pipe(R.sort(compareByLikes), R.take(categoryWeights[categoryId]))(products)
            ),
            R.values,
            R.flatten,
            shuffle,
            R.take(count)
        )
    );
}
