import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Button, Page } from 'miniruche/ui';
import AddressAutocomplete from 'miniruche/components/address/AddressAutocomplete.jsx';

class Landing extends React.Component {
    static propTypes = {
        address: PropTypes.string.isRequired,
        i18nConfig: PropTypes.object.isRequired,
        onAddressChange: PropTypes.func.isRequired,
        onSubmitAddress: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
        errors: PropTypes.array,
    };

    render() {
        const { address, i18nConfig, onAddressChange, onSubmitAddress, trans, errors } = this.props;

        const localeShortcut = i18nConfig.shortcut;

        const miniruche_video_id_fr = 'NqIaT29v3Tw';
        const miniruche_video_id_ca = 'COO4UZkeG0I';
        const miniruche_video_id_es = 'bN3OzQgRZFg';
        const miniruche_illu_de = 'abholpunkt.jpg';
        const miniruche_illu_it = 'mini-alveare.jpg';
        const miniruche_illu_nl = 'ophaalpunt.jpg';

        const getYoutubeVideoSnippet = id => (
            <iframe
                width="800"
                height="450"
                src={`https://www.youtube.com/embed/${id}?rel=0&amp;controls=1&amp;showinfo=0`}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
            />
        );
        const getIllustrationImgSnippet = filename => (
            <img
                src={`/assets/images/miniruche/illustrations/${filename}`}
                alt="mini ruche - illustration"
                width="100%"
            />
        );

        let illustration;
        switch (localeShortcut) {
            case 'de':
                illustration = getIllustrationImgSnippet(miniruche_illu_de);
                break;
            case 'it':
                illustration = getIllustrationImgSnippet(miniruche_illu_it);
                break;
            case 'nl':
                illustration = getIllustrationImgSnippet(miniruche_illu_nl);
                break;
            case 'ca':
                illustration = getYoutubeVideoSnippet(miniruche_video_id_ca);
                break;
            case 'es':
                illustration = getYoutubeVideoSnippet(miniruche_video_id_es);
                break;
            default:
                illustration = getYoutubeVideoSnippet(miniruche_video_id_fr);
        }

        return (
            <Page>
                <div className="landing">
                    <div className="landing-header">
                        <div className="landing-container">
                            <img
                                className="landing-logo"
                                src={`/assets/images/logos/logo-miniruche-whitebg-${localeShortcut}.svg`}
                                alt="mini ruche"
                                width="200"
                            />
                            <p className="landing-baseline">
                                {trans('miniruche.create.landing.deliveryService')}{' '}
                                <span className="landing-baseline-lrqdo">
                                    {trans('brand.name')}
                                </span>
                                {', '}
                                {trans('miniruche.create.landing.aloneOrNot')}
                            </p>
                            <div className="landing-header-part2">
                                {R.includes(localeShortcut, ['de', 'it', 'nl']) ? (
                                    <div className="landing-header-part2-left">{illustration}</div>
                                ) : (
                                    <div className="landing-header-part2-left landing-header-part2-left--with-shadow">
                                        <div className="video-container">{illustration}</div>
                                    </div>
                                )}
                                <div className="landing-header-part2-right">
                                    <h3 className="landing-subheading u-spacing-stack-m">
                                        {trans('miniruche.create.landing.text1')}
                                    </h3>
                                    <p className="landing-text u-spacing-stack-s">
                                        {trans('miniruche.create.landing.address')}
                                    </p>
                                    <AddressAutocomplete
                                        autofocus
                                        address={address}
                                        onAddressChange={onAddressChange}
                                        placeholder={trans(
                                            'miniruche.addressForm.inputPlaceholder'
                                        )}
                                        errors={errors}
                                    />
                                    <Button block variant="primary" onClick={onSubmitAddress}>
                                        {trans('miniruche.create.ready')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="landingSection1 landing-container">
                        <div className="landingSection1-image">
                            <img src="/assets/images/illustrations/miniruche-landing1.gif?v=2" />
                        </div>
                        <div className="landingSection1-text">
                            <h3 className="landing-subheading u-spacing-stack-xs">
                                {trans('miniruche.create.landing.text2')}
                            </h3>
                            <p className="landing-text u-spacing-stack-s">
                                {trans('miniruche.create.landing.text3')}
                            </p>
                            <p className="landing-text u-spacing-stack-s">
                                {trans('miniruche.create.landing.text4')}
                            </p>
                        </div>
                    </div>
                    <div className="landing-container">
                        <h2 className="landing-heading">
                            {trans('miniruche.create.landing.heading')}
                        </h2>
                        <div className="landingSection3">
                            <div className="landingSection3-image">
                                <img src="/assets/images/illustrations/miniruche-landing3.gif?v=2" />
                            </div>
                            <div className="landingSection3-text">
                                <h3 className="landing-subheading u-spacing-stack-xs">
                                    {trans('miniruche.create.landing.text5')}
                                </h3>
                                <p className="landing-text u-spacing-stack-s">
                                    {trans('miniruche.create.landing.text6')}
                                </p>
                                <h3 className="landing-subheading u-spacing-stack-xs">
                                    {trans('miniruche.create.landing.text7')}
                                </h3>
                                <p className="landing-text">
                                    {trans('miniruche.create.landing.text8')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="landingFooter">
                    <div className="landing-container">
                        <div className="landingFooter-left">
                            <p>{trans('miniruche.create.landing.by')}</p>
                            <a href={i18nConfig.homepage}>
                                <img width="200" src={i18nConfig.logos.turquoise} />
                            </a>
                        </div>
                        <div className="landingFooter-right">
                            <a
                                className="landingFooter-help"
                                href={i18nConfig.minirucheHelpCenter}
                                target="_blank"
                            >
                                {trans('global.helpCenter')}
                            </a>
                            <br />
                            <a
                                className="landingFooter-support"
                                href={`mailto:${i18nConfig.supportEmail}`}
                                target="_blank"
                            >
                                {i18nConfig.supportEmail}
                            </a>
                            <br />
                            {localeShortcut === 'fr' && '01 82 28 94 03'}
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

export default connectToI18n(Landing);
