import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Price from 'components/Price.jsx';
import { getTotal } from 'miniruche/modules/shopping/store/basketSelectors';

export class BasketTotal extends React.Component {
    static propTypes = {
        total: PropTypes.object,
    };

    render() {
        const { total } = this.props;

        if (!total) {
            return null;
        }

        return <Price price={total} />;
    }
}

function mapStateToProps(state) {
    return {
        total: getTotal(state),
    };
}

export default connect(mapStateToProps)(BasketTotal);
