import PropTypes from 'prop-types';
import React from 'react';
import { transparentPixel } from 'resources/base64';

export class ImageLoader extends React.Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        placeholder: transparentPixel,
    };

    state = {
        src: this.props.placeholder,
    };

    componentDidMount() {
        this.img = new Image();
        this.img.onload = this.onLoad;
        this.img.src = this.props.src;
    }

    componentWillUnmount() {
        if (!this.img) {
            return;
        }
        this.img.onload = () => {};
        delete this.img;
    }

    onLoad = () => {
        this.setState({
            src: this.props.src,
        });
    };

    render() {
        return <img src={this.state.src} alt={this.props.alt} className={this.props.className} />;
    }
}

export default ImageLoader;
