import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Product from './Product.jsx';

export class ProductModal extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        saleId: PropTypes.number.isRequired,
        categoryId: PropTypes.number.isRequired,
        product: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    onClose = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <div className="miniProductModal">
                <Product
                    slug={this.props.slug}
                    saleId={this.props.saleId}
                    categoryId={this.props.categoryId}
                    productId={this.props.product.id}
                    initialProduct={this.props.product}
                    closeModal={this.onClose}
                />
            </div>
        );
    }
}

export default withRouter(ProductModal);
