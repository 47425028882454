import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { get, post } from 'miniruche/services/api';
import { validateOrder } from 'miniruche/services/basket/basket.service';
import CityAutocomplete from 'miniruche/components/address/CityAutocomplete.jsx';
import { Page, Section, Heading, Button, Text, Input } from 'miniruche/ui';

class UserInformation extends React.Component {
    constructor(props) {
        super(props);
        this.onDataLoaded = this.onDataLoaded.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBirthdayChange = this.onBirthdayChange.bind(this);
        this.onZipCodeChange = this.onZipCodeChange.bind(this);
        this.onCitySelected = this.onCitySelected.bind(this);
        this.saveUserInformations = this.saveUserInformations.bind(this);
        this.state = {
            errors: {},
            phone: '',
            street: '',
            city: {
                id: null,
                name: null,
                zipCode: '',
            },
            birthday: {
                day: '',
                month: '',
                year: '',
            },
            nationality: '',
            hasAcceptedTOS: '',
            user: null,
            countries: [],
            status: 'INITIAL_LOADING',
        };
    }

    componentDidMount() {
        const userRequest = get('me/');
        const countriesRequest = get('countries/');
        Promise.all([userRequest, countriesRequest]).then(this.onDataLoaded);
    }

    onDataLoaded([user, { countries }]) {
        this.setState({
            user,
            city: user.address.city,
            nationality: user.address.country.code,
            countries,
            status: 'READY',
        });
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    onBirthdayChange(birthday) {
        this.setState({
            birthday,
        });
    }

    onZipCodeChange(zipCode) {
        this.setState({
            city: {
                zipCode,
                id: null,
                name: null,
            },
        });
    }

    onCitySelected({ id, name, zipCode }) {
        this.setState({
            city: {
                name,
                id,
                zipCode,
            },
        });
    }

    saveUserInformations(event) {
        event.preventDefault();
        this.setState({
            status: 'SAVING',
        });
        post(`users/${this.state.user.id}/profileForOrder/${this.props.orderUuid}`, {
            phone: this.state.phone,
            birthday: `${this.state.birthday.year}-${this.state.birthday.month}-${this.state.birthday.day}`,
            nationality: this.state.nationality,
            address: {
                street: this.state.street,
                city: this.state.city.id,
            },
            hasAcceptedTOS: true,
        }).then(
            () => {
                validateOrder(this.props.saleId, this.props.slug);
            },
            err => {
                this.setState({
                    status: 'READY',
                });
                if (err.status === 400) {
                    const errors = R.pipe(
                        R.path(['responseJSON', 'detail', 'errors']),
                        R.defaultTo({})
                    )(err);
                    this.setState({ errors });
                }
            }
        );
    }

    render() {
        const { errors, status, countries, city } = this.state;
        const { trans } = this.props;

        if (status === 'INITIAL_LOADING') {
            return <div className="miniRucheWrapper miniRucheWrapper--blue" />;
        }

        return (
            <Page blue>
                <Section small>
                    <div className="u-center u-spacing-stack-xl">
                        <img width="200" src="/assets/images/logos/logo-miniruche.svg" />
                    </div>
                    <Heading rank={1} size="large" className="u-spacing-stack-l">
                        {trans('paymentpage.paymentInformation')}
                    </Heading>
                    <Text className="miniRucheLogin-text">
                        {trans('miniruche.userInformation.moreInformation')}
                    </Text>
                    <form>
                        <div>
                            <Input
                                autofocus
                                label={trans('global.phone')}
                                name="phone"
                                value={this.state.phone}
                                onChange={this.onChange}
                                errors={errors.phone}
                            />
                            <Input
                                type="date"
                                label={trans('global.dateOfBirth')}
                                name="birthday"
                                value={this.state.birthday}
                                onChange={this.onBirthdayChange}
                                errors={errors.birthday}
                            />
                            <Input
                                type="dropdown"
                                label={trans('global.nationality')}
                                name="nationality"
                                value={this.state.nationality}
                                options={countries.map(country => ({
                                    label: country.name,
                                    value: country.code,
                                }))}
                                onChange={this.onChange}
                                errors={errors.nationality}
                            />
                            <Input
                                label={trans('address.street')}
                                name="street"
                                value={this.state.street}
                                onChange={this.onChange}
                                errors={errors.address && errors.address.street}
                            />
                            <CityAutocomplete
                                className="u-spacing-stack-xl"
                                label={trans('global.zipCode')}
                                zipCode={
                                    city.name ? `${city.zipCode} - ${city.name}` : city.zipCode
                                }
                                onZipCodeChange={this.onZipCodeChange}
                                onCitySelected={this.onCitySelected}
                                errors={errors.address && errors.address.city}
                            />
                        </div>
                        <Button
                            block
                            variant="primary"
                            onClick={status === 'SAVING' ? null : this.saveUserInformations}
                            disabled={status === 'SAVING'}
                        >
                            {trans('global.continue')}
                        </Button>
                    </form>
                </Section>
            </Page>
        );
    }
}

UserInformation.propTypes = {
    orderUuid: PropTypes.string.isRequired,
    saleId: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    trans: PropTypes.func.isRequired,
};

export default connectToI18n(({ match, i18n }) => (
    <UserInformation
        i18n={i18n}
        slug={match.params.slug}
        saleId={parseInt(match.params.saleId, 10)}
        orderUuid={match.params.orderUuid}
    />
));
