import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { wait } from 'modules/utils/promise';
import classnames from 'classnames';
import { Section, Heading, Button, Input, Text, Split, Grow } from 'miniruche/ui';

export class NameForm extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        next: PropTypes.func.isRequired,
        canGoToNextStep: PropTypes.bool.isRequired,
        currentStep: PropTypes.number.isRequired,
        stepCount: PropTypes.number.isRequired,
        trans: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
    };

    state = {
        status: 'INITIAL_LOADING',
    };

    componentDidMount() {
        // Wait 1ms to make sure the transition is triggered.
        wait(1).then(() => {
            this.setState({
                status: 'READY',
            });
        });
    }

    onChange = e => {
        this.props.onChange(e.target.value);
    };

    render() {
        const { name, next, canGoToNextStep, currentStep, stepCount, trans, previous } = this.props;

        return (
            <div className={classnames('u-fade', { 'u-fade-in': this.state.status === 'READY' })}>
                <Section small>
                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                        {currentStep}/{stepCount}
                        <br />
                        {trans('miniruche.name.title')}
                    </Heading>
                    <Text>{trans('miniruche.name.details')}</Text>
                </Section>
                <Section small>
                    <Input
                        type="text"
                        placeholder={trans('miniruche.nameForm.inputPlaceholder')}
                        onChange={this.onChange}
                        value={name}
                        autofocus
                    />
                </Section>
                <Section small>
                    <Split gutter="medium">
                        <Grow weight={0}>
                            <Button onClick={previous} variant="outline" icon="angle-left" />
                        </Grow>
                        <Grow weight={1} className="u-right">
                            <Button
                                block
                                variant="primary"
                                onClick={canGoToNextStep ? next : null}
                                disabled={!canGoToNextStep}
                            >
                                {trans('miniruche.create.nextStep')}
                            </Button>
                        </Grow>
                    </Split>
                </Section>
            </div>
        );
    }
}

export default connectToI18n(NameForm);
