import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import * as R from 'ramda';
import { READY, SAVING } from 'modules/utils/ajaxStatuses';
import { patch } from 'miniruche/services/api';
import AddressAutocomplete from 'miniruche/components/address/AddressAutocomplete.jsx';
import { Button, Input, FormErrors } from 'miniruche/ui';

const actions = {
    updateAddressForm(addressForm) {
        return R.evolve({ addressForm: R.mergeDeepLeft(addressForm) });
    },

    setErrorMessage(error) {
        return R.evolve({ errors: R.append(error) });
    },

    removeErrorMessage() {
        return R.evolve({ errors: R.empty });
    },

    setStatus(status) {
        return R.evolve({ status: () => status });
    },
};

class AddressSetting extends React.Component {
    static propTypes = {
        miniruche: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        addressForm: this.props.miniruche.meetingPoint,
        errors: [],
        status: READY,
    };

    onAddressChange = ({ address, coordinates }) => {
        this.setState(actions.updateAddressForm({ fullAddress: address, coordinates }));
    };

    onAdditionalInformationChange = event => {
        this.setState(actions.updateAddressForm({ additionalInformation: event.target.value }));
    };

    onAddressSave = () => {
        patch(`miniruche/admin/${this.props.miniruche.slug}`, {
            meetingPoint: this.state.addressForm,
        }).then(
            () => this.setState(actions.setStatus(READY)),
            () =>
                this.setState(
                    R.pipe(
                        actions.setStatus(READY),
                        actions.setErrorMessage('La sauvegarde a échoué')
                    )
                )
        );
        this.setState(R.pipe(actions.setStatus(SAVING), actions.removeErrorMessage()));
    };

    render() {
        const {
            addressForm: { fullAddress, additionalInformation },
            errors,
            status,
        } = this.state;
        const { trans } = this.props;

        return (
            <div>
                <AddressAutocomplete
                    label={trans('global.fullAddress')}
                    address={fullAddress}
                    onAddressChange={this.onAddressChange}
                    className="u-spacing-stack-l"
                />
                <Input
                    type="textarea"
                    label={trans('address.additional')}
                    className="u-spacing-stack-l"
                    value={additionalInformation || ''}
                    placeholder={trans('address.additional.example')}
                    onChange={this.onAdditionalInformationChange}
                />
                <Button
                    block
                    variant="primary"
                    disabled={status === SAVING}
                    onClick={this.onAddressSave}
                >
                    {trans('global.save')}
                </Button>
                <div className="u-center">
                    <FormErrors errors={errors} />
                </div>
            </div>
        );
    }
}

export default connectToI18n(AddressSetting);
