import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { get, post } from 'miniruche/services/api';
import Landing from 'miniruche/pages/create/Landing.jsx';
import AddressForm from 'miniruche/components/miniruche/AddressForm.jsx';
import NameForm from 'miniruche/components/miniruche/NameForm.jsx';
import AssembliesForm from 'miniruche/components/assembly/AssembliesForm.jsx';
import EmailForm from 'miniruche/components/miniruche/EmailForm.jsx';
import LoginOrRegisterForm from 'miniruche/components/miniruche/LoginOrRegisterForm.jsx';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';
import { Page } from 'miniruche/ui';

class Create extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        standaloneAddress: PropTypes.bool,
        trans: PropTypes.func.isRequired,
    };

    state = {
        address: '',
        coordinates: null,
        nearbyAssemblies: [],
        selectedAssemblies: [],
        name: '',
        email: '',
        emailExists: false,
        isLoggedIn: false,
        step: 0,
        status: 'READY',
        errors: {},
    };

    componentDidMount() {
        this.stopHistoryListener = this.props.history.listen(this.onHistoryChange);
    }

    componentWillUnmount() {
        this.stopHistoryListener();
    }

    onHistoryChange = location => {
        const step = R.pipe(R.path(['state', 'step']), R.defaultTo(1))(location);
        this.setState({
            step,
        });
    };

    onAddressChange = ({ coordinates, address }) => {
        if (coordinates) {
            this.fetchNearbyAssemblies(coordinates);
            this.setState({ coordinates, address });
        } else {
            this.setState({ coordinates, address, nearbyAssemblies: [] });
        }
    };

    onSubmitAddress = () => {
        if (this.state.coordinates) {
            if (!R.isEmpty(this.state.nearbyAssemblies)) {
                this.next();
            }
        } else {
            this.setState(previousState => {
                return {
                    errors: {
                        ...previousState.errors,
                        address: [this.props.trans('miniruche.create.invalidAddress')],
                    },
                };
            });
        }
    };

    onSelectedAssembliesChange = selectedAssemblies => {
        this.setState({ selectedAssemblies });
    };

    onNearbyAssembliesChange = nearbyAssemblies => {
        if (nearbyAssemblies && nearbyAssemblies.length) {
            this.setState(previousState => {
                return {
                    nearbyAssemblies: nearbyAssemblies
                        .sort((a, b) => {
                            if (a.hasOpenSale === b.hasOpenSale) {
                                return a.distance - b.distance;
                            }
                            return a.hasOpenSale ? -1 : 1;
                        })
                        .slice(0, 20),
                    errors: {
                        ...previousState.errors,
                        address: [],
                    },
                };
            });
        } else {
            this.setState(previousState => {
                return {
                    nearbyAssemblies: [],
                    errors: {
                        ...previousState.errors,
                        address: [this.props.trans('miniruche.create.noAssemblyNearby')],
                    },
                };
            });
        }
    };

    onNameChange = name => {
        this.setState({ name });
    };

    onEmailChange = email => {
        this.setState({ email });
    };

    fetchNearbyAssemblies = coordinates => {
        get(`miniruche/assemblies/find/${coordinates.latitude},${coordinates.longitude}`).then(
            this.onNearbyAssembliesChange
        );
    };

    lookupEmail = email => {
        post('miniruche/users/lookup', {
            email,
        }).then(
            ({ exists }) => {
                this.setState(
                    {
                        step: 4,
                        emailExists: exists,
                    },
                    () => {
                        addUniversalEvent({
                            eventCategory: 'Onboarding',
                            eventAction: 'email_entered',
                            eventLabel: exists ? 'recognized' : 'not_recognized',
                        });
                        this.props.history.push(this.props.history.location.pathname, {
                            step: this.state.step,
                        });
                    }
                );
            },
            () => {
                this.setState(previousState => {
                    return {
                        errors: {
                            ...previousState.errors,
                            login: [this.props.trans('miniruche.create.invalidEmail')],
                        },
                    };
                });
            }
        );
    };

    next = () => {
        return this.setState(
            R.evolve({
                step: R.inc,
            }),
            () => {
                switch (this.state.step) {
                    case 1:
                        addUniversalEvent({
                            eventCategory: 'Onboarding',
                            eventAction: 'address_defined',
                            eventLabel: `${this.state.coordinates.latitude},${this.state.coordinates.longitude}`,
                        });
                        break;
                    case 2:
                        addUniversalEvent({
                            eventCategory: 'Onboarding',
                            eventAction: 'ruche_selection_validated',
                            eventLabel: this.state.selectedAssemblies.join(','),
                            eventValue: this.state.selectedAssemblies.length,
                        });
                        break;
                    case 3:
                        addUniversalEvent({
                            eventCategory: 'Onboarding',
                            eventAction: 'miniruche_name_validated',
                            eventLabel: this.state.name,
                        });
                        break;
                    default:
                }
                this.props.history.push(this.props.history.location.pathname, {
                    step: this.state.step,
                });
            }
        );
    };

    previous = () => {
        return this.setState(
            R.evolve({
                step: R.dec,
            }),
            () => {
                switch (this.state.step) {
                    case 1:
                        addUniversalEvent({
                            eventCategory: 'Onboarding',
                            eventAction: 'miniruche_name_back',
                        });
                        break;
                    case 2:
                        addUniversalEvent({
                            eventCategory: 'Onboarding',
                            eventAction: 'email_back',
                        });
                        break;
                    default:
                }
                this.props.history.push(this.props.history.location.pathname, {
                    step: this.state.step,
                });
            }
        );
    };

    getMaxStep = () => {
        if (!this.state.nearbyAssemblies.length) {
            return 0;
        }
        if (!this.state.selectedAssemblies.length) {
            return 1;
        }
        if (!this.state.name) {
            return 2;
        }
        if (!this.state.email) {
            return 3;
        }
        return 4;
    };

    save = () => {
        this.setState({
            status: 'SAVING',
        });
        post('miniruche', {
            name: this.state.name,
            assemblies: this.state.selectedAssemblies,
            meetingPoint: {
                coordinates: this.state.coordinates,
                fullAddress: this.state.address,
            },
        }).then(({ slug }) => {
            addUniversalEvent({
                eventCategory: 'Onboarding',
                eventAction: 'miniruche_created',
                eventLabel: slug,
            });
            this.props.history.push(`/new/${slug}/created`);
        });
    };

    renderStep() {
        const {
            address,
            nearbyAssemblies,
            selectedAssemblies,
            name,
            email,
            step,
            errors,
            emailExists,
        } = this.state;

        const maxStep = this.getMaxStep();

        const stepProps = {
            canGoToNextStep: maxStep > step,
            next: this.next,
            previous: this.previous,
            stepCount: this.props.standaloneAddress ? 4 : 3,
            currentStep: this.props.standaloneAddress ? step + 1 : step,
        };

        switch (step) {
            case 0:
                return (
                    <AddressForm
                        address={address}
                        onChange={this.onAddressChange}
                        errors={errors.address}
                        {...stepProps}
                    />
                );
            case 1:
                return (
                    <AssembliesForm
                        nearbyAssemblies={nearbyAssemblies}
                        selectedAssemblies={selectedAssemblies}
                        onChange={this.onSelectedAssembliesChange}
                        {...stepProps}
                    />
                );
            case 2:
                return <NameForm name={name} onChange={this.onNameChange} {...stepProps} />;
            case 3:
                return (
                    <EmailForm
                        email={email}
                        onChange={this.onEmailChange}
                        lookupEmail={this.lookupEmail}
                        errors={errors.login}
                        {...stepProps}
                    />
                );
            default:
                return (
                    <LoginOrRegisterForm
                        email={email}
                        emailExists={emailExists}
                        onChange={this.onEmailChange}
                        save={this.save}
                        {...stepProps}
                        currentStep={this.props.standaloneAddress ? 4 : 3}
                    />
                );
        }
    }

    render() {
        const { address, errors } = this.state;

        if (!this.props.standaloneAddress && this.state.step === 0) {
            return (
                <Landing
                    address={address}
                    onAddressChange={this.onAddressChange}
                    onSubmitAddress={this.onSubmitAddress}
                    previous={this.previous}
                    canGoToNextStep={this.submitAddress}
                    errors={errors.address}
                />
            );
        }

        return <Page blue>{this.renderStep()}</Page>;
    }
}

export default connectToI18n(({ history, standaloneAddress, trans }) => (
    <Create history={history} standaloneAddress={standaloneAddress} trans={trans} />
));
