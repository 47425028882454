import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import * as R from 'ramda';
import Conveyor from 'miniruche/components/participant/Conveyor.jsx';

import { Modal, Heading, Text } from 'miniruche/ui';

export class ConveyorsModal extends React.Component {
    static propTypes = {
        sale: PropTypes.object.isRequired,
        close: PropTypes.func.isRequired,
        addConveyor: PropTypes.func.isRequired,
        removeConveyor: PropTypes.func.isRequired,
        potentialConveyors: PropTypes.array.isRequired,
        trans: PropTypes.func.isRequired,
    };

    onChange = ({ name, value }) => {
        const conveyor = R.find(R.propEq('uuid', name), this.props.potentialConveyors);
        if (value.isConveyor === true) {
            this.props.addConveyor(conveyor);
        }
        if (value.isConveyor === false) {
            this.props.removeConveyor(conveyor);
        }
    };

    render() {
        const { close, sale, potentialConveyors, trans } = this.props;

        const conveyors = R.indexBy(R.prop('uuid'), sale.conveyors);
        const feesAmount = sale.orderStats.feesTotal.amount;
        const currency = sale.orderStats.feesTotal.currency;
        const share = {
            amount: sale.conveyors.length <= 1 ? feesAmount : feesAmount / sale.conveyors.length,
            currency,
        };

        return (
            <Modal
                title={
                    <Heading rank={2} size="medium">
                        {trans('miniruche.sale.conveyorModal.heading')}
                    </Heading>
                }
                close={close}
            >
                <Text className="u-spacing-stack-m">
                    {trans('miniruche.sale.conveyorModal.text')}
                </Text>
                <div>
                    {potentialConveyors.map(potentialConveyor => {
                        return (
                            <Conveyor
                                key={potentialConveyor.uuid}
                                name={potentialConveyor.uuid}
                                className="u-spacing-stack-s"
                                participant={potentialConveyor}
                                isConveyor={!!conveyors[potentialConveyor.uuid]}
                                onChange={this.onChange}
                                share={share}
                            />
                        );
                    })}
                </div>
            </Modal>
        );
    }
}

export default connectToI18n(ConveyorsModal);
