import { addTranslations } from 'modules/i18n';

const locales = [
    'fr',
    'fr-CH',
    'fr-BE',
    'fr-FR',
    'ca',
    'ca-ES',
    'de',
    'de-CH',
    'de-DE',
    'da',
    'da-DK',
    'es',
    'es-ES',
    'it',
    'it-IT',
    'nl',
    'nl-BE',
    'nl-NL',
    'en',
    'en-GB',
];

const fakeTranslations = {};

// Server side add everything additional to the global translation object ...
if (___SERVER___) {
    locales.forEach(locale => {
        addTranslations(locale, fakeTranslations);
    });
}
// Which get copied in the index.html on the server
// so just add everything from the window when client side
else {
    addTranslations(window.location.pathname.split('/')[1], window.messages);
}
