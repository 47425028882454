import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';
import { Heading } from 'miniruche/ui';

const nameBasedOnId = (nameProp, idProp, idValue) =>
    R.pipe(R.find(R.propEq(idProp, idValue)), R.prop(nameProp));

class CategoryHeading extends React.Component {
    render() {
        const { categoryId, className, productTypes, rank, size } = this.props;

        const title =
            nameBasedOnId('categoryName', 'categoryId', categoryId)(productTypes) ||
            nameBasedOnId('subCategoryName', 'subCategoryId', categoryId)(productTypes) ||
            nameBasedOnId('name', 'id', categoryId)(productTypes);

        if (!title) {
            return null;
        }

        return (
            <Heading className={classnames('categoryHeading', className)} rank={rank} size={size}>
                {title}
            </Heading>
        );
    }
}

CategoryHeading.propTypes = {
    categoryId: PropTypes.number.isRequired,
    className: PropTypes.string,
    productTypes: PropTypes.array.isRequired,
    rank: PropTypes.number,
    size: PropTypes.string,
};

CategoryHeading.defaultProps = {
    rank: 2,
    size: 'large',
};

export default CategoryHeading;
