import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Mask extends React.Component {
    render() {
        const { children, className, content, ...rest } = this.props;

        return (
            <div className={classnames('mask', className)} {...rest}>
                {children}
                <div className="mask-inner">{content}</div>
            </div>
        );
    }
}

Mask.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    content: PropTypes.node,
};

export default Mask;
