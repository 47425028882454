import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { filerPhoto } from 'modules/utils/filer';
import { convertRawTextToHtml } from 'modules/utils/text';
import { capitalizeFirstLetter } from 'modules/utils/string';
import Offer from 'miniruche/modules/shopping/components/offer/Offer.jsx';
import { propType as statusPropType } from 'modules/utils/ajaxStatuses';
import ImageLoader from 'components/ImageLoader.jsx';
import { Image, Modal, Heading, Mask, Text, Stack, Split, Grow, Separator } from 'miniruche/ui';

export class ProductDetails extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        slug: PropTypes.string.isRequired,
        categoryId: PropTypes.number.isRequired,
        saleId: PropTypes.number.isRequired,
        closeModal: PropTypes.func,
        status: statusPropType,
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
    };

    getImage = () => {
        const { product, categoryId } = this.props;

        if (product.photoId) {
            return filerPhoto(product.photoId, 'large');
        }

        return `/assets/images/products/product-${categoryId}.png`;
    };

    render() {
        const { product, status, closeModal, transChoice, trans, slug, saleId } = this.props;

        return (
            <Modal
                close={closeModal}
                title={
                    <Heading rank={2} size="medium">
                        {capitalizeFirstLetter(product.name.toLowerCase())}
                    </Heading>
                }
            >
                <div>
                    <Mask
                        className="u-spacing-stack-m"
                        content={
                            <div className="u-spacing-inset-s">
                                {product.labels.map(label => {
                                    return (
                                        <Image
                                            key={label.id}
                                            inline
                                            rounded
                                            bordered
                                            height="20"
                                            width="20"
                                            src={filerPhoto(label.photoId, 'small')}
                                        />
                                    );
                                })}
                            </div>
                        }
                    >
                        <ImageLoader className="image image--wide" src={this.getImage()} alt="" />
                    </Mask>
                    {(status === 'READY' || status === 'SAVING') && (
                        <div>
                            <Stack gutter="s" className="u-spacing-stack-m">
                                {product.offers.map(offer => {
                                    return (
                                        <Offer
                                            key={offer.id}
                                            slug={slug}
                                            saleId={saleId}
                                            offer={offer}
                                            strategy={product.quantityStrategy}
                                            status={status}
                                            reverse={product.offers.length > 1}
                                            horizontal
                                        />
                                    );
                                })}
                            </Stack>
                            <Separator className="u-spacing-stack-m" />
                            <Text
                                tag="div"
                                className="u-spacing-stack-m"
                                dangerouslySetInnerHTML={{
                                    __html: convertRawTextToHtml(product.description),
                                }}
                            />
                            {product.storageLife &&
                                product.storageLife.amount &&
                                product.storageLife.unit && (
                                    <div className="u-spacing-stack-m">
                                        <Text strong>
                                            {trans('products.productPage.storageGarantee')}
                                        </Text>
                                        <Text>
                                            {product.storageLife.amount}{' '}
                                            {trans(`global.calendar.${product.storageLife.unit}`)}
                                        </Text>
                                    </div>
                                )}
                            {typeof product.freshness !== 'undefined' && (
                                <div className="u-spacing-stack-m">
                                    <Text strong>
                                        {trans('catalog.editProduct.freshness.title')}
                                    </Text>
                                    <Text>
                                        {transChoice(
                                            'products.salesBox.freshness.content',
                                            product.freshness
                                        )}
                                    </Text>
                                </div>
                            )}
                            <Separator className="u-spacing-stack-m" />
                            <Split gutter="small" className="u-spacing-stack-s">
                                <Grow weight={0}>
                                    <Image
                                        rounded
                                        bordered
                                        width="48"
                                        src={filerPhoto(product.farmPhotoId, 'small')}
                                    />
                                </Grow>
                                <Grow weight={1} align="center">
                                    <Text strong>{product.farmName}</Text>
                                    <Text>
                                        {product.farmCity}, {product.farmDepartment}
                                    </Text>
                                </Grow>
                            </Split>
                            <Text
                                tag="div"
                                className="u-spacing-stack-s"
                                dangerouslySetInnerHTML={{
                                    __html: convertRawTextToHtml(product.farmDescription),
                                }}
                            />
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

export default connectToI18n(ProductDetails);
