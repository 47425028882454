import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Switch extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        className: PropTypes.string,
        inverse: PropTypes.bool,
        size: PropTypes.string,
    };

    static contextTypes = {
        inverse: PropTypes.bool,
    };

    static defaultProps = {
        size: 'medium',
    };

    onChange = event => {
        if (this.props.onChange) {
            this.props.onChange(event.target.checked);
        }
    };

    render() {
        const { name, label, value, className, inverse, size } = this.props;

        const isInversed = inverse || this.context.inverse;
        const classes = ['switch', `switch--${size}`, { 'switch--inverse': isInversed }, className];

        return (
            <span className={classnames(classes)}>
                <input
                    type="checkbox"
                    id={name}
                    name={name}
                    onChange={this.onChange}
                    checked={value}
                />
                <label htmlFor={name}>{label && <span>{label}</span>}</label>
            </span>
        );
    }
}
