import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { connect } from 'react-redux';
import { get } from 'miniruche/services/api';
import { bySortedCategories } from 'models/productTypes';
import PageHeader from 'miniruche/modules/shopping/components/layout/PageHeader.jsx';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import { capitalizeFirstLetter } from 'modules/utils/string';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import { Link } from 'react-router-dom';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';
import RemainingDays from 'miniruche/components/sale/RemainingDays.jsx';
import { getStatus } from 'miniruche/modules/shopping/store/basketSelectors';
import { INITIAL } from 'miniruche/modules/shopping/store/basketStatuses';
import { BASKET_FETCH } from 'miniruche/modules/shopping/store/basketActionTypes';
import { Page, Section, Split, Grow, Text, Button } from 'miniruche/ui';

export class Sale extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        saleId: PropTypes.number.isRequired,
        i18n: PropTypes.string.isRequired,
        dispatch: PropTypes.func.isRequired,
        basketStatus: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        categories: [],
        miniRuche: {},
        distribution: {},
        status: INITIAL_LOADING,
    };

    componentDidMount() {
        this.props.dispatch({ type: BASKET_FETCH });
        const distributionRequest = get(`miniruche/${this.props.slug}/sales/${this.props.saleId}`);
        const productTypesRequest = get(`distributions/${this.props.saleId}/product_types`);
        Promise.all([distributionRequest, productTypesRequest]).then(this.onDataLoaded);
    }

    onDataLoaded = ([sale, { ProductTypes }]) => {
        this.setState({
            categories: bySortedCategories(ProductTypes),
            miniRuche: sale.community,
            distribution: sale.distribution,
            status: READY,
        });
    };

    onCategoryClicked = (categoryId, categoryName) => {
        addUniversalEvent({
            eventCategory: 'Sale',
            eventAction: 'category_clicked',
            eventLabel: categoryName,
        });
    };

    render() {
        const { slug, saleId, i18n, basketStatus, trans } = this.props;

        if (this.state.status === INITIAL_LOADING || basketStatus === INITIAL) {
            return <Page />;
        }

        return (
            <Page>
                <PageHeader
                    slug={slug}
                    saleId={saleId}
                    title={capitalizeFirstLetter(
                        getLocalizedDate(i18n, 'I', this.state.distribution.date)
                    )}
                    showBasket
                    backText={trans('miniruche.sale.backToSales')}
                    backHref={`/${slug}`}
                />
                <Section>
                    <Text type="success" className="u-center u-spacing-stack-l">
                        <RemainingDays saleEndDate={this.state.distribution.saleEndDate} />
                    </Text>
                    {this.state.categories.map(category => {
                        return (
                            <Link
                                key={category.id}
                                to={`/${slug}/sales/${saleId}/categories/${category.id}`}
                                onClick={() => this.onCategoryClicked(category.id, category.name)}
                                className="u-blockLink u-spacing-stack-s"
                            >
                                <Split align="center" gutter="small">
                                    <Grow weight={0}>
                                        <img
                                            width="48"
                                            height="48"
                                            src={`/assets/images/product_categories/category_lygiestyle_${category.id}.svg`}
                                        />
                                    </Grow>
                                    <Grow weight={1}>
                                        <Text>{category.name}</Text>
                                    </Grow>
                                    <Grow weight={0}>
                                        <Button small variant="clear" icon="angle-right" />
                                    </Grow>
                                </Split>
                            </Link>
                        );
                    })}
                </Section>
            </Page>
        );
    }
}

function mapStateToProps(state) {
    return {
        basketStatus: getStatus(state),
    };
}

const Connected = connect(mapStateToProps)(Sale);

export default connectToI18n(({ match, i18n, trans }) => (
    <Connected
        i18n={i18n}
        slug={match.params.slug}
        saleId={parseInt(match.params.saleId, 10)}
        trans={trans}
    />
));
