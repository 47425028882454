import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import MultipleInputOption from './MultipleInputOption.jsx';

export default class MultipleInput extends React.Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        value: PropTypes.array,
        name: PropTypes.string,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        onChange: () => {},
    };

    constructor(props) {
        super(props);
        if (this.props.value === undefined) {
            this.state = {
                isControlled: false,
                value: [],
            };
        } else {
            this.state = {
                isControlled: true,
            };
        }
    }

    onOptionChange = ({ name, value }) => {
        const handler = value.isSelected ? R.append(name) : R.reject(R.equals(name));
        let nextValue;
        this.setState(
            state => {
                if (state.isControlled) {
                    nextValue = handler(this.props.value);
                    return state;
                }
                nextValue = handler(state.value);
                return {
                    ...state,
                    value: nextValue,
                };
            },
            () => {
                this.props.onChange({
                    name: this.props.name,
                    value: nextValue,
                });
            }
        );
    };

    render() {
        const { options, value } = this.props;

        const stateOrPropsValue = this.state.isControlled ? value : this.state.value;

        return (
            <div>
                {options.map(option => {
                    return (
                        <MultipleInputOption
                            type="button"
                            key={option.value}
                            name={option.value}
                            label={option.label}
                            isSelected={R.contains(option.value, stateOrPropsValue)}
                            onChange={this.onOptionChange}
                        >
                            {option.label}
                        </MultipleInputOption>
                    );
                })}
            </div>
        );
    }
}
