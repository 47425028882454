import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { filerPhoto } from 'modules/utils/filer';
import { capitalizeFirstLetter } from 'modules/utils/string';
import Offer from 'miniruche/modules/shopping/components/offer/Offer.jsx';
import LikeAuthors from 'miniruche/modules/shopping/components/product/LikeAuthors.jsx';
import { Split, Grow, Image, Button, Text, Mask, Box } from 'miniruche/ui';

export class ProductSummary extends PureComponent {
    static propTypes = {
        product: PropTypes.object.isRequired,
        categoryId: PropTypes.number.isRequired,
        like: PropTypes.func.isRequired,
        unLike: PropTypes.func.isRequired,
        userFirstName: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        saleId: PropTypes.number.isRequired,
        dismissBanner: PropTypes.func.isRequired,
        onClickDetails: PropTypes.func.isRequired,
        productDetailsUrl: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    toggleLike = () => {
        this.dismissBanner();
        if (this.props.product.hasLiked) {
            return this.props.unLike({ productId: this.props.product.id });
        }
        return this.props.like({ productId: this.props.product.id });
    };

    dismissBanner = () => {
        if (!this.props.product.isBannerDismissed) {
            this.props.dismissBanner({ productId: this.props.product.id });
        }
    };

    getImage = () => {
        const { product, categoryId } = this.props;

        if (product.photoId) {
            return filerPhoto(product.photoId, 'small');
        }

        return `/assets/images/products/product-${categoryId}.png`;
    };

    onClickDetails = () => {
        this.props.onClickDetails(this.props.product.id);
    };

    render() {
        const {
            product,
            userFirstName,
            slug,
            saleId,
            categoryId,
            productDetailsUrl,
            trans,
        } = this.props;

        const likes = product.hasLiked ? product.likes - 1 : product.likes;

        return (
            <div className="productSummary">
                <Split gutter="medium" className={likes > 0 ? 'u-spacing-stack-s' : null}>
                    <Grow weight={0}>
                        <Link to={productDetailsUrl} onClick={this.onClickDetails}>
                            <Mask
                                content={
                                    <div className="u-spacing-inset-xs">
                                        {product.labels.map(label => {
                                            return (
                                                <Image
                                                    className="u-spacing-inline-xxs u-spacing-stack-xxs"
                                                    key={label.id}
                                                    inline
                                                    rounded
                                                    bordered
                                                    height="20"
                                                    width="20"
                                                    src={filerPhoto(label.photoId, 'small')}
                                                />
                                            );
                                        })}
                                    </div>
                                }
                            >
                                <Image className="productSummary-image" src={this.getImage()} />
                            </Mask>
                        </Link>
                    </Grow>
                    <Grow align="center" weight={1}>
                        <Split gutter="small" className="productSummary-header">
                            <Grow weight={1}>
                                <Text strong className="u-spacing-stack-xxs">
                                    <Link
                                        className="u-blockLink"
                                        to={productDetailsUrl}
                                        onClick={this.onClickDetails}
                                    >
                                        {capitalizeFirstLetter(product.name.toLowerCase())}
                                    </Link>
                                </Text>
                                <Text>{capitalizeFirstLetter(product.farmName.toLowerCase())}</Text>
                            </Grow>
                            {product.previouslyBought && (
                                <Grow weight={0}>
                                    <Button
                                        small
                                        variant="clear"
                                        onClick={this.toggleLike}
                                        icon={product.hasLiked ? 'heart-on' : 'heart'}
                                    />
                                </Grow>
                            )}
                        </Split>
                        {product.offers.map(offer => {
                            return (
                                <Offer
                                    key={offer.id}
                                    className="productSummary-offer"
                                    status={offer.status}
                                    offer={offer}
                                    strategy={this.props.product.quantityStrategy}
                                    reverse={product.offers.length > 1}
                                    slug={slug}
                                    saleId={saleId}
                                />
                            );
                        })}
                    </Grow>
                </Split>
                <LikeAuthors
                    authors={product.likeAuthors}
                    filteredUser={product.hasLiked ? userFirstName : null}
                />
                {product.previouslyBought && !product.hasLiked && !product.isBannerDismissed && (
                    <Box type="success" style={{ marginTop: '18px' }}>
                        <Text className="u-spacing-stack-xs">{trans('miniruche.like.text1')}</Text>
                        <Text className="u-spacing-stack-m">{trans('miniruche.like.text2')}</Text>
                        <Button
                            className="u-spacing-inline-s u-spacing-stack-s"
                            onClick={this.toggleLike}
                        >
                            {trans('miniruche.like.yes')}
                        </Button>
                        <Button className="u-spacing-stack-s" onClick={this.dismissBanner}>
                            {trans('miniruche.like.no')}
                        </Button>
                    </Box>
                )}
            </div>
        );
    }
}

export default connectToI18n(ProductSummary);
