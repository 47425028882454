import React from 'react';
import PropTypes from 'prop-types';
import { post } from 'miniruche/services/api';
import { Modal, Heading, Text, Button } from 'miniruche/ui';

class ForgotPasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.sendEmail = this.sendEmail.bind(this);
        this.state = {
            status: 'READY',
            emailSent: false,
        };
    }

    sendEmail() {
        this.setState({
            status: 'SAVING',
        });
        post('miniruche/password_reset/', {
            email: this.props.email,
            redirectPath: this.props.redirectPath,
        }).then(() => {
            this.setState({
                status: 'READY',
                emailSent: true,
            });
        });
    }

    render() {
        const { email, close, trans } = this.props;

        const { status, emailSent } = this.state;

        return (
            <Modal
                title={
                    <Heading rank={2} size="medium">
                        {trans('miniruche.session.forgot.changePassword')}
                    </Heading>
                }
                close={close}
            >
                {emailSent ? (
                    <div>
                        <Text type="success" className="u-spacing-stack-s">
                            {trans('miniruche.session.forgot.emailSent')}
                        </Text>
                        <Text>{trans('miniruche.session.forgot.checkMails')}</Text>
                    </div>
                ) : (
                    <div>
                        <Text className="u-spacing-stack-m">
                            {trans('miniruche.session.forgot.question', {
                                email,
                            })}
                        </Text>
                        <Button
                            variant="primary"
                            disabled={status === 'SAVING'}
                            onClick={status === 'SAVING' ? null : this.sendEmail}
                        >
                            {trans('miniruche.session.forgot.getMail')}
                        </Button>
                    </div>
                )}
            </Modal>
        );
    }
}

ForgotPasswordModal.propTypes = {
    email: PropTypes.string.isRequired,
    redirectPath: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    trans: PropTypes.func.isRequired,
};

ForgotPasswordModal.defaultProps = {
    options: [],
};

export default ForgotPasswordModal;
