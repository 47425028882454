import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { get } from 'miniruche/services/api';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import AdminSale from 'miniruche/components/sale/AdminSale.jsx';
import { Page, Section, Stack, Heading, Text, Button, URLShare } from 'miniruche/ui';
import PageHeader from 'miniruche/ui/layout/PageHeader.jsx';

export class Sales extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        miniRuche: {},
        status: INITIAL_LOADING,
        error: null,
    };

    componentDidMount() {
        const miniRucheRequest = get(`miniruche/admin/${this.props.slug}`);
        const salesRequest = get(`miniruche/admin/${this.props.slug}/sales`);
        Promise.all([miniRucheRequest, salesRequest]).then(this.onDataLoaded, this.onError);
    }

    onDataLoaded = ([miniRuche, sales]) => {
        this.setState({
            miniRuche,
            sales,
            status: READY,
        });
    };

    onError = error => {
        switch (error.status) {
            case 404:
                return this.setState({
                    status: READY,
                    error: 'not_found',
                });
            case 403:
                return this.setState({
                    status: READY,
                    error: 'unauthorized',
                });
            default:
                return this.setState({
                    status: READY,
                    error: 'default',
                });
        }
    };

    render() {
        const { status, miniRuche, sales, error } = this.state;
        const { trans } = this.props;

        const { slug } = this.props;

        if (status === INITIAL_LOADING) {
            return <Page blue />;
        }

        if (error === 'not_found') {
            return (
                <Page blue>
                    <Section>
                        <Heading rank={1} size="small" className="u-spacing-stack-m">
                            {trans('miniruche.admin.sales.notFound')}
                        </Heading>
                        <Text>{trans('miniruche.admin.sales.notFoundText')}</Text>
                    </Section>
                </Page>
            );
        }

        if (error === 'unauthorized') {
            const url = encodeURIComponent(`${this.props.slug}/admin/sales`);
            return (
                <Page blue>
                    <Section>
                        <Heading rank={1} size="small" className="u-spacing-stack-m">
                            {trans('miniruche.admin.sales.unauthorizedHeading')}
                        </Heading>
                        <Text className="u-spacing-stack-m">
                            {trans('miniruche.admin.sales.unauthorizedText')}
                        </Text>
                        <Button variant="primary" to={`/login?redirectPath=${url}`}>
                            {trans('miniruche.admin.sales.unauthorizedButton')}
                        </Button>
                    </Section>
                </Page>
            );
        }

        if (error === 'default') {
            return (
                <Page blue>
                    <Section>
                        <Heading rank={1} size="small" className="u-spacing-stack-m">
                            {trans('miniruche.admin.sales.defaultErrorHeading')}
                        </Heading>
                        <Text className="u-spacing-stack-m">
                            {trans('miniruche.admin.sales.defaultErrorMessage')}
                        </Text>
                    </Section>
                </Page>
            );
        }

        const sortedSales = sales.sort((s1, s2) => (s1.date < s2.date ? -1 : 1));

        return (
            <Page blue>
                <PageHeader
                    title={trans('global.dashboard')}
                    backHref={`/${slug}`}
                    backText={trans('miniruche.admin.sales.backToHome')}
                />
                <Section>
                    <Heading rank={2} size="medium" className="u-spacing-stack-m">
                        {trans('distributions.now')}
                    </Heading>
                    <Stack className="u-spacing-stack-l">
                        {sortedSales.map(sale => {
                            return <AdminSale key={sale.id} sale={sale} miniRuche={miniRuche} />;
                        })}
                    </Stack>
                    <Button
                        block
                        variant="outline"
                        className="u-spacing-stack-s"
                        to={`/${slug}/admin/assemblies`}
                    >
                        {trans('menu.manageHive')}
                    </Button>
                    <Button block variant="outline" to={`/${slug}/admin/edit`}>
                        {trans('miniruche.admin.sales.parameters')}
                    </Button>
                </Section>
                <Section alternate>
                    <Heading rank={2} size="medium" className="u-spacing-stack-m">
                        {trans('miniruche.admin.sales.invite')}
                    </Heading>
                    <Text className="u-spacing-stack-s">
                        {trans('miniruche.admin.sales.inviteText')}
                    </Text>
                    <URLShare
                        url={`https://miniruche.com/${this.props.slug}`}
                        messageTitle={trans('miniruche.admin.sales.inviteButtonTitle')}
                        messageContent={trans('miniruche.admin.sales.inviteButtonContent')}
                    />
                </Section>
            </Page>
        );
    }
}

export default connectToI18n(({ match, i18n, trans }) => (
    <Sales i18n={i18n} slug={match.params.slug} trans={trans} />
));
