import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as R from 'ramda';

class Progress extends React.Component {
    render() {
        const { className, max, value } = this.props;

        const percentage = R.pipe(
            R.flip(R.divide)(max),
            R.max(0),
            R.min(1),
            R.multiply(100)
        )(value);

        return (
            <div className={classnames('progress', className)}>
                {percentage > 0 && (
                    <div className="progress-inner" style={{ width: `${percentage}%` }} />
                )}
            </div>
        );
    }
}

Progress.propTypes = {
    className: PropTypes.string,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

Progress.defaultProps = {
    options: [],
};

export default Progress;
