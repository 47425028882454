import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { post } from 'miniruche/services/api';
import { clear, grant } from 'modules/oauth';
import RegisterForm from 'miniruche/components/session/RegisterForm.jsx';
import { Page, Section, Heading, Button } from 'miniruche/ui';

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.register = this.register.bind(this);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            errors: {},
            city: {
                zipCode: '',
                id: null,
                name: null,
            },
            street: '',
            birthday: {
                day: '',
                month: '',
                year: '',
            },
            nationality: 'FR',
        };
    }

    componentDidMount() {
        clear();
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    register(event) {
        event.preventDefault();
        post('users/', {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            plainPassword: this.state.password,
            hasAcceptedTOS: true,
            address: {
                city: this.state.city.id,
                street: this.state.street,
            },
            accountSourceApp: 'miniruche',
            birthday: `${this.state.birthday.year}-${this.state.birthday.month}-${this.state.birthday.day}`,
            nationality: this.state.nationality,
        }).then(
            () => {
                const redirectPath = new URLSearchParams(this.props.location.search).get(
                    'redirectPath'
                );
                grant('password', {
                    username: this.state.email,
                    password: this.state.password,
                }).then(() => {
                    if (redirectPath) {
                        this.props.history.push(redirectPath);
                    } else {
                        this.props.history.goBack();
                    }
                });
            },
            err => {
                if (err.status === 400) {
                    const errors = R.pipe(
                        R.path(['responseJSON', 'detail', 'errors']),
                        R.defaultTo({})
                    )(err);
                    this.setState({ errors });
                }
            }
        );
    }

    render() {
        const { trans } = this.props;
        const {
            errors,
            firstName,
            lastName,
            city,
            email,
            password,
            street,
            birthday,
            nationality,
        } = this.state;

        return (
            <Page blue>
                <Section small>
                    <div className="u-center u-spacing-stack-xl">
                        <img width="200" src="/assets/images/logos/logo-miniruche.svg" />
                    </div>
                    <Heading rank={1} size="large" className="u-spacing-stack-l">
                        {trans('global.signup')}
                    </Heading>
                    <form>
                        <RegisterForm
                            className="u-spacing-stack-xl"
                            onChange={this.onChange}
                            firstName={firstName}
                            lastName={lastName}
                            city={city}
                            email={email}
                            password={password}
                            errors={errors}
                            street={street}
                            birthday={birthday}
                            nationality={nationality}
                        />
                        <Button
                            block
                            variant="primary"
                            onClick={this.register}
                            className="u-spacing-stack-m"
                        >
                            {trans('global.signup')}
                        </Button>
                    </form>
                </Section>
            </Page>
        );
    }
}

Register.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    trans: PropTypes.func.isRequired,
};

export default connectToI18n(({ location, history, i18n, trans }) => (
    <Register i18n={i18n} location={location} history={history} trans={trans} />
));
