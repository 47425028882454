import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import effectsMiddleware from './effectsMiddleware';
import basketEffects from './basketEffects';
import basketReducer from './basketReducer';

const composeEnhancers = (___BROWSER___ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const effects = effectsMiddleware(basketEffects);

const reducer = combineReducers({
    basket: basketReducer,
    slug: (state = '') => state,
    saleId: (state = '') => state,
});

const enhancers = composeEnhancers(applyMiddleware(effects), applyMiddleware(thunk));

export default function configureStore({ slug, saleId }) {
    return createStore(reducer, { slug, saleId }, enhancers);
}
