import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Text, Icon } from 'miniruche/ui';

class PickupConstraints extends React.PureComponent {
    render() {
        const { sale, className, transChoice } = this.props;

        if (sale.orderCount <= 0) {
            return null;
        }

        return (
            <Text className={className}>
                <span className="u-spacing-inline-s">
                    {sale.orderCount}
                    &nbsp;
                    {transChoice('global.ordersPluralized', sale.orderCount)}
                </span>{' '}
                <span className="u-spacing-inline-s">
                    {Math.max(Math.round(sale.orderPickupConstraints.totalWeightInMg / 1000000), 1)}{' '}
                    kg
                </span>{' '}
                <Icon type="fresh" size="m" /> <Icon type="fragile" size="m" />
            </Text>
        );
    }
}

PickupConstraints.propTypes = {
    sale: PropTypes.object.isRequired,
    className: PropTypes.string,
    transChoice: PropTypes.func.isRequired,
};

export default connectToI18n(PickupConstraints);
