import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Box, Split, Grow, Heading, Text, Button } from 'miniruche/ui';
import { getLocalizedTime, getLocalizedDay } from 'modules/utils/dateAndTime';
import { capitalizeFirstLetter } from 'modules/utils/string';
import { formatDistance } from 'modules/utils/distances';

class AssemblyBox extends React.Component {
    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.addAssembly = this.addAssembly.bind(this);
        this.removeAssembly = this.removeAssembly.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.added !== this.props.added;
    }

    showModal() {
        this.props.showModal(this.props.assembly.uuid);
    }

    addAssembly() {
        this.props.addAssembly(this.props.assembly.uuid);
    }

    removeAssembly() {
        this.props.removeAssembly(this.props.assembly.uuid);
    }

    render() {
        const { assembly, trans, i18n, added, className } = this.props;

        return (
            <Box key={assembly.uuid} className={className}>
                <Heading rank={3} size="small" className="u-spacing-stack-s">
                    {assembly.name}
                </Heading>
                <div className="u-spacing-stack-s">
                    <Text strong>
                        {trans('miniruche.assembly.distributionTimeWithDay', {
                            '%day%': capitalizeFirstLetter(
                                getLocalizedDay(i18n, assembly.timeslots[0].day)
                            ),
                            '%start%': getLocalizedTime(i18n, assembly.timeslots[0].starting),
                            '%end%': getLocalizedTime(i18n, assembly.timeslots[0].ending),
                        })}
                    </Text>
                    {assembly.hasOpenSale && (
                        <Text type="success">{trans('miniruche.assembly.saleOpened')}</Text>
                    )}
                    {!assembly.hasOpenSale && (
                        <Text type="warning">{trans('miniruche.assembly.upcoming')}</Text>
                    )}
                </div>
                {assembly.distance && (
                    <Text>
                        {trans('miniruche.assembly.distance', {
                            '%distance%': formatDistance(assembly.distance, 'm', i18n),
                        })}
                    </Text>
                )}
                <Text>{assembly.address.street}</Text>
                <Text className="u-spacing-stack-m">{assembly.address.city}</Text>
                {!added && (
                    <Button
                        className="u-spacing-stack-s"
                        block
                        onClick={added ? null : this.addAssembly}
                        disabled={added}
                    >
                        {trans('miniruche.assembly.add')}
                    </Button>
                )}
                {added && (
                    <Button
                        className="u-spacing-stack-s"
                        variant="success"
                        block
                        onClick={this.removeAssembly}
                        icon="ok"
                    >
                        {trans('miniruche.assembly.added')}
                    </Button>
                )}
                <Button block variant="outline" onClick={this.showModal}>
                    {trans('global.seeOnMap')}
                </Button>
            </Box>
        );
    }
}

AssemblyBox.propTypes = {
    i18n: PropTypes.string.isRequired,
    trans: PropTypes.func.isRequired,
    assembly: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired,
    addAssembly: PropTypes.func.isRequired,
    removeAssembly: PropTypes.func.isRequired,
    className: PropTypes.string,
    added: PropTypes.bool,
};

export default connectToI18n(AssemblyBox);
