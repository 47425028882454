import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { filerPhoto } from 'modules/utils/filer';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';
import { Image, Text, Split, Grow, FullWidthBlock, Carousel } from 'miniruche/ui';
import createResponsiveComponent from 'components/createResponsiveComponent';

export class FeaturedProducts extends React.Component {
    static propTypes = {
        products: PropTypes.array.isRequired,
        saleUrl: PropTypes.string.isRequired,
        isSmallWidth: PropTypes.bool,
        isReady: PropTypes.bool,
        trans: PropTypes.func.isRequired,
    };

    onClickProduct = (productId, indexInList) => {
        addUniversalEvent({
            eventCategory: 'Sale',
            eventAction: 'featured_product_clicked',
            eventLabel: productId,
            eventValue: indexInList,
        });
    };

    onClickAllProducts = () => {
        addUniversalEvent({
            eventCategory: 'Sale',
            eventAction: 'featured_product_clicked',
            eventLabel: 'all',
        });
    };

    renderItems() {
        const placeholderItemCount = 10;
        const products = this.props.isReady
            ? this.props.products
            : R.times(index => ({ id: index }), placeholderItemCount);

        const items = products.map((product, index) => (
            <Grow key={product.id}>
                <Link
                    to={
                        this.props.isReady
                            ? {
                                  pathname: `${this.props.saleUrl}/categories/${product.rootCategoryId}/products/${product.id}`,
                                  state: { fromDashboard: true },
                              }
                            : this.props.saleUrl
                    }
                    className="featuredProducts-item"
                    title={product.name}
                    onClick={() => {
                        this.onClickProduct(product.id, index);
                    }}
                >
                    <Image
                        height={120}
                        width={120}
                        src={filerPhoto(product.photoId, 'small')}
                        className="u-spacing-stack-xs featuredProducts-image"
                    />
                    <Text className="featuredProducts-productName">{product.name}</Text>
                </Link>
            </Grow>
        ));

        return items.concat([
            <Grow key="all_products">
                <Link
                    to={this.props.saleUrl}
                    className="featuredProducts-item featuredProducts-allProductsLink"
                    onClick={this.onClickAllProducts}
                >
                    {this.props.trans('products.all')}
                </Link>
            </Grow>,
        ]);
    }

    render() {
        if (this.props.isSmallWidth) {
            return (
                <FullWidthBlock className="featuredProducts">
                    <Split gutter="small" className="u-spacing-inset-s">
                        {this.renderItems()}
                    </Split>
                </FullWidthBlock>
            );
        }

        return (
            <Carousel slidesToShow={5} cellAlign="left" className="featuredProducts">
                {this.renderItems()}
            </Carousel>
        );
    }
}

export default connectToI18n(createResponsiveComponent(FeaturedProducts, { maxWidth: 636 }));
