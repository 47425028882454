import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import classnames from 'classnames';

export class TabSet extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        setActiveTab: PropTypes.func.isRequired,
        isActive: PropTypes.bool,
        inverse: PropTypes.bool,
    };

    static contextTypes = {
        inverse: PropTypes.bool,
    };

    onClick = () => {
        this.props.setActiveTab(this.props.value);
    };

    render() {
        const { label, value, inverse, isActive } = this.props;

        const isInversed = inverse || this.context.inverse;

        return (
            <button
                key={value}
                className={classnames(
                    'tab',
                    { 'tab--inverse': isInversed },
                    { 'tab--active': isActive }
                )}
                onClick={this.onClick}
            >
                {label}
            </button>
        );
    }
}

export default connectToI18n(TabSet);
