import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import PickupItem from 'miniruche/components/pickup/PickupItem.jsx';
import { join } from 'modules/utils/string';
import { Text, GroupItem } from 'miniruche/ui';

export class PickupGroup extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        items: PropTypes.array.isRequired,
        orderNumbers: PropTypes.array.isRequired,
        pickups: PropTypes.object.isRequired,
        pickupAll: PropTypes.func.isRequired,
        releaseAll: PropTypes.func.isRequired,
        className: PropTypes.string,
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
    };

    render() {
        const {
            label,
            items,
            pickups,
            pickupAll,
            releaseAll,
            className,
            orderNumbers,
            trans,
            transChoice,
        } = this.props;

        return (
            <div className={className}>
                <GroupItem>
                    <Text strong className="u-spacing-stack-xxs">
                        {label}
                    </Text>
                    <Text>
                        {transChoice('global.ordersPluralized', orderNumbers.length)} n°{' '}
                        {join(orderNumbers, {
                            separator: ', ',
                            lastSeparator: ` ${trans('global.and')} `,
                        })}
                    </Text>
                </GroupItem>
                {items.map(item => {
                    const wasPickedUp = R.pipe(
                        R.prop('orderItems'),
                        R.map(id => pickups[id] || false),
                        R.all(R.identity)
                    )(item);
                    return (
                        <PickupItem
                            key={item.offer.uuid}
                            wasPickedUp={wasPickedUp}
                            item={item}
                            pickupAll={pickupAll}
                            releaseAll={releaseAll}
                        />
                    );
                })}
            </div>
        );
    }
}

export default connectToI18n(PickupGroup);
