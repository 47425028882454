import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Heading extends React.Component {
    render() {
        const { children, rank, size, className } = this.props;

        const Tag = `h${rank}`;

        return (
            <Tag className={classnames('heading', `heading--${size}`, className)}>{children}</Tag>
        );
    }
}

Heading.propTypes = {
    children: PropTypes.node,
    rank: PropTypes.number.isRequired,
    size: PropTypes.string,
    className: PropTypes.string,
};

Heading.defaultProps = {
    size: 'medium',
};

export default Heading;
