import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Icon from 'miniruche/ui/content/Icon.jsx';

class Button extends React.Component {
    render() {
        const {
            children,
            variant,
            inverse,
            block,
            small,
            to,
            href,
            icon,
            iconSize,
            iconPosition,
            className,
            disabled,
            onClick,
            ...rest
        } = this.props;

        const isInversed = inverse || this.context.inverse;

        const classes = [className, 'button'];

        if (variant) {
            classes.push(`button--${variant}`);
        }

        if (isInversed) {
            classes.push('button--inverse');
        }

        if (disabled) {
            classes.push('button--disabled');
        }

        if (block) {
            classes.push('button--block');
        }

        if (small) {
            classes.push('button--small');
        }

        let computedIconSize = !iconSize && small ? 'xs' : 's';

        if (icon && children !== 0 && !children) {
            classes.push('button--iconOnly');

            if (variant === 'clear') {
                computedIconSize = 'm';
            }
        }

        const innerMarkup = (
            <Fragment>
                {icon && iconPosition === 'left' && (
                    <Icon type={icon} size={computedIconSize} className="button-icon--left" />
                )}
                {children}
                {icon && iconPosition === 'right' && (
                    <Icon type={icon} size={computedIconSize} className="button-icon--right" />
                )}
            </Fragment>
        );

        if (href) {
            return (
                <a
                    href={disabled ? null : href}
                    className={classnames(classes)}
                    onClick={disabled ? null : onClick}
                    {...rest}
                >
                    {innerMarkup}
                </a>
            );
        }

        if (to) {
            return (
                <Link
                    to={to}
                    className={classnames(classes)}
                    onClick={disabled ? null : onClick}
                    {...rest}
                >
                    {innerMarkup}
                </Link>
            );
        }

        return (
            <button className={classnames(classes)} onClick={disabled ? null : onClick} {...rest}>
                {innerMarkup}
            </button>
        );
    }
}

Button.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.string,
    inverse: PropTypes.bool,
    block: PropTypes.bool,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    to: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    iconSize: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    iconPosition: 'left',
};

Button.contextTypes = {
    inverse: PropTypes.bool,
};

export default Button;
