import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class FormField extends React.Component {
    render() {
        const { className, children, invalid } = this.props;
        return (
            <div
                className={classnames('formField', className, {
                    'fa-input--error': invalid,
                })}
            >
                {children}
            </div>
        );
    }
}

FormField.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    invalid: PropTypes.bool,
};

export default FormField;
