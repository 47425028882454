import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button.jsx';
import { smsLink } from 'modules/utils/links';

class ShareButton extends React.Component {
    onShare = () => {
        this.props.onShare(this.props.type);
    };

    render() {
        // eslint-disable-next-line no-unused-vars
        const { url, message, type, onShare: _ignore, ...rest } = this.props;

        if (type === 'facebook') {
            return (
                <Button
                    onClick={this.onShare}
                    href={`fb-messenger://share?link=${url}&app_id=284947218655596`}
                    icon="share-messenger"
                    {...rest}
                />
            );
        }

        if (type === 'whatsapp') {
            return (
                <Button
                    onClick={this.onShare}
                    href={`whatsapp://send?text=${message} ${url}`}
                    data-action="share/whatsapp/share"
                    icon="share-whatsapp"
                    {...rest}
                />
            );
        }

        if (type === 'sms') {
            return (
                <Button
                    onClick={this.onShare}
                    icon="share-sms"
                    href={smsLink([], `${message} ${url}`)}
                    {...rest}
                />
            );
        }

        if (type === 'email') {
            return (
                <Button
                    onClick={this.onShare}
                    icon="share-email"
                    href={`mailto:?subject=${message}&body=${url}`}
                    {...rest}
                />
            );
        }

        return null;
    }
}

ShareButton.propTypes = {
    url: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onShare: PropTypes.func,
};

ShareButton.defaultProps = {
    onShare: () => {},
};

export default ShareButton;
