import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'miniruche';
import 'app/scripts/polyfills';
import { setBundle, enableReporting } from 'modules/error-reporting';
import { addConfig } from 'modules/config';
import mapConfig from 'modules/maps/config';
addConfig(mapConfig);

// Sentry
setBundle('miniruche');
enableReporting();

const path = window.location.pathname.split('/');
const locale = path[1];
const hasMinirucheInPath = path[2] === 'miniruche';

const myRender = () =>
    render(
        <BrowserRouter
            basename={hasMinirucheInPath ? `/${locale}/miniruche` : `/${locale}`}
            location={`/${path.splice(hasMinirucheInPath ? 3 : 2).join('/')}`}
            i18n={locale}
        >
            <App i18n={locale} />
        </BrowserRouter>,
        document.getElementById('react-app')
    );

myRender();

if (module.hot) {
    const deepForceUpdate = require('react-deep-force-update')(React);
    const AppChannel = require('modules/channels/App').default;
    AppChannel.vent.on('HOT_RELOADING', () => {
        const instance = myRender();
        deepForceUpdate(instance);
    });
}
