import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'miniruche/ui/actions/Button.jsx';
import Grow from './Grow.jsx';
import Split from './Split.jsx';
import * as Backdrop from 'modules/utils/backdrop';

class Modal extends React.Component {
    getChildContext() {
        return {
            inverse: false,
        };
    }

    componentDidMount() {
        Backdrop.show();
    }

    componentWillUnmount() {
        Backdrop.hide();
    }

    render() {
        const { children, className, close, closeDisabled, title } = this.props;

        return (
            <div className={classnames(className, 'modal')}>
                <div className="modal-backdrop" onClick={close} />
                <div className="modal-wrapper">
                    <div className="modal-content">
                        <Split gutter="medium" className="u-spacing-stack-m">
                            <Grow weight={1} align="center">
                                {title}
                            </Grow>
                            <Grow weight={0} align="top">
                                <Button
                                    small
                                    disabled={closeDisabled}
                                    icon="close-thin"
                                    variant="outline"
                                    onClick={close}
                                />
                            </Grow>
                        </Split>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    close: PropTypes.func.isRequired,
    closeDisabled: PropTypes.bool,
    title: PropTypes.node,
};

Modal.defaultProps = {
    closeDisabled: false,
    title: '',
};

Modal.childContextTypes = {
    inverse: PropTypes.bool,
};

export default Modal;
