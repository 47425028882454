import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function addPlaceholder(placeholder, options) {
    if (!placeholder) {
        return options;
    }
    return [{ label: placeholder, value: null }, ...options];
}

class Dropdown extends React.Component {
    render() {
        const { invalid, options, placeholder, inverse, ...rest } = this.props;

        const isInversed = inverse || this.context.inverse;

        return (
            <select
                className={classnames(
                    'formInput',
                    { 'formInput--error': invalid },
                    { 'formInput--inverse': isInversed }
                )}
                {...rest}
            >
                {addPlaceholder(placeholder, options).map(opt => (
                    <option key={opt.value} value={opt.value}>
                        {opt.label}
                    </option>
                ))}
            </select>
        );
    }
}

Dropdown.propTypes = {
    invalid: PropTypes.bool,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    inverse: PropTypes.bool,
};

Dropdown.defaultProps = {
    invalid: false,
    options: [],
};

Dropdown.contextTypes = {
    inverse: PropTypes.bool,
};

export default Dropdown;
