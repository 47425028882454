import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { connect } from 'react-redux';
import { getSubtotal } from 'miniruche/modules/shopping/store/basketSelectors';
import { clear } from 'modules/oauth';
import classnames from 'classnames';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';
import Price from 'components/Price.jsx';
import { Link as RouterLink } from 'react-router-dom';
import { Section, Heading, Split, Grow, Icon, Text, Link, Button } from 'miniruche/ui';

export class PageHeader extends React.Component {
    static propTypes = {
        title: PropTypes.node.isRequired,
        backHref: PropTypes.string,
        backText: PropTypes.string,
        slug: PropTypes.string,
        saleId: PropTypes.number,
        user: PropTypes.object,
        basketSubtotal: PropTypes.object,
        showBasket: PropTypes.bool,
        className: PropTypes.string,
        extra: PropTypes.node,
        trans: PropTypes.func.isRequired,
    };

    static defaultProps = {
        backHref: null,
        backText: 'Retour',
        slug: null,
        saleId: null,
        user: null,
        showBasket: false,
    };

    onBasketClicked = () => {
        addUniversalEvent({
            eventCategory: 'Checkout',
            eventAction: 'basket_clicked',
            eventLabel: 'slug',
            eventValue: this.props.basketSubtotal && this.props.basketSubtotal.amount,
        });
    };

    logout = () => {
        clear();
        const path = window.location.pathname.split('/');
        const locale = path[1];
        const hasMinirucheInPath = path[2] === 'miniruche';
        const url = `/${path.splice(hasMinirucheInPath ? 3 : 2).join('/')}`;
        window.location = `/${locale}${
            hasMinirucheInPath ? '/miniruche' : ''
        }/login?redirectPath=${encodeURIComponent(url)}`;
    };

    render() {
        const {
            title,
            backHref,
            backText,
            basketSubtotal,
            showBasket,
            user,
            className,
            slug,
            saleId,
            extra,
            trans,
        } = this.props;

        return (
            <Section className={classnames('miniRucheHeader', 'u-spacing-inset-s', className)}>
                <Split align="center" gutter="small">
                    <Grow weight={1}>
                        {backHref ? (
                            <RouterLink to={backHref} className="u-blockLink">
                                <Split align="center" gutter="small">
                                    <Grow weight={0}>
                                        <Icon type="angle-left" />
                                    </Grow>
                                    <Grow weight={1}>
                                        <Heading
                                            rank={1}
                                            size="small"
                                            className="u-spacing-stack-xxs"
                                        >
                                            {title}
                                        </Heading>
                                        <Text size="small">{backText}</Text>
                                    </Grow>
                                </Split>
                            </RouterLink>
                        ) : (
                            <Heading rank={1} size="small">
                                {title}
                            </Heading>
                        )}
                    </Grow>
                    {user && (
                        <Grow weight={1} className="u-right">
                            <Text>{user.firstName}</Text>
                            <Link small onClick={this.logout}>
                                {trans('navigation.user.logout')}
                            </Link>
                        </Grow>
                    )}
                    {showBasket && (
                        <Grow weight={0}>
                            <Button
                                small
                                icon="basket"
                                iconSize="m"
                                variant="primary"
                                to={`/${slug}/sales/${saleId}/basket`}
                                onClick={this.onBasketClicked}
                            >
                                {basketSubtotal && <Price price={basketSubtotal} />}
                            </Button>
                        </Grow>
                    )}
                    {extra && <Grow weight={0}>{extra}</Grow>}
                </Split>
            </Section>
        );
    }
}

function mapStateToProps(state) {
    return {
        basketSubtotal: getSubtotal(state),
    };
}

export const i18nPageHeader = connectToI18n(PageHeader);

export default connect(mapStateToProps)(i18nPageHeader);
