import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { patch } from 'miniruche/services/api';
import { Button, Heading, Icon, Modal, Switch, Text } from 'miniruche/ui';

class VisibilitySetting extends React.Component {
    static propTypes = {
        miniruche: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        mustConfirmVisibility: false,
        visibleForEveryone: this.props.miniruche.visibleForEveryone,
    };

    onVisibilityChange = visibleForEveryone => {
        if (visibleForEveryone) {
            this.setState({ mustConfirmVisibility: true });
        } else {
            this.onVisibilitySave(false);
        }
    };

    onConfirmPublicVisibility = () => {
        this.setState({
            mustConfirmVisibility: false,
            visibleForEveryone: true,
        });
        this.onVisibilitySave(true);
    };

    onCancelPublicVisibility = () => {
        this.setState({ mustConfirmVisibility: false });
    };

    onVisibilitySave = visibleForEveryone => {
        this.setState({ visibleForEveryone });
        patch(`miniruche/admin/${this.props.miniruche.slug}`, {
            visibleForEveryone,
        }).catch(() => {
            this.setState({ visibleForEveryone: !visibleForEveryone });
        });
    };

    render() {
        const { mustConfirmVisibility, visibleForEveryone } = this.state;
        const { trans } = this.props;

        return (
            <div>
                <Heading rank={2} size="medium" className="u-spacing-stack-m">
                    {trans('miniruche.admin.visibility.all')}
                </Heading>
                <Text className="u-spacing-stack-m">
                    {trans('miniruche.admin.visibility.details')}
                </Text>
                <Switch
                    name="visibleForEveryone"
                    label={trans('miniruche.admin.visibility.public')}
                    onChange={this.onVisibilityChange}
                    value={visibleForEveryone}
                />
                {mustConfirmVisibility && (
                    <Modal close={this.onCancelPublicVisibility}>
                        <Heading rank={2} className="u-spacing-stack-m">
                            <Icon type="warning" className="u-spacing-inline-s" size="l" />
                            {trans('global.warning')}
                        </Heading>
                        <Text className="u-spacing-stack-l">
                            {trans('miniruche.admin.visibility.confirmText')}
                        </Text>
                        <Button
                            block
                            onClick={this.onConfirmPublicVisibility}
                            className="u-spacing-stack-s"
                            variant="primary"
                        >
                            {trans('miniruche.admin.visibility.changeToPublic')}
                        </Button>
                        <Button block onClick={this.onCancelPublicVisibility} variant="outline">
                            {trans('miniruche.admin.visibility.keepPrivate')}
                        </Button>
                    </Modal>
                )}
            </div>
        );
    }
}

export default connectToI18n(VisibilitySetting);
