import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';
import { Page, Section, Heading, Button, Image } from 'miniruche/ui';

export class Success extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    onEndButtonClicked = () => {
        addUniversalEvent({
            eventCategory: 'Sale',
            eventAction: 'miniruche_started',
        });
    };

    render() {
        const { slug, trans } = this.props;

        return (
            <Page blue>
                <Section small>
                    <Heading rank={1} size="large">
                        {trans('miniruche.create.success')}
                        <br />
                        {trans('miniruche.create.bravo')}
                    </Heading>
                    <Image fluid src="/assets/images/illustrations/miniruche-success.gif" />
                    <Button
                        block
                        variant="primary"
                        to={`/${slug}`}
                        onClick={this.onEndButtonClicked}
                    >
                        {trans('miniruche.create.ready')}
                    </Button>
                </Section>
            </Page>
        );
    }
}

export default connectToI18n(({ match, i18n, trans }) => (
    <Success i18n={i18n} slug={match.params.slug} trans={trans} />
));
