import React from 'react';
import PropTypes from 'prop-types';
import FormInput from './FormInput.jsx';
import Grow from 'miniruche/ui/layout/Grow.jsx';
import Split from 'miniruche/ui/layout/Split.jsx';

const nbsp = String.fromCharCode(160);

class DateInput extends React.Component {
    componentDidMount() {
        if (this.props.autofocus) {
            this.input.focus();
        }
    }

    onChange = key => {
        return ({ target }) => {
            this.props.onChange({
                ...this.props.value,
                [key]: target.value,
            });
        };
    };

    render() {
        const { invalid, inverse, autofocus, value, onChange, ...rest } = this.props;

        return (
            <Split align="center" {...rest}>
                <Grow weight={1}>
                    <FormInput
                        inverse={inverse}
                        invalid={invalid}
                        autofocus={autofocus}
                        placeholder="JJ"
                        value={value.day}
                        onChange={this.onChange('day')}
                    />
                </Grow>
                <Grow weight={0}>{`${nbsp}/${nbsp}`}</Grow>
                <Grow weight={1}>
                    <FormInput
                        inverse={inverse}
                        invalid={invalid}
                        placeholder="MM"
                        value={value.month}
                        onChange={this.onChange('month')}
                    />
                </Grow>
                <Grow weight={0}>{`${nbsp}/${nbsp}`}</Grow>
                <Grow weight={2}>
                    <FormInput
                        inverse={inverse}
                        invalid={invalid}
                        placeholder="AAAA"
                        value={value.year}
                        onChange={this.onChange('year')}
                    />
                </Grow>
            </Split>
        );
    }
}

DateInput.propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    inverse: PropTypes.bool,
    autofocus: PropTypes.bool,
};

DateInput.defaultProps = {
    invalid: false,
};

DateInput.contextTypes = {
    inverse: PropTypes.bool,
};

export default DateInput;
