import React from 'react';
import {
    Heading,
    Text,
    Section,
    Split,
    Grow,
    Box,
    Stack,
    ListItem,
    GroupItem,
    Carousel,
    Modal,
    Button,
    TabSet,
    Banner,
} from 'miniruche/ui';

const ExampleComponent = () => (
    <Split gutter="medium">
        <Grow weight={0}>
            <img
                src="https://dup5kvce96jhb.cloudfront.net/photo/59649ac66042714a632ee0ce/view/medium"
                width="100"
                height="100"
            />
        </Grow>
        <Grow>
            <Heading rank={4} size="small" className="u-spacing-stack-s">
                Fennel
            </Heading>
            <Button variant="outline">See more</Button>
        </Grow>
    </Split>
);

class Layout extends React.Component {
    constructor() {
        super();
        this.state = {
            isModalVisible: false,
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal() {
        this.setState({
            isModalVisible: true,
        });
    }

    hideModal() {
        this.setState({
            isModalVisible: false,
        });
    }

    render() {
        return (
            <div>
                <Section>
                    <Heading rank={2} size="large" className="u-spacing-stack-s">
                        Layout
                    </Heading>
                    <Text className="u-spacing-stack-l">
                        Basic layouts are built with 2 components : Split and Grow.
                    </Text>
                    <Text className="u-spacing-stack-m">
                        Split splits an area in equal parts. For example:
                    </Text>
                    <div className="u-spacing-stack-l">
                        <Split>
                            <Box>Part 1</Box>
                            <Box>Part 2</Box>
                            <Box>Part 3</Box>
                        </Split>
                    </div>
                    <Text className="u-spacing-stack-m">
                        You can also add gutters between the splitted parts:
                    </Text>
                    <div className="u-spacing-stack-xl">
                        <Split gutter="medium">
                            <Box>Part 1</Box>
                            <Box>Part 2</Box>
                            <Box>Part 3</Box>
                        </Split>
                    </div>
                    <Text className="u-spacing-stack-m">
                        If the parts must have differents widths, you will need the Grow component.
                    </Text>
                    <div className="u-spacing-stack-xl">
                        <Split gutter="medium">
                            <Grow weight={2}>
                                <Box>Part 1</Box>
                            </Grow>
                            <Grow weight={1}>
                                <Box>Part 2</Box>
                            </Grow>
                            <Grow weight={1}>
                                <Box>Part 3</Box>
                            </Grow>
                        </Split>
                    </div>
                    <Text className="u-spacing-stack-m">
                        You can align the splitted parts to the top...
                    </Text>
                    <div className="u-spacing-stack-xl">
                        <Split gutter="medium" align="top">
                            <Grow weight={2}>
                                <Box style={{ height: '200px' }}>Part 1</Box>
                            </Grow>
                            <Grow weight={1}>
                                <Box>Part 2</Box>
                            </Grow>
                            <Grow weight={1}>
                                <Box>Part 3</Box>
                            </Grow>
                        </Split>
                    </div>
                    <Text className="u-spacing-stack-m">To the bottom...</Text>
                    <div className="u-spacing-stack-xl">
                        <Split gutter="medium" align="bottom">
                            <Grow weight={2}>
                                <Box style={{ height: '200px' }}>Part 1</Box>
                            </Grow>
                            <Grow weight={1}>
                                <Box>Part 2</Box>
                            </Grow>
                            <Grow weight={1}>
                                <Box>Part 3</Box>
                            </Grow>
                        </Split>
                    </div>
                    <Text className="u-spacing-stack-m">Or center them.</Text>
                    <div className="u-spacing-stack-xl">
                        <Split gutter="medium" align="center">
                            <Grow weight={2}>
                                <Box style={{ height: '200px' }}>Part 1</Box>
                            </Grow>
                            <Grow weight={1}>
                                <Box>Part 2</Box>
                            </Grow>
                            <Grow weight={1}>
                                <Box>Part 3</Box>
                            </Grow>
                        </Split>
                    </div>
                    <Text className="u-spacing-stack-m">
                        You can also customize the alignement on each Grow component.
                    </Text>
                    <div className="u-spacing-stack-xl">
                        <Split gutter="medium" align="center">
                            <Grow weight={2}>
                                <Box style={{ height: '200px' }}>Part 1</Box>
                            </Grow>
                            <Grow weight={1} align="top">
                                <Box>Part 2</Box>
                            </Grow>
                            <Grow weight={1} align="bottom">
                                <Box>Part 3</Box>
                            </Grow>
                        </Split>
                    </div>
                    <Text className="u-spacing-stack-m">
                        If at some point you need to fill a vertical space, Split has a "vertical"
                        prop.
                    </Text>
                    <div className="u-spacing-stack-xl">
                        <Split gutter="medium">
                            <Grow>
                                <Box style={{ height: '300px' }}>Part 1</Box>
                            </Grow>
                            <Grow>
                                <Split gutter="medium" vertical>
                                    <Grow weight={2}>
                                        <Box style={{ height: '100%' }}>Part 2</Box>
                                    </Grow>
                                    <Grow weight={1}>
                                        <Box style={{ height: '100%' }}>Part 3</Box>
                                    </Grow>
                                </Split>
                            </Grow>
                        </Split>
                    </div>
                    <Text className="u-spacing-stack-m">
                        You can align vertical parts to the left, right, or center.
                    </Text>
                    <div>
                        <Split gutter="medium">
                            <Grow>
                                <Box style={{ height: '300px' }}>Part 1</Box>
                            </Grow>
                            <Grow>
                                <Split gutter="medium" vertical>
                                    <Grow weight={2}>
                                        <Box style={{ height: '100%' }}>Part 2</Box>
                                    </Grow>
                                    <Grow weight={1} align="right">
                                        <Box style={{ height: '100%', width: '120px' }}>Part 3</Box>
                                    </Grow>
                                </Split>
                            </Grow>
                        </Split>
                    </div>
                </Section>
                <Section>
                    <Heading rank={3} size="medium" className="u-spacing-stack-m">
                        Boxes
                    </Heading>
                    <Box className="u-spacing-stack-m">
                        <Heading rank={2} size="small" className="u-spacing-stack-s">
                            Hello
                        </Heading>
                        <Text className="u-spacing-stack-m">This is a regular box.</Text>
                        <Button>Got it</Button>
                    </Box>
                    <Box className="u-spacing-stack-m" type="success">
                        <Heading rank={2} size="small" className="u-spacing-stack-s">
                            Congratulations!
                        </Heading>
                        <Text className="u-spacing-stack-m">
                            This is a success box used to hightlight an achievement.
                        </Text>
                        <Button>Got it</Button>
                    </Box>
                    <Box type="warning">
                        <Heading rank={2} size="small" className="u-spacing-stack-s">
                            Beware!
                        </Heading>
                        <Text className="u-spacing-stack-m">This is a warning box.</Text>
                        <Button>Got it</Button>
                    </Box>
                </Section>
                <Section>
                    <Heading rank={3} size="medium" className="u-spacing-stack-m">
                        Stacks
                    </Heading>
                    <Text className="u-spacing-stack-m">
                        Stack adds a margin to each of its child except the last one.
                    </Text>
                    <Stack type="box" className="u-spacing-stack-l">
                        <Box>
                            <ExampleComponent />
                        </Box>
                        <Box>
                            <ExampleComponent />
                        </Box>
                        <Box>
                            <ExampleComponent />
                        </Box>
                    </Stack>
                    <Text className="u-spacing-stack-m">
                        The spacing can be customized with the gutter prop.
                    </Text>
                    <Stack className="u-spacing-stack-l" gutter="xs">
                        <Box>
                            <ExampleComponent />
                        </Box>
                        <Box>
                            <ExampleComponent />
                        </Box>
                        <Box>
                            <ExampleComponent />
                        </Box>
                    </Stack>
                    <Heading rank={4} size="small" className="u-spacing-stack-m">
                        Lists
                    </Heading>
                    <div className="u-spacing-stack-l">
                        <ListItem>
                            <ExampleComponent />
                        </ListItem>
                        <ListItem>
                            <ExampleComponent />
                        </ListItem>
                        <ListItem>
                            <ExampleComponent />
                        </ListItem>
                    </div>
                    <Heading rank={4} size="small" className="u-spacing-stack-m">
                        Groups
                    </Heading>
                    <div>
                        <GroupItem>
                            <ExampleComponent />
                        </GroupItem>
                        <GroupItem>
                            <ExampleComponent />
                        </GroupItem>
                        <GroupItem>
                            <ExampleComponent />
                        </GroupItem>
                    </div>
                </Section>
                <Section>
                    <Heading rank={3} size="medium" className="u-spacing-stack-s">
                        Carousel
                    </Heading>
                    <Text>
                        Note: it doesn't look good on mobile on this page with the examples sidebar.
                        It won't happen in the app, but we should fix that anyway.
                    </Text>
                </Section>
                <Section noPadding>
                    <Carousel>
                        <Box>Part 1</Box>
                        <Box>Part 2</Box>
                        <Box>Part 3</Box>
                        <Box>Part 4</Box>
                        <Box>Part 5</Box>
                    </Carousel>
                </Section>
                <Section>
                    <Heading rank={4} size="small" className="u-spacing-stack-m">
                        Modal
                    </Heading>
                    <Button onClick={this.showModal}>Click me to open a modal</Button>
                    {this.state.isModalVisible && (
                        <Modal
                            title={
                                <Heading rank={4} size="small">
                                    I'm a modal
                                </Heading>
                            }
                            close={this.hideModal}
                        >
                            <Text>Some text.</Text>
                        </Modal>
                    )}
                </Section>
                <Section>
                    <Heading rank={4} size="small" className="u-spacing-stack-m">
                        TabSet
                    </Heading>
                    <TabSet
                        tabs={[
                            {
                                value: 'firstTab',
                                label: 'First tab',
                            },
                            {
                                value: 'secondTab',
                                label: 'Second tab',
                            },
                            {
                                value: 'thirdTab',
                                label: 'Third tab',
                            },
                        ]}
                        value="secondTab"
                    />
                </Section>
                <Section>
                    <Heading rank={4} size="small">
                        Banner
                    </Heading>
                </Section>
                <Banner color="yellow" className="u-spacing-stack-m">
                    I'm a yellow banner
                </Banner>
                <Banner color="white" className="u-spacing-stack-m">
                    I'm a white banner
                </Banner>
                <Banner color="blue" className="u-spacing-stack-m">
                    I'm a blue banner
                </Banner>
                <Banner color="red" className="u-spacing-stack-m">
                    I'm a red banner
                </Banner>
                <Banner color="green" className="u-spacing-stack-m">
                    I'm a green banner
                </Banner>
            </div>
        );
    }
}

export default Layout;
