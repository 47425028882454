import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Separator extends React.Component {
    render() {
        const { className, ...rest } = this.props;

        return <div className={classnames('separator', className)} {...rest} />;
    }
}

Separator.propTypes = {
    className: PropTypes.string,
};

export default Separator;
