import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import connectToI18n from 'modules/i18n/connectToI18n';
import { LOADING, READY } from 'modules/utils/ajaxStatuses';
import { searchProducts } from 'miniruche/services/product/product.service';
import { Button, FormInput, Grow, Page, Section, Split, Stack, Text } from 'miniruche/ui';
import PageHeader from '../components/layout/PageHeader.jsx';
import ProductSummary from '../components/product/ProductSummary.jsx';
import { BASKET_FETCH } from '../store/basketActionTypes';
import { ProductModal } from './ProductModal.jsx';

class Search extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        productId: PropTypes.number,
        saleId: PropTypes.number.isRequired,
        slug: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        keyword: null,
        products: [],
        status: READY,
    };

    componentDidMount() {
        this.props.dispatch({ type: BASKET_FETCH });
    }

    handleSearch = event => {
        event.preventDefault();

        const keyword = event.target.elements.keyword.value.trim();
        if (!keyword) {
            return;
        }

        const { saleId, slug } = this.props;

        searchProducts({ keyword, saleId, slug }).then(products => {
            this.setState({ products, status: READY });
        });

        this.setState({ keyword, products: [], status: LOADING });
    };

    render() {
        const { history, productId, saleId, slug, trans } = this.props;
        const { keyword, products, status } = this.state;
        const selectedProduct = R.find(R.propEq('id', productId), products);

        return (
            <Page>
                <PageHeader
                    backHref={`/${slug}/sales/${saleId}`}
                    backText={trans('miniruche.search.backToProducts')}
                    saleId={saleId}
                    showBasket
                    slug={slug}
                    title={trans('hostFarmsNavigation.findFarms.title')}
                />
                <Section>
                    <form noValidate onSubmit={this.handleSearch}>
                        <Split align="center">
                            <Grow weight={1}>
                                <FormInput
                                    autofocus
                                    disabled={status === LOADING}
                                    name="keyword"
                                    placeholder={trans('miniruche.search.placeholder')}
                                    type="search"
                                />
                            </Grow>
                            <Grow className="u-hidden-mobile" weight={0}>
                                <Button
                                    disabled={status === LOADING}
                                    icon="search"
                                    small
                                    type="submit"
                                    variant="clear"
                                />
                            </Grow>
                        </Split>
                    </form>
                </Section>
                <Section>
                    {status === LOADING && (
                        <div className="u-center">
                            <img
                                alt=""
                                src="/assets/images/miniruche/cercle_jaune.gif"
                                width="50"
                            />
                        </div>
                    )}
                    {status === READY &&
                        keyword &&
                        products.length > 0 &&
                        products.map(product => (
                            <ProductSummary
                                categoryId={product.rootCategoryId}
                                dismissBanner={() => {}}
                                key={product.id}
                                like={() => {}}
                                onClickDetails={() => {}}
                                product={product}
                                productDetailsUrl={`/${slug}/sales/${saleId}/search/products/${product.id}`}
                                saleId={saleId}
                                slug={slug}
                                unLike={() => {}}
                                userFirstName=""
                            />
                        ))}
                    {status === READY && keyword && products.length === 0 && (
                        <Stack className="u-center" gutter="m">
                            <Text>{trans('miniruche.search.ohno')}</Text>
                            <Text>
                                {trans('miniruche.search.noresult', {
                                    keyword,
                                })}
                            </Text>
                        </Stack>
                    )}
                </Section>
                {selectedProduct && (
                    <ProductModal
                        categoryId={selectedProduct.rootCategoryId}
                        history={history}
                        product={selectedProduct}
                        saleId={saleId}
                        slug={slug}
                    />
                )}
            </Page>
        );
    }
}

const Connected = connect(() => ({}))(Search);

export default connectToI18n(({ history, location, match }) => (
    <Connected
        history={history}
        location={location}
        productId={match.params.productId && parseInt(match.params.productId, 10)}
        saleId={parseInt(match.params.saleId, 10)}
        slug={match.params.slug}
    />
));
