import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class ListItem extends React.Component {
    render() {
        const { children, inverse, className, spacing } = this.props;

        const isInversed = inverse || this.context.inverse;

        return (
            <div
                className={classnames(
                    'listItem',
                    `u-spacing-${spacing}`,
                    { 'listItem--inverse': isInversed },
                    className
                )}
            >
                {children}
            </div>
        );
    }
}

ListItem.propTypes = {
    children: PropTypes.node,
    spacing: PropTypes.string,
    className: PropTypes.string,
    inverse: PropTypes.bool,
};

ListItem.defaultProps = {
    spacing: 'inset-m',
};

ListItem.contextTypes = {
    inverse: PropTypes.bool,
};

export default ListItem;
