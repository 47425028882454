import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import { capitalizeFirstLetter } from 'modules/utils/string';
import { Link } from 'react-router-dom';
import RemainingDays from 'miniruche/components/sale/RemainingDays.jsx';
import { Split, Grow, Text, Button, Heading } from 'miniruche/ui';

export class SaleSummary extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        sale: PropTypes.object.isRequired,
        i18n: PropTypes.string.isRequired,
        className: PropTypes.string,
        onClick: PropTypes.func,
    };

    render() {
        const { slug, sale, i18n, className, onClick } = this.props;

        return (
            <Link
                to={`/${slug}/sales/${sale.id}`}
                className={classnames('u-blockLink', className)}
                onClick={onClick}
            >
                <Split align="center" gutter="small">
                    <Grow weight={1}>
                        <Heading rank={2} className="u-spacing-stack-xxs" size="small">
                            {capitalizeFirstLetter(getLocalizedDate(i18n, 'I', sale.date))}
                        </Heading>
                        <Text className="u-spacing-stack-xxs" size="small">
                            {sale.assemblyName}
                        </Text>
                        <Text type="success">
                            <RemainingDays saleEndDate={sale.saleEndDate} />
                        </Text>
                    </Grow>
                    <Grow weight={0}>
                        <Button small variant="clear" icon="angle-right" />
                    </Grow>
                </Split>
            </Link>
        );
    }
}

export default connectToI18n(SaleSummary);
