import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Text } from 'miniruche/ui';

class SaleStatus extends React.PureComponent {
    render() {
        const { sale, className, trans } = this.props;

        if (sale.status !== 'sale.opened') {
            return (
                <Text className={className} type="warning">
                    {trans('distribution.finished')}
                </Text>
            );
        }

        if (sale.isPaused) {
            return (
                <Text className={className} type="warning">
                    {trans('miniruche.sale.status.stopped')}
                </Text>
            );
        }

        return (
            <Text className={className} type="success">
                {trans('farms.distributions.status.ongoingSale')}
            </Text>
        );
    }
}

SaleStatus.propTypes = {
    sale: PropTypes.object.isRequired,
    className: PropTypes.string,
    trans: PropTypes.func.isRequired,
};

export default connectToI18n(SaleStatus);
