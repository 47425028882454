import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { connect } from 'react-redux';
import { get } from 'miniruche/services/api';
import { INITIAL_LOADING, READY, SAVING } from 'modules/utils/ajaxStatuses';
import { validateOrder } from 'miniruche/services/basket/basket.service';
import PageHeader from 'miniruche/modules/shopping/components/layout/PageHeader.jsx';
import BasketItem from 'miniruche/modules/shopping/components/orderItem/BasketItem.jsx';
import BasketTotal from 'miniruche/modules/shopping/components/basket/BasketTotal.jsx';
import BasketFee from 'miniruche/modules/shopping/components/basket/BasketFee.jsx';
import UnavailableProductsModal from 'miniruche/modules/shopping/components/basket/UnavailableProductsModal.jsx';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';
import { getStatus, getItems, getSubtotal } from 'miniruche/modules/shopping/store/basketSelectors';
import { INITIAL } from 'miniruche/modules/shopping/store/basketStatuses';
import { BASKET_FETCH } from 'miniruche/modules/shopping/store/basketActionTypes';
import { Page, Section, Tag, Text, Button, Split, Grow, Image } from 'miniruche/ui';

const addCurrentQuantity = basket => removedItem => {
    const currentItem = R.find(R.pathEq(['offer', 'id'], removedItem.offer.id), basket.items);
    return R.assoc('currentQuantity', currentItem ? currentItem.quantity : 0, removedItem);
};

function addCurrentQuantities(basket) {
    return R.evolve(
        {
            removedItems: R.map(addCurrentQuantity(basket)),
        },
        basket
    );
}

export class Basket extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        saleId: PropTypes.number.isRequired,
        hasError: PropTypes.bool,
        trans: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        basketStatus: PropTypes.string.isRequired,
        basketItems: PropTypes.object.isRequired,
        basketSubtotal: PropTypes.object.isRequired,
    };

    static defaultProps = {
        hasError: false,
    };

    state = {
        basket: {},
        sale: null,
        requestedDelivery: null,
        showDeliveryModal: false,
        status: INITIAL_LOADING,
        errors: {},
        deliveryRequestStatus: READY,
        hasDeliveryFeature: false,
    };

    componentDidMount() {
        this.props.dispatch({ type: BASKET_FETCH });
        const userRequest = get('miniruche/users/me');
        const basketRequest = get(`miniruche/${this.props.slug}/sale/${this.props.saleId}/basket`);
        const saleRequest = get(`miniruche/${this.props.slug}/sale/${this.props.saleId}`);
        Promise.all([userRequest, basketRequest, saleRequest]).then(this.onDataLoaded);
    }

    onDataLoaded = ([user, basket, sale]) => {
        this.setState({
            user,
            basket,
            sale,
            status: READY,
        });
    };

    onCheckoutClicked = () => {
        const { saleId, slug } = this.props;

        this.setState({
            status: SAVING,
        });

        addUniversalEvent({
            eventCategory: 'Checkout',
            eventAction: 'basket_validated',
            eventLabel: this.state.basket.id,
            eventValue: this.props.basketSubtotal.amount,
        });

        this.normalCheckout(saleId, slug);
    };

    normalCheckout = (saleId, slug) => {
        return validateOrder(saleId, slug, true).catch(err => {
            this.setState({
                status: READY,
            });
            if (
                err.status === 400 &&
                err.responseJSON &&
                err.responseJSON.error === 'MissingUserInformation'
            ) {
                this.props.history.push(
                    `/${slug}/sales/${saleId}/basket/${err.responseJSON.orderUuid}/userInformation`
                );
            } else if (err.status === 409) {
                this.setState({
                    basket: addCurrentQuantities(err.responseJSON),
                });
            }
        });
    };

    clearRemovedItems = () => {
        this.setState(
            R.evolve({
                basket: R.dissoc('removedItems'),
            })
        );
    };

    render() {
        const { saleId, slug, hasError, trans, basketStatus, basketItems } = this.props;

        const hasItems = !R.isEmpty(basketItems);

        const { basket } = this.state;

        if (this.state.status === INITIAL_LOADING || basketStatus === INITIAL) {
            return <Page />;
        }

        return (
            <Page>
                <PageHeader
                    slug={slug}
                    saleId={saleId}
                    title={
                        hasItems ? (
                            <div>
                                {trans('miniruche.sale.basketOf')} <BasketTotal />
                            </div>
                        ) : (
                            trans('global.basket')
                        )
                    }
                    backText={trans('miniruche.basket.back')}
                    backHref={`/${slug}/sales/${saleId}`}
                />
                {!hasItems && (
                    <Section className="u-center">
                        <Image
                            style={{ width: '80%', maxWidth: '400px', margin: '0 auto' }}
                            src="/assets/images/illustrations/miniruche-emptybasket.gif"
                        />
                        <Text className="u-spacing-stack-m">{trans('miniruche.basket.empty')}</Text>
                        <Button
                            block
                            variant="primary"
                            to={`/${slug}/sales/${saleId}`}
                            className="u-spacing-stack-m"
                        >
                            {trans('miniruche.basket.back')}
                        </Button>
                    </Section>
                )}
                {hasItems && (
                    <Section>
                        {hasError && (
                            <Tag block className="u-spacing-stack-s" type="warning">
                                {trans('order.status.canceled')}
                            </Tag>
                        )}
                        <div className="u-spacing-stack-l">
                            {basket.items
                                .filter(i => !i.isCanceled)
                                .map(item => {
                                    return (
                                        <BasketItem
                                            key={item.id}
                                            slug={slug}
                                            saleId={saleId}
                                            item={item}
                                        />
                                    );
                                })}
                        </div>
                        {basket.specialFees.map(fee => {
                            if (fee.amount === 0) {
                                return null;
                            }
                            return (
                                <Split
                                    key={fee.key}
                                    align="top"
                                    gutter="small"
                                    className="u-spacing-stack-m"
                                >
                                    <Grow weight={1}>
                                        {fee.key !== 'Frais de fonctionnement de la mini ruche' &&
                                            fee.key !== 'miniruche' && (
                                                <Text className="u-spacing-stack-xxs">
                                                    {fee.description}
                                                </Text>
                                            )}
                                        {(fee.key === 'Frais de fonctionnement de la mini ruche' ||
                                            fee.key === 'miniruche') && (
                                            <Fragment>
                                                <Text className="u-spacing-stack-xxs">
                                                    {trans('miniruche.fees.description')}
                                                </Text>
                                                <Text size="small">
                                                    {trans('miniruche.basket.fees')}
                                                </Text>
                                            </Fragment>
                                        )}
                                    </Grow>
                                    <Grow weight={0}>
                                        <Text>
                                            <BasketFee />
                                        </Text>
                                    </Grow>
                                </Split>
                            );
                        })}
                        <Split align="top" gutter="small" className="u-spacing-stack-m">
                            <Grow weight={1}>
                                <Text strong>{trans('miniruche.basket.totalAmount')}</Text>
                            </Grow>
                            <Grow weight={0}>
                                <Text strong>
                                    <BasketTotal />
                                </Text>
                            </Grow>
                        </Split>
                        <Section>
                            <Button
                                disabled={status === SAVING || this.props.basketStatus !== 'READY'}
                                variant="primary"
                                onClick={this.onCheckoutClicked}
                                block
                            >
                                {trans('paymentpage.pay')}
                            </Button>
                        </Section>
                    </Section>
                )}
                {basket.removedItems && (
                    <UnavailableProductsModal
                        orderItems={basket.removedItems}
                        close={this.clearRemovedItems}
                        saleURL={`/${slug}/sales/${saleId}`}
                        confirmOrder={this.onCheckoutClicked}
                    />
                )}
            </Page>
        );
    }
}

function mapStateToProps(state) {
    return {
        basketStatus: getStatus(state),
        basketItems: getItems(state),
        basketSubtotal: getSubtotal(state),
    };
}

const Connected = connect(mapStateToProps)(Basket);

export default connectToI18n(({ match, history, i18n, trans }) => (
    <Connected
        i18n={i18n}
        trans={trans}
        slug={match.params.slug}
        saleId={parseInt(match.params.saleId, 10)}
        hasError={match.params.status === 'error'}
        history={history}
    />
));
