import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InlineSVG from 'svg-inline-react';
import arrowRightLargeIcon from 'icons/arrow-right-large.svg';
import angleLeftIcon from 'icons/angle-left.svg';
import angleRightIcon from 'icons/angle-right.svg';
import angleUpIcon from 'icons/angle-up.svg';
import angleDownIcon from 'icons/angle-down.svg';
import okIcon from 'icons/ok.svg';
import heartIcon from 'icons/mini-heart.svg';
import heartIconOn from 'icons/mini-heart-on.svg';
import basketIcon from 'icons/basket.svg';
import lockIcon from 'icons/lock.svg';
import closeIcon from 'icons/close-thin.svg';
import facebookIcon from 'icons/share-facebook.svg';
import whatsappIcon from 'icons/share-whatsapp.svg';
import emailIcon from 'icons/share-email.svg';
import smsIcon from 'icons/share-sms.svg';
import messengerIcon from 'icons/share-messenger.svg';
import plusIcon from 'icons/plus.svg';
import minusIcon from 'icons/minus.svg';
import freshIcon from 'icons/fresh.svg';
import fragileIcon from 'icons/fragile.svg';
import pauseIcon from 'icons/pause.svg';
import playIcon from 'icons/play.svg';
import warningIcon from 'icons/warning.svg';
import searchIcon from 'icons/search.svg';

const iconsMap = {
    'arrow-right-large': arrowRightLargeIcon,
    'angle-left': angleLeftIcon,
    'angle-right': angleRightIcon,
    'angle-up': angleUpIcon,
    'angle-down': angleDownIcon,
    ok: okIcon,
    heart: heartIcon,
    'heart-on': heartIconOn,
    basket: basketIcon,
    lock: lockIcon,
    'close-thin': closeIcon,
    'share-facebook': facebookIcon,
    'share-sms': smsIcon,
    'share-email': emailIcon,
    'share-whatsapp': whatsappIcon,
    'share-messenger': messengerIcon,
    plus: plusIcon,
    minus: minusIcon,
    fresh: freshIcon,
    fragile: fragileIcon,
    pause: pauseIcon,
    play: playIcon,
    warning: warningIcon,
    search: searchIcon,
};

class Icon extends React.Component {
    render() {
        const { type, size, color, className } = this.props;

        return (
            <InlineSVG
                className={classnames(
                    'icon',
                    `icon--${size}`,
                    { [`icon--${color}`]: !!color },
                    className
                )}
                src={iconsMap[type]}
            />
        );
    }
}

Icon.propTypes = {
    type: PropTypes.string.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
};

Icon.defaultProps = {
    size: 'm',
};

export default Icon;
