import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import Sale from './pages/Sale.jsx';
import Search from './pages/Search.jsx';
import Category from './pages/Category.jsx';
import Basket from './pages/Basket.jsx';
import UserInformation from './pages/UserInformation.jsx';

export default class ShoppingApp extends React.PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        const { slug, saleId } = props.match.params;

        this.store = configureStore({ slug, saleId });
    }

    render() {
        return (
            <Provider store={this.store}>
                <Switch>
                    <Route exact path="/:slug/sales/:saleId" component={Sale} />
                    <Route exact path="/:slug/sales/:saleId/search" component={Search} />
                    <Route
                        exact
                        path="/:slug/sales/:saleId/search/products/:productId"
                        component={Search}
                    />
                    <Route
                        exact
                        path="/:slug/sales/:saleId/categories/:categoryId"
                        component={Category}
                    />
                    <Route
                        exact
                        path="/:slug/sales/:saleId/categories/:categoryId/products/:productId"
                        component={Category}
                    />
                    <Route exact path="/:slug/sales/:saleId/basket/:status?" component={Basket} />
                    <Route
                        exact
                        path="/:slug/sales/:saleId/basket/:orderUuid/userInformation"
                        component={UserInformation}
                    />
                </Switch>
            </Provider>
        );
    }
}
