import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { lowerCaseAndUnaccent } from 'modules/utils/string';
import { getCountries } from 'miniruche/services/country/countries.service';
import CityAutocomplete from 'miniruche/components/address/CityAutocomplete.jsx';
import { Input } from 'miniruche/ui';

const buildNationalitiesOptions = R.pipe(
    R.sortBy(R.pipe(R.prop('name'), lowerCaseAndUnaccent)),
    R.map(country => ({ value: country.code, label: country.name }))
);

class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.onFirstNameChange = this.onFirstNameChange.bind(this);
        this.onLastNameChange = this.onLastNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onZipCodeChange = this.onZipCodeChange.bind(this);
        this.onCitySelected = this.onCitySelected.bind(this);
        this.onStreetChange = this.onStreetChange.bind(this);
        this.onBirthdayChange = this.onBirthdayChange.bind(this);
        this.onNationalityChange = this.onNationalityChange.bind(this);
        this.state = {
            nationalities: [],
        };
    }

    componentDidMount() {
        if (!this.props.hideNationality) {
            getCountries().then(countries =>
                this.setState({
                    nationalities: buildNationalitiesOptions(countries),
                })
            );
        }
    }

    onFirstNameChange(event) {
        this.props.onChange('firstName', event.target.value);
    }

    onLastNameChange(event) {
        this.props.onChange('lastName', event.target.value);
    }

    onEmailChange(event) {
        this.props.onChange('email', event.target.value);
    }

    onStreetChange(event) {
        this.props.onChange('street', event.target.value);
    }

    onZipCodeChange(zipCode) {
        this.props.onChange('city', {
            zipCode,
            id: null,
            name: null,
        });
    }

    onCitySelected({ id, name, zipCode }) {
        this.props.onChange('city', {
            zipCode,
            id,
            name,
        });
    }

    onPasswordChange(event) {
        this.props.onChange('password', event.target.value);
    }

    onBirthdayChange(value) {
        this.props.onChange('birthday', value);
    }

    onNationalityChange(event) {
        this.props.onChange('nationality', event.target.value);
    }

    render() {
        const {
            firstName,
            lastName,
            email,
            street,
            city,
            birthday,
            nationality,
            password,
            hideEmail,
            hideStreet,
            hideBirthday,
            hideNationality,
            errors,
            className,
            trans,
        } = this.props;

        const { nationalities } = this.state;

        return (
            <div className={className}>
                <Input
                    autofocus
                    label={trans('global.placeHolder.firstName')}
                    value={firstName}
                    onChange={this.onFirstNameChange}
                    errors={errors.firstName}
                />
                <Input
                    label={trans('global.placeHolder.name')}
                    value={lastName}
                    onChange={this.onLastNameChange}
                    errors={errors.lastName}
                />
                {!hideEmail && (
                    <Input
                        label={trans('global.label.email')}
                        value={email}
                        onChange={this.onEmailChange}
                        errors={errors.email}
                    />
                )}
                {!hideStreet && (
                    <Input
                        label={trans('address.street')}
                        name="street"
                        value={street}
                        onChange={this.onStreetChange}
                        errors={errors.address && errors.address.street}
                    />
                )}
                <CityAutocomplete
                    label={trans('address.zip')}
                    zipCode={city.name ? `${city.zipCode} - ${city.name}` : city.zipCode}
                    onZipCodeChange={this.onZipCodeChange}
                    onCitySelected={this.onCitySelected}
                    errors={errors.address && errors.address.city}
                />
                <Input
                    type="password"
                    label={trans('global.password')}
                    value={password}
                    onChange={this.onPasswordChange}
                    errors={errors.plainPassword}
                />
                {!hideBirthday && (
                    <Input
                        type="date"
                        label={trans('global.dateOfBirth')}
                        value={birthday}
                        onChange={this.onBirthdayChange}
                        errors={errors.birthday}
                    />
                )}
                {!hideNationality && (
                    <Input
                        type="dropdown"
                        label={trans('global.nationality')}
                        placeholder={R.isEmpty(nationalities) ? '...' : ''}
                        disabled={R.isEmpty(nationalities)}
                        options={nationalities}
                        value={nationality}
                        onChange={this.onNationalityChange}
                        errors={errors.nationality}
                    />
                )}
            </div>
        );
    }
}

RegisterForm.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    city: PropTypes.object.isRequired,
    street: PropTypes.string,
    birthday: PropTypes.object,
    nationality: PropTypes.string,
    password: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    hideEmail: PropTypes.bool,
    hideStreet: PropTypes.bool,
    hideBirthday: PropTypes.bool,
    hideNationality: PropTypes.bool,
    errors: PropTypes.object,
    className: PropTypes.string,
    trans: PropTypes.func.isRequired,
};

RegisterForm.defaultProps = {
    hideEmail: false,
    errors: {},
};

export default connectToI18n(RegisterForm);
