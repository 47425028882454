import React from 'react';
import { connect } from 'react-redux';
import connectToI18n from 'modules/i18n/connectToI18n';
import CategoryHeading from 'miniruche/modules/shopping/components/category/CategoryHeading.jsx';
import ProductSummary from 'miniruche/modules/shopping/components/product/ProductSummary.jsx';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { uniqueId } from 'underscore';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';
import PageHeader from 'miniruche/modules/shopping/components/layout/PageHeader.jsx';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import {
    fetchProducts,
    fetchPreviouslyBoughtProductIds,
    fetchDismissedBanners,
    like,
    unlike,
    dismissBanner,
    optimisticLike,
    optimisticUnlike,
    optimisticDismissBanner,
} from 'miniruche/services/product/product.service';
import { fetchUser } from 'miniruche/services/user/user.service';
import { fetchSale } from 'miniruche/services/sale/sale.service';
import { fetchProductTypes } from 'miniruche/services/productType/productType.service';
import { getStatus } from 'miniruche/modules/shopping/store/basketSelectors';
import { INITIAL } from 'miniruche/modules/shopping/store/basketStatuses';
import { BASKET_FETCH } from 'miniruche/modules/shopping/store/basketActionTypes';
import Request from 'miniruche/effects/Request.jsx';
import { Page, Section } from 'miniruche/ui';
import ProductModal from './ProductModal.jsx';

function initialLoad(payload) {
    return Promise.all([
        fetchUser(payload),
        fetchProducts(payload),
        fetchProductTypes(payload),
        fetchPreviouslyBoughtProductIds(payload),
        fetchDismissedBanners(payload),
        fetchSale(payload),
    ]);
}

const actions = {
    sortProducts() {
        return state => ({
            ...state,
            products: R.sortWith([
                R.ascend(
                    R.pipe(
                        R.prop('categoryId'),
                        R.propEq('id'),
                        R.flip(R.findIndex)(state.productTypes)
                    )
                ),
                R.ascend(R.prop('name')),
            ])(state.products),
        });
    },
    like(productId) {
        return state =>
            R.evolve({
                products: R.map(
                    R.when(R.propEq('id', productId), optimisticLike(state.user.firstName))
                ),
            })(state);
    },
    unLike(productId) {
        return state =>
            R.evolve({
                products: R.map(
                    R.when(R.propEq('id', productId), optimisticUnlike(state.user.firstName))
                ),
            })(state);
    },
    addRequest(type, payload) {
        return state => ({
            ...state,
            requests: [
                ...state.requests,
                {
                    type,
                    payload,
                    id: uniqueId(),
                },
            ],
        });
    },
    removeRequest(type, payload) {
        return state => ({
            ...state,
            requests: R.reject(r => r.type === type && r.payload === payload, state.requests),
        });
    },
    updatePreviouslyBought(previouslyBoughtProductsIds) {
        return state => ({
            ...state,
            products: R.map(
                product => ({
                    ...product,
                    previouslyBought: R.contains(product.id, previouslyBoughtProductsIds),
                }),
                state.products
            ),
        });
    },
    updateBannerVisibility(dismissedBanners) {
        return state => ({
            ...state,
            products: R.map(
                product => ({
                    ...product,
                    isBannerDismissed: R.contains(product.id, dismissedBanners),
                }),
                state.products
            ),
        });
    },
    dismissBanner(productId) {
        return R.evolve({
            products: R.map(R.when(R.propEq('id', productId), optimisticDismissBanner)),
        });
    },
    setUser(user) {
        return state => ({
            ...state,
            user,
        });
    },
    setSale(sale) {
        return state => ({
            ...state,
            sale,
        });
    },
    setProducts(products) {
        return state => ({
            ...state,
            products,
        });
    },
    setProductTypes(productTypes) {
        return state => ({
            ...state,
            productTypes,
        });
    },
    setStatus(status) {
        return state => ({
            ...state,
            status,
        });
    },
};

export class Category extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        saleId: PropTypes.number.isRequired,
        categoryId: PropTypes.number.isRequired,
        productId: PropTypes.number,
        dispatch: PropTypes.func.isRequired,
        basketStatus: PropTypes.string.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        subCategories: [],
        status: INITIAL_LOADING,
        requests: ___BROWSER___
            ? [
                  {
                      type: 'INITIAL_LOAD',
                      payload: {
                          slug: this.props.slug,
                          saleId: this.props.saleId,
                          categoryId: this.props.categoryId,
                      },
                      id: uniqueId(),
                  },
              ]
            : [],
    };

    componentDidMount() {
        if (
            this.props.productId &&
            this.props.location.state &&
            this.props.location.state.fromDashboard
        ) {
            const categoryUrl = `/${this.props.slug}/sales/${this.props.saleId}/categories/${this.props.categoryId}`;
            this.props.history.replace(categoryUrl);
            this.props.history.push(`${categoryUrl}/products/${this.props.productId}`);
        }
        this.props.dispatch({ type: BASKET_FETCH });
    }

    onInitialLoadSuccess = (
        [user, products, productTypes, previouslyBoughtProductsIds, dismissedBanners, sale],
        payload
    ) => {
        this.setState(
            R.pipe(
                actions.setUser(user),
                actions.setSale(sale),
                actions.setProducts(products),
                actions.setProductTypes(productTypes),
                actions.sortProducts(),
                actions.updatePreviouslyBought(previouslyBoughtProductsIds),
                actions.updateBannerVisibility(dismissedBanners),
                actions.removeRequest('INITIAL_LOAD', payload),
                actions.setStatus(READY)
            )
        );
    };

    onLikeRequest = payload => {
        this.setState(R.pipe(actions.like(payload.productId), actions.addRequest('LIKE', payload)));
    };

    onLikeSuccess = (response, payload) => {
        this.setState(actions.removeRequest('LIKE', payload));
    };

    onLikeError = (error, payload) => {
        this.setState(
            R.pipe(actions.unLike(payload.productId), actions.removeRequest('LIKE', payload))
        );
    };

    onUnLikeRequest = payload => {
        this.setState(
            R.pipe(actions.unLike(payload.productId), actions.addRequest('UNLIKE', payload))
        );
    };

    onUnLikeSuccess = (response, payload) => {
        this.setState(actions.removeRequest('UNLIKE', payload));
    };

    onUnLikeError = (error, payload) => {
        this.setState(
            R.pipe(actions.like(payload.productId), actions.removeRequest('UNLIKE', payload))
        );
    };

    onDismissBannerRequest = payload => {
        this.setState(
            R.pipe(
                actions.addRequest('DISMISS_BANNER', payload),
                actions.dismissBanner(payload.productId)
            )
        );
    };

    onDismissBannerSuccess = (response, payload) => {
        this.setState(actions.removeRequest('DISMISS_BANNER', payload));
    };

    onDismissBannerError = (error, payload) => {
        this.setState(actions.removeRequest('DISMISS_BANNER', payload));
    };

    onClickProduct = productId => {
        addUniversalEvent({
            eventCategory: 'Sale',
            eventAction: 'product_clicked',
            eventLabel: productId,
        });
    };

    render() {
        const { basketStatus, slug, categoryId, saleId, productId, trans } = this.props;

        const { status, products, productTypes, user } = this.state;

        if (status === INITIAL_LOADING || basketStatus === INITIAL) {
            return (
                <Page>
                    {this.state.requests.filter(R.propEq('type', 'INITIAL_LOAD')).map(request => {
                        return (
                            <Request
                                key={request.id}
                                handler={initialLoad}
                                payload={request.payload}
                                onSuccess={this.onInitialLoadSuccess}
                            />
                        );
                    })}
                </Page>
            );
        }

        const categoryName = productTypes[0].categoryName;

        const selectedProduct = R.find(R.propEq('id', productId), products);

        return (
            <Page>
                {this.state.requests.filter(R.propEq('type', 'LIKE')).map(request => {
                    return (
                        <Request
                            key={request.id}
                            handler={like}
                            payload={request.payload}
                            onSuccess={this.onLikeSuccess}
                            onError={this.onLikeError}
                        />
                    );
                })}
                {this.state.requests.filter(R.propEq('type', 'UNLIKE')).map(request => {
                    return (
                        <Request
                            key={request.id}
                            handler={unlike}
                            payload={request.payload}
                            onSuccess={this.onUnLikeSuccess}
                            onError={this.onUnLikeError}
                        />
                    );
                })}
                {this.state.requests.filter(R.propEq('type', 'DISMISS_BANNER')).map(request => {
                    return (
                        <Request
                            key={request.id}
                            handler={dismissBanner}
                            payload={request.payload}
                            onSuccess={this.onDismissBannerSuccess}
                            onError={this.onDismissBannerError}
                        />
                    );
                })}
                <PageHeader
                    showBasket
                    slug={slug}
                    saleId={saleId}
                    title={categoryName}
                    backText={trans('miniruche.sale.backToCategories')}
                    backHref={`/${slug}/sales/${saleId}`}
                />
                {R.pipe(
                    R.filter(product => product.rootCategoryId === categoryId),
                    R.groupBy(product => product.parentCategoryId),
                    R.mapObjIndexed((subProducts, subCategoryId) => (
                        <Section key={subCategoryId} noPadding>
                            <CategoryHeading
                                productTypes={productTypes}
                                categoryId={parseInt(subCategoryId, 10)}
                            />
                            {subProducts.map(product => (
                                <ProductSummary
                                    key={product.id}
                                    product={product}
                                    slug={slug}
                                    categoryId={categoryId}
                                    saleId={saleId}
                                    like={this.onLikeRequest}
                                    unLike={this.onUnLikeRequest}
                                    userFirstName={user.firstName}
                                    dismissBanner={this.onDismissBannerRequest}
                                    onClickDetails={this.onClickProduct}
                                    productDetailsUrl={`/${slug}/sales/${saleId}/categories/${categoryId}/products/${product.id}`}
                                />
                            ))}
                        </Section>
                    )),
                    R.values
                )(products)}
                {selectedProduct && (
                    <ProductModal
                        slug={slug}
                        saleId={saleId}
                        categoryId={categoryId}
                        product={selectedProduct}
                    />
                )}
            </Page>
        );
    }
}

function mapStateToProps(state) {
    return {
        basketStatus: getStatus(state),
    };
}

const Connected = connect(mapStateToProps)(Category);

export default connectToI18n(({ match, location, history, trans }) => (
    <Connected
        location={location}
        history={history}
        slug={match.params.slug}
        saleId={parseInt(match.params.saleId, 10)}
        categoryId={parseInt(match.params.categoryId, 10)}
        productId={match.params.productId && parseInt(match.params.productId, 10)}
        trans={trans}
    />
));
