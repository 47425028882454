import * as R from 'ramda';
import {
    BASKET_INCREMENT_QUANTITY,
    BASKET_DECREMENT_QUANTITY,
    BASKET_SAVE,
    BASKET_SAVE_SUCCESS,
    BASKET_SAVE_ERROR,
    BASKET_FETCH,
    BASKET_FETCH_SUCCESS,
    BASKET_FETCH_ERROR,
} from './basketActionTypes';
import { INITIAL, SAVING, LOADING, READY } from './basketStatuses';

const initialState = {
    status: INITIAL,
    scheduledSave: false,
    items: {},
};

function getQuantity(offer, state) {
    return R.pathOr(0, ['items', offer.id, 'quantity'], state);
}

function setQuantity(offer, quantity, state) {
    return R.evolve(
        {
            items: R.assoc(offer.id, {
                offerId: offer.id,
                unitPrice: offer.price,
                quantity,
            }),
        },
        state
    );
}

function incrementQuantity(offer, state) {
    const previousQuantity = getQuantity(offer, state);
    const nextQuantity = previousQuantity + 1;
    return setQuantity(offer, nextQuantity, state);
}

function decrementQuantity(offer, state) {
    const previousQuantity = getQuantity(offer, state);
    if (previousQuantity <= 0) {
        return state;
    }
    const nextQuantity = R.max(0, previousQuantity - 1);
    return setQuantity(offer, nextQuantity, state);
}

function save(state) {
    return R.merge(state, {
        status: SAVING,
        scheduledSave: false,
    });
}

function scheduleSave(state) {
    return R.merge(state, {
        scheduledSave: true,
    });
}

function fetch(state) {
    return R.merge(state, {
        status: LOADING,
    });
}

function onSuccess({ items, fee }, state) {
    return R.merge(state, {
        status: READY,
        items,
        fee,
    });
}

function onError(state) {
    return R.merge(state, {
        status: READY,
    });
}

export default function basketsReducer(state = initialState, action) {
    switch (action.type) {
        case BASKET_INCREMENT_QUANTITY:
            return incrementQuantity(action.offer, state);

        case BASKET_DECREMENT_QUANTITY:
            return decrementQuantity(action.offer, state);

        case BASKET_SAVE:
            if (state.status === READY || state.status === INITIAL) {
                return save(state);
            }
            if (state.status === SAVING) {
                return scheduleSave(state);
            }
            return state;

        case BASKET_FETCH:
            if (state.status === READY || state.status === INITIAL) {
                return fetch(state);
            }
            return state;

        case BASKET_SAVE_SUCCESS:
            if (!state.scheduledSave) {
                return onSuccess(action, state);
            }
            return save(state);

        case BASKET_FETCH_SUCCESS:
            return onSuccess(action, state);

        case BASKET_SAVE_ERROR:
        case BASKET_FETCH_ERROR: {
            if (!state.scheduledSave) {
                return onError(state);
            }
            return save(state);
        }
        default:
            return state;
    }
}
