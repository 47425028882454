import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getLocalizedTime, getLocalizedDay } from 'modules/utils/dateAndTime';
import { capitalizeFirstLetter } from 'modules/utils/string';
import SimpleMap from 'components/Maps/SimpleMap.jsx';
import { Modal, Heading, Text } from 'miniruche/ui';
import { formatDistance } from 'modules/utils/distances';

export class AssemblyModal extends React.Component {
    static propTypes = {
        assembly: PropTypes.object.isRequired,
        i18n: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
    };

    render() {
        const { assembly, hideModal, i18n, trans } = this.props;

        const mapAssembly = {
            ...assembly,
            theme: {
                name: 'turquoise3',
            },
            place: {
                address: {
                    coordinates: assembly.coordinates,
                },
            },
        };

        return (
            <Modal
                close={hideModal}
                title={
                    <div>
                        <Heading rank={2} size="small" className="u-spacing-stack-xs">
                            {assembly.name}
                        </Heading>
                        <Text strong>
                            {trans('miniruche.assembly.distributionTimeWithDay', {
                                '%day%': capitalizeFirstLetter(
                                    getLocalizedDay(i18n, assembly.timeslots[0].day)
                                ),
                                '%start%': getLocalizedTime(i18n, assembly.timeslots[0].starting),
                                '%end%': getLocalizedTime(i18n, assembly.timeslots[0].ending),
                            })}
                        </Text>
                        {assembly.hasOpenSale && (
                            <Text type="success">{trans('miniruche.assembly.saleOpened')}</Text>
                        )}
                        {!assembly.hasOpenSale && (
                            <Text type="warning">{trans('miniruche.assembly.upcoming')}</Text>
                        )}
                    </div>
                }
            >
                {assembly.distance && (
                    <Text>
                        {trans('miniruche.assembly.distance', {
                            '%distance%': formatDistance(assembly.distance, 'm', i18n),
                        })}
                    </Text>
                )}
                <Text>{assembly.address.street}</Text>
                <Text className="u-spacing-stack-m">{assembly.address.city}</Text>
                <SimpleMap
                    className="miniRucheAssemblyMap"
                    places={[{ entity: mapAssembly, placeType: 'assembly' }]}
                    defaultZoom={16}
                />
            </Modal>
        );
    }
}

export default connectToI18n(AssemblyModal);
