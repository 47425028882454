import Globalize from 'globalize';
import './globalize.culture.da-DK';
Globalize.addCultureInfo('da', 'da-DK');

import moment from 'moment';
import 'moment/locale/da';
moment.defineLocale('da-DK', { parentLocale: 'da' });

export default {
    code: 'da-DK',

    // bcode is here for the "backCode" ... the code as seen/sent by the back :O
    bCode: 'da_DK',

    name: 'Danish',
    shortcut: 'da',
    supportEmail: 'info@madsamling.dk',
    privacyEmail: 'privacy@madsamling.dk',
    trainingSite: null,

    appStores: {
        apple: 'https://itunes.apple.com/fr/app/la-ruche-qui-dit-oui/id1052198033?mt=8',
        google: 'https://play.google.com/store/apps/details?id=com.lrqdo&hl=fr',
    },

    supportSite: {
        kycInformation: 'https://thefoodassembly.zendesk.com/hc/en-gb',
    },

    logos: {
        brown: '/assets/images/logos/brown/logo-dk.svg',
        turquoise: '/assets/images/logos/turquoise/logo-en.svg',
        white: '/assets/images/logos/white/logo-dk.svg',
        whiteOutlined: '/assets/images/logos/whiteOutlined/logo-dk.svg',
        blue: '/assets/images/logos/blue/logo-dk.svg',
        christmas: '/assets/images/logos/christmas/logo-en.svg',
    },

    facebook: 'https://www.facebook.com/TheFoodAssembly',
    twitter: 'https://twitter.com/foodassembly',

    dateFormats: {
        d: 'DD-MM-YYYY',
        D: 'DD. MMMM YYYY',
        I: 'DD. MMMM',
        i: 'DD. MMMM YYYY',
        t: 'HH:mm',
        T: 'HH:mm:ss',
        f: 'DD. MMMM YYYY HH:mm',
        F: 'DD. MMMM YYYY HH:mm:ss',
        M: 'DD. MMMM',
        Y: 'MMMM YYYY',
        shortDayDate: 'DD. MMMM',
        dateWithoutYear: 'DD.MM.',
    },

    inlineLogo: require('./logo.svg'),

    cguPage: 'https://madsamling.dk/da-DK/p/terms',
    homepage: 'https://madsamling.dk',
    minirucheHelpCenter: 'https://miniruche.zendesk.com/hc/fr',
};
