import * as R from 'ramda';
import { createSelector } from 'reselect';

export const getBasket = R.propOr({}, 'basket');

export const getItems = createSelector(getBasket, R.propOr({}, 'items'));

export const getStatus = createSelector(getBasket, R.prop('status'));

export const getFee = createSelector(getBasket, R.prop('fee'));

export const getSubtotal = createSelector(getItems, items => {
    const values = R.values(items);
    if (!values.length) {
        return null;
    }
    const currency = values[0].unitPrice.currency;
    const amount = R.reduce((acc, item) => acc + item.quantity * item.unitPrice.amount, 0, values);

    return {
        amount,
        currency,
    };
});

export const getComputedFee = createSelector(getSubtotal, getFee, (subTotal, fee) => {
    if (subTotal === null) {
        return null;
    }

    if (fee.rate !== null) {
        return R.evolve(
            {
                amount: R.multiply(fee.rate),
            },
            subTotal
        );
    }

    const { amount, currency } = fee;

    return { amount, currency };
});

export const getTotal = createSelector(getSubtotal, getComputedFee, (subTotal, fee) => {
    if (subTotal === null || fee === null) {
        return null;
    }
    return {
        amount: subTotal.amount + fee.amount,
        currency: subTotal.currency,
    };
});

export const getCount = createSelector(
    getItems,
    R.pipe(R.values, R.pluck('quantity'), R.filter(R.lt(0)), R.length)
);

export const getItem = (state, props) => {
    return R.pathOr(0, ['basket', 'items', props.offerId, 'quantity'], state);
};
