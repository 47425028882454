import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AutocompleteItemsList from 'components/AutocompleteItemsList.jsx';

class Autocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.onItemSelected = this.onItemSelected.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputBlur = this.onInputBlur.bind(this);
    }

    componentDidMount() {
        if (this.props.autofocus) {
            this.input.focus();
        }
    }

    onItemSelected(item) {
        this.props.onChange(item);
    }

    onInputChange(e) {
        this.props.onInput(e.target.value);
    }

    onInputBlur() {
        if (this.props.onBlur) {
            this.props.onBlur();
        }
        if (this.props.allowCustomText) {
            this.props.onCustomText();

            return;
        }

        if (this.props.options.length) {
            this.props.onChange(this.props.options[0]);
        }
    }

    render() {
        const { invalid, options, placeholder, inverse, value } = this.props;

        const isInversed = inverse || this.context.inverse;

        return (
            <div className="autocomplete">
                <input
                    className={classnames(
                        'formInput',
                        { 'formInput--error': invalid },
                        { 'formInput--inverse': isInversed }
                    )}
                    type="text"
                    placeholder={placeholder}
                    onChange={this.onInputChange}
                    onBlur={this.onInputBlur}
                    value={value}
                    ref={c => (this.input = c)}
                />
                <AutocompleteItemsList
                    key={value}
                    className="autocomplete-suggestions"
                    itemsData={options}
                    onItemSelected={this.onItemSelected}
                    isVisible={!!options.length}
                />
            </div>
        );
    }
}

Autocomplete.propTypes = {
    allowCustomText: PropTypes.bool,
    invalid: PropTypes.bool,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    inverse: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onCustomText: PropTypes.func,
    onInput: PropTypes.func.isRequired,
    value: PropTypes.string,
    autofocus: PropTypes.bool,
    onBlur: PropTypes.func,
};

Autocomplete.defaultProps = {
    allowCustomText: false,
    invalid: false,
    onCustomText: () => {},
    options: [],
};

Autocomplete.contextTypes = {
    inverse: PropTypes.bool,
};

export default Autocomplete;
