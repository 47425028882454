import getClientId from 'modules/analytics/getClientIdGA';
import { isAndroidWebview } from 'app/scripts/config/analytics';
import { get, post, patch } from 'miniruche/services/api';
import $ from 'jquery';

export function fetchBasket(payload) {
    const { slug, saleId } = payload;

    return get(`miniruche/${slug}/sale/${saleId}/basket`);
}

export function addToBasket(payload) {
    const { slug, saleId, offerId, quantity, previousQuantity } = payload;

    if (previousQuantity === 0) {
        return post(`miniruche/${slug}/sale/${saleId}/basket/items`, {
            offer: offerId,
            quantity,
        });
    }

    return patch(`miniruche/${slug}/sale/${saleId}/basket/items/${offerId}`, {
        quantity,
    });
}

export function validateOrder(saleId, slug, shouldRedirect = true) {
    return getClientId().then(clientId => {
        // Not all IE11 versions support window.location.origin
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/location#Browser_compatibility
        const port = window.location.port ? `:${window.location.port}` : '';
        const origin = `${window.location.protocol}//${window.location.hostname}${port}`;
        const locale = window.location.pathname.split('/')[1];
        const params = {
            urlAccept: `${origin}/${locale}/miniruche/${slug}`,
            urlDecline: `${origin}/${locale}/miniruche/${slug}/sales/${saleId}/basket/error`,
            urlCancel: `${origin}/${locale}/miniruche/${slug}/sales/${saleId}/basket`,
            clientId,
        };
        if (isAndroidWebview()) {
            params.androidWebview = 1;
        }
        const request = post(`miniruche/${slug}/sale/${saleId}/basket/confirm`, params).then(
            data => {
                if (shouldRedirect && data.paymentForm) {
                    $(data.paymentForm)
                        .appendTo('body')
                        .hide()
                        .submit();
                } else {
                    return data;
                }
            }
        );

        return request;
    });
}
