import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { get, patch, post } from 'miniruche/services/api';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import { getLocalizedTime, getLocalizedDay } from 'modules/utils/dateAndTime';
import { capitalizeFirstLetter } from 'modules/utils/string';
import AssemblyBox from 'miniruche/components/assembly/AssemblyBox.jsx';
import AssemblyModal from 'miniruche/components/assembly/AssemblyModal.jsx';
import AssembliesSearch from 'miniruche/components/assembly/AssembliesSearch.jsx';
import PageHeader from 'miniruche/ui/layout/PageHeader.jsx';
import { Page, Section, Stack, Box, Split, Grow, Heading, Text, Button, Link } from 'miniruche/ui';

function updateIsPaused(state, uuid, isPaused) {
    return {
        ...state,
        assemblies: state.assemblies.map(assembly => {
            if (assembly.uuid === uuid) {
                return {
                    ...assembly,
                    isPaused,
                };
            }
            return assembly;
        }),
    };
}

export class Assemblies extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        i18n: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        miniRuche: {},
        status: INITIAL_LOADING,
        isSearchVisible: false,
        searchResults: [],
    };

    componentDidMount() {
        const miniRucheRequest = get(`miniruche/admin/${this.props.slug}`);
        const assembliesRequest = get(`miniruche/admin/${this.props.slug}/assemblies`);
        Promise.all([miniRucheRequest, assembliesRequest]).then(this.onDataLoaded);
    }

    onDataLoaded = ([miniRuche, assemblies]) => {
        const { latitude, longitude } = miniRuche.meetingPoint.coordinates;
        get(
            `miniruche/assemblies/find/${latitude || 48.85661400000001},${longitude ||
                48.85661400000001}`
        ).then(this.onNearestAssembliesUpdate);

        this.setState({
            assemblies,
            assembliesAlreadyAdded: R.indexBy(R.prop('uuid'), assemblies),
            assembliesCurrentlyAdded: {},
        });
    };

    onNearestAssembliesUpdate = nearestAssemblies => {
        this.setState({
            nearestAssemblies,
            status: READY,
        });
    };

    showSearch = () => {
        this.setState({
            isSearchVisible: true,
        });
    };

    showResults = searchResults => {
        this.setState({
            searchResults,
        });
    };

    addAssembly = assemblyUuid => {
        const searchResultAssemblyIndex = R.findIndex(
            R.propEq('uuid', assemblyUuid),
            this.state.searchResults
        );
        const nearbyAssemblyIndex = R.findIndex(
            R.propEq('uuid', assemblyUuid),
            this.state.nearestAssemblies
        );
        this.setState(previousState => {
            return {
                assembliesCurrentlyAdded: {
                    ...previousState.assembliesCurrentlyAdded,
                    [assemblyUuid]: true,
                },
            };
        });
        patch(`miniruche/${this.props.slug}/assemblies`, {
            assemblies: R.pluck('uuid', this.state.assemblies).concat(assemblyUuid),
        }).then(
            () => {
                get(`miniruche/admin/${this.props.slug}/assemblies`).then(assemblies => {
                    this.setState({ assemblies });
                });
            },
            () => {
                this.setState(previousState => {
                    return {
                        assembliesCurrentlyAdded: {
                            ...previousState.assembliesCurrentlyAdded,
                            [assemblyUuid]: false,
                        },
                    };
                });
            }
        );
    };

    showModal = assemblyUuid => {
        const searchResultAssemblyIndex = R.findIndex(
            R.propEq('uuid', assemblyUuid),
            this.state.searchResults
        );
        if (searchResultAssemblyIndex !== -1) {
            return this.setState({
                assemblyModal: this.state.searchResults[searchResultAssemblyIndex],
            });
        }
        const assemblyIndex = R.findIndex(
            R.propEq('uuid', assemblyUuid),
            this.state.nearestAssemblies
        );
        return this.setState({
            assemblyModal: this.state.nearestAssemblies[assemblyIndex],
        });
    };

    hideModal = () => {
        this.setState({
            assemblyModal: null,
        });
    };

    pause = uuid => {
        this.setState(previousState => updateIsPaused(previousState, uuid, true));
        post(`miniruche/${this.props.slug}/assembly/${uuid}/pause`).catch(() => {
            this.setState(previousState => updateIsPaused(previousState, uuid, false));
        });
    };

    resume = uuid => {
        this.setState(previousState => updateIsPaused(previousState, uuid, false));
        post(`miniruche/${this.props.slug}/assembly/${uuid}/resume`).catch(() => {
            this.setState(previousState => updateIsPaused(previousState, uuid, true));
        });
    };

    deleteAssembly = assemblyUuid => {
        const assemblies = this.state.assemblies.filter(assembly => assembly.uuid !== assemblyUuid);
        patch(`miniruche/${this.props.slug}/assemblies`, {
            assemblies: R.pluck('uuid', assemblies),
        }).then(() => {
            this.setState(previousState => {
                return {
                    assemblies: previousState.assemblies.filter(
                        assembly => assembly.uuid !== assemblyUuid
                    ),
                    assembliesCurrentlyAdded: {
                        ...previousState.assembliesCurrentlyAdded,
                        [assemblyUuid]: false,
                    },
                };
            });
        });
    };

    render() {
        const { status, assemblies, nearestAssemblies } = this.state;

        const { i18n, trans, slug } = this.props;

        if (status === INITIAL_LOADING) {
            return <Page blue />;
        }

        return (
            <Page blue>
                <PageHeader
                    title={trans('miniruche.admin.assemblies.manage')}
                    backHref={`/${slug}/admin/sales`}
                    backText={trans('miniruche.admin.assemblies.backToDashboard')}
                    className="u-spacing-stack-xl"
                />
                <Section noPadding>
                    {nearestAssemblies
                        .sort((a, b) => {
                            if (a.hasOpenSale === b.hasOpenSale) {
                                return a.distance - b.distance;
                            }
                            return a.hasOpenSale ? -1 : 1;
                        })
                        .filter(assembly => {
                            return !this.state.assembliesAlreadyAdded[assembly.uuid];
                        })
                        .map(assembly => {
                            return (
                                <AssemblyBox
                                    key={assembly.uuid}
                                    assembly={assembly}
                                    showModal={this.showModal}
                                    addAssembly={this.addAssembly}
                                    removeAssembly={this.deleteAssembly}
                                    added={!!this.state.assembliesCurrentlyAdded[assembly.uuid]}
                                    className="u-spacing-stack-m"
                                />
                            );
                        })}
                </Section>
                <Section>
                    <div className="u-center u-spacing-stack-l">
                        {trans('global.or')}{' '}
                        <Link onClick={this.showSearch}>
                            {trans('miniruche.assemblies.search')}
                        </Link>
                    </div>
                    {this.state.isSearchVisible && (
                        <AssembliesSearch
                            searchResults={this.state.searchResults}
                            showResults={this.showResults}
                            showModal={this.showModal}
                            addAssembly={this.addAssembly}
                            deleteAssembly={this.deleteAssembly}
                            assembliesCurrentlyAdded={this.state.assembliesCurrentlyAdded}
                        />
                    )}
                </Section>
                <Section alternate>
                    <Heading rank={2} size="medium" className="u-spacing-stack-s">
                        {trans('menu.hives')}
                    </Heading>
                    <Text className="u-spacing-stack-m">
                        {trans('miniruche.admin.assemblies.text')}
                    </Text>
                    <Stack gutter="s" className="u-spacing-stack-m">
                        {assemblies
                            .sort((a, b) => a.timeslots[0].day - b.timeslots[0].day)
                            .map(assembly => {
                                return (
                                    <Box
                                        key={assembly.uuid}
                                        type={assembly.isPaused ? 'warning' : null}
                                    >
                                        <Split align="center" gutter="medium">
                                            <Grow weight={1}>
                                                <Heading
                                                    rank={3}
                                                    size="small"
                                                    className="u-spacing-stack-xs"
                                                >
                                                    {assembly.name}
                                                </Heading>
                                                <Text>
                                                    {trans(
                                                        'miniruche.assembly.distributionTimeWithDay',
                                                        {
                                                            '%day%': capitalizeFirstLetter(
                                                                getLocalizedDay(
                                                                    i18n,
                                                                    assembly.timeslots[0].day
                                                                )
                                                            ),
                                                            '%start%': getLocalizedTime(
                                                                i18n,
                                                                assembly.timeslots[0].starting
                                                            ),
                                                            '%end%': getLocalizedTime(
                                                                i18n,
                                                                assembly.timeslots[0].ending
                                                            ),
                                                        }
                                                    )}
                                                </Text>
                                            </Grow>
                                            <Grow weight={0}>
                                                {assembly.isPaused ? (
                                                    <Button
                                                        small
                                                        icon="play"
                                                        onClick={() => this.resume(assembly.uuid)}
                                                    />
                                                ) : (
                                                    <Button
                                                        small
                                                        variant="warning"
                                                        icon="pause"
                                                        onClick={() => this.pause(assembly.uuid)}
                                                    />
                                                )}
                                            </Grow>
                                        </Split>
                                    </Box>
                                );
                            })}
                    </Stack>
                </Section>
                {this.state.assemblyModal && (
                    <AssemblyModal assembly={this.state.assemblyModal} hideModal={this.hideModal} />
                )}
            </Page>
        );
    }
}

export default connectToI18n(({ match, i18n, trans }) => (
    <Assemblies i18n={i18n} trans={trans} slug={match.params.slug} />
));
