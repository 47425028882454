import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Section, Page, Split, Grow } from 'miniruche/ui';
import PageSections from './PageSections.jsx';
import Layout from './Layout.jsx';
import Actions from './Actions.jsx';
import Forms from './Forms.jsx';
import Content from './Content.jsx';

class Examples extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blue: false,
        };
        this.toggleBackground = this.toggleBackground.bind(this);
    }

    toggleBackground(e) {
        const isBlue = e.target.checked;
        this.setState({
            blue: isBlue,
        });
    }

    render() {
        return (
            <Page blue={!this.state.blue}>
                <Split>
                    <Grow weight={1}>
                        <Section>
                            <div className="u-spacing-stack-m">
                                <input
                                    type="checkbox"
                                    checked={this.state.blue}
                                    id="color-checkbox"
                                    onChange={this.toggleBackground}
                                />{' '}
                                <label htmlFor="color-checkbox">Blue background</label>
                            </div>
                            <div className="u-spacing-stack-s">
                                <Link to="/examples/page-sections">Page Sections</Link>
                            </div>
                            <div className="u-spacing-stack-s">
                                <Link to="/examples/layout">Layout</Link>
                            </div>
                            <div className="u-spacing-stack-s">
                                <Link to="/examples/actions">Actions</Link>
                            </div>
                            <div className="u-spacing-stack-s">
                                <Link to="/examples/forms">Forms</Link>
                            </div>
                            <div className="u-spacing-stack-s">
                                <Link to="/examples/content">Content</Link>
                            </div>
                        </Section>
                    </Grow>
                    <Grow weight={5}>
                        <Page blue={this.state.blue}>
                            <Switch>
                                <Route path="/examples/page-sections" component={PageSections} />
                                <Route path="/examples/layout" component={Layout} />
                                <Route path="/examples/actions" component={Actions} />
                                <Route path="/examples/forms" component={Forms} />
                                <Route path="/examples/content" component={Content} />
                            </Switch>
                        </Page>
                    </Grow>
                </Split>
            </Page>
        );
    }
}

export default Examples;
