import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { get, post } from 'miniruche/services/api';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import Sale from 'miniruche/pages/admin/sales/Sale.jsx';
import { Page } from 'miniruche/ui';

export class SaleContainer extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        saleId: PropTypes.number.isRequired,
    };

    state = {
        miniRuche: {},
        status: INITIAL_LOADING,
        isConveyorsModalVisible: false,
    };

    componentDidMount() {
        const saleRequest = get(`miniruche/admin/${this.props.slug}/sales/${this.props.saleId}`);
        const userRequest = get('me/');
        Promise.all([saleRequest, userRequest]).then(this.onDataLoaded);
    }

    onDataLoaded = ([sale, user]) => {
        const potentialConveyors = R.map(participant => {
            return {
                uuid: participant.uuid,
                name: participant.fullName,
            };
        }, sale.participantsWhoOrdered);

        if (R.none(R.propEq('uuid', user.uuid), potentialConveyors)) {
            potentialConveyors.unshift({
                uuid: user.uuid,
                name: `${user.firstName} ${user.lastName}`,
            });
        }

        this.setState({
            sale,
            potentialConveyors,
            user,
            status: READY,
        });
    };

    showConveyorsModal = () => {
        this.setState({
            isConveyorsModalVisible: true,
        });
    };

    closeConveyorsModal = () => {
        this.setState({
            isConveyorsModalVisible: false,
        });
    };

    setConveyors = conveyors => {
        let previousConveyors;
        this.setState(previousState => {
            previousConveyors = previousState.sale.conveyors.slice();
            return {
                sale: {
                    ...previousState.sale,
                    conveyors,
                },
            };
        });
        return post(`miniruche/${this.props.slug}/sale/${this.props.saleId}/conveyors`, {
            conveyors: R.pluck('uuid', conveyors),
        }).catch(() => {
            this.setState(previousState => {
                return {
                    sale: {
                        ...previousState.sale,
                        conveyors: previousConveyors,
                    },
                };
            });
        });
    };

    addConveyor = conveyor => {
        const conveyors = R.compose(
            R.uniqBy(R.propEq('uuid')),
            R.append(conveyor)
        )(this.state.sale.conveyors);

        this.setConveyors(conveyors);
    };

    removeConveyor = conveyor => {
        const conveyors = R.reject(R.propEq('uuid', conveyor.uuid), this.state.sale.conveyors);

        if (conveyors.length === 0) {
            return;
        }

        this.setConveyors(conveyors);
    };

    togglePause = () => {
        const isPaused = !this.state.sale.isPaused;
        post(
            `miniruche/${this.props.slug}/sale/${this.props.saleId}/${
                isPaused ? 'pause' : 'resume'
            }`
        ).then(
            this.setState(previousState => {
                return {
                    sale: {
                        ...previousState.sale,
                        isPaused,
                    },
                };
            })
        );
    };

    render() {
        const { status, potentialConveyors, sale, isConveyorsModalVisible } = this.state;

        const { slug } = this.props;

        if (status === INITIAL_LOADING) {
            return <Page blue />;
        }

        return (
            <Sale
                slug={slug}
                status={status}
                potentialConveyors={potentialConveyors}
                sale={sale}
                isConveyorsModalVisible={isConveyorsModalVisible}
                addConveyor={this.addConveyor}
                removeConveyor={this.removeConveyor}
                togglePause={this.togglePause}
                showConveyorsModal={this.showConveyorsModal}
                closeConveyorsModal={this.closeConveyorsModal}
            />
        );
    }
}

export default connectToI18n(({ match, i18n, trans }) => (
    <SaleContainer
        i18n={i18n}
        trans={trans}
        slug={match.params.slug}
        saleId={parseInt(match.params.saleId, 10)}
    />
));
