import Globalize from 'globalize';
import './globalize.culture.fr-FR';
Globalize.addCultureInfo('fr', 'fr-FR');

import moment from 'moment';
import 'moment/locale/fr';
moment.defineLocale('fr-FR', { parentLocale: 'fr' });

export default {
    code: 'fr-FR',

    // bcode is here for the "backCode" ... the code as seen/sent by the back :O
    bCode: 'fr_FR',

    name: 'Français',
    shortcut: 'fr',
    supportEmail: 'support@lrqdo.fr',
    privacyEmail: 'privacy@lrqdo.fr',

    appStores: {
        apple: 'https://itunes.apple.com/fr/app/la-ruche-qui-dit-oui/id1052198033?mt=8',
        google: 'https://play.google.com/store/apps/details?id=com.lrqdo&hl=fr',
    },

    trainingSite: {
        url: 'http://formation.laruchequiditoui.fr',
        password: 'formation-rr-réseau',
    },

    hostEssential: {
        url: 'https://laruchequiditoui.zendesk.com/hc/fr/articles/360003047154',
    },

    prodEssential: {
        url: 'https://support.laruchequiditoui.fr/hc/fr/articles/360018660999',
    },

    supportSite: {
        // Do not use support.laruchequiditoui.fr (yet ?)
        // it does not support https and we need https
        widgetDomain: 'laruchequiditoui.zendesk.com',
        homepage: 'http://support.laruchequiditoui.fr/hc/fr',
        host:
            'http://support.laruchequiditoui.fr/hc/fr/categories/200133228-Le-Coin-des-Responsables-de-Ruche',
        farmer: 'https://support.laruchequiditoui.fr/hc/fr/categories/5495742283410',
        member: 'http://support.laruchequiditoui.fr/hc/fr/categories/200133208-Le-coin-des-membres',
        user: 'http://support.laruchequiditoui.fr/hc/fr/categories/200133208-Le-coin-des-membres',
        kycInformation:
            'http://support.laruchequiditoui.fr/hc/fr/articles/218582808-Informations-suppl%C3%A9mentaires-requises-pour-le-paiement',
        proImprovementsHost:
            'https://support.laruchequiditoui.fr/hc/fr/articles/218582808-Informations-suppl%C3%A9mentaires-requises-pour-le-paiement',
        proImprovementsProd:
            'https://support.laruchequiditoui.fr/hc/fr/articles/218582808-Informations-suppl%C3%A9mentaires-requises-pour-le-paiement',
        farmerOrderMinimum: 'http://support.laruchequiditoui.fr/hc/fr/articles/115000669927',
        farmerOrderMinimumHowToChange:
            'http://support.laruchequiditoui.fr/hc/fr/articles/235538808',
        farmerInterregionalCatalog:
            'https://support.laruchequiditoui.fr/hc/fr/articles/115002918373',
        hostShippingInformationIntraCommunityVat:
            'https://laruchequiditoui.zendesk.com/hc/fr/articles/360002471674',
        producerShippingInformationIntraCommunityVat:
            'https://support.laruchequiditoui.fr/hc/fr/articles/360011464974-Ventes-transfrontali%C3%A8res-D%C3%A9clarer-la-TVA',
    },

    links: {
        about: 'https://nous.laruchequiditoui.fr/',
        press: 'http://ressources.laruchequiditoui.fr/presse',
        joinUs: 'https://nous.laruchequiditoui.fr/notre-equipe/',
        order: 'https://laruchequiditoui.fr/fr/assemblies',
        miniruche: 'http://miniruche.com/',
        giftCards: 'https://laruchequiditoui.fr/fr/giftcards',
        CSE: 'https://offrecse.laruchequiditoui.fr/',
        openRuche: 'https://ouvrir-une-ruche.laruchequiditoui.fr/',
        provideRuche: 'https://laruchequiditoui.fr/fr/p/provide',
        ourDatas: 'https://nous.laruchequiditoui.fr/vos-donnees/',
        proposeLocal: '',
    },

    logos: {
        brown: '/assets/images/logos/brown/logo-fr.svg',
        turquoise: '/assets/images/logos/turquoise/logo-fr.svg',
        white: '/assets/images/logos/white/logo-fr.svg',
        whiteOutlined: '/assets/images/logos/whiteOutlined/logo-fr.svg',
        blue: '/assets/images/logos/blue/logo-fr.svg',
        christmas: '/assets/images/logos/christmas/logo-fr.svg',
    },

    social: {
        facebook: 'https://www.facebook.com/laruchequiditoui',
        twitter: 'https://twitter.com/ruchequiditoui',
        blog: 'https://magazine.laruchequiditoui.fr/',
        instagram: 'https://instagram.com/laruchequiditoui',
        youtube: 'https://www.youtube.com/user/LaRucheQuiDitOui/',
        linkedin: 'https://www.linkedin.com/company/la-ruche-qui-dit-oui-',
    },

    proposeLocalForm:
        'https://docs.google.com/forms/d/e/1FAIpQLSf3MiBWJyEBf_rdQbhQ9nSayIEVZfF5UELFORGANZDm6W_E4g/viewform',

    dateFormats: {
        d: 'DD/MM/YYYY',
        D: 'dddd DD MMMM YYYY',
        I: 'dddd DD MMMM',
        i: 'DD MMMM YYYY',
        t: 'HH:mm',
        T: 'HH:mm:ss',
        f: 'dddd DD MMMM YYYY HH:mm',
        F: 'dddd DD MMMM YYYY HH:mm:ss',
        M: 'DD MMMM',
        Y: 'MMMM YYYY',
        shortDayDate: 'ddd DD MMMM',
        dateWithoutYear: 'DD/MM',
    },

    inlineLogo: require('./logo.svg'),
    zendeskLocale: 'fr',
    zendeskSearch: 'https://laruchequiditoui.zendesk.com/api/v2/help_center/',
    zendeskCategories: {
        farmer: '5495742283410',
        member: '200133208',
        user: '200133208',
        host: '200133228,360001048374',
    },

    cguPage: 'https://laruchequiditoui.fr/fr/p/terms',
    homepage: 'https://laruchequiditoui.fr',
    onSiteSaleArticle: 'https://support.laruchequiditoui.fr/hc/fr/articles/360010298913',
    minirucheHelpCenter: 'https://miniruche.zendesk.com/hc/fr',
};
