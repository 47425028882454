const NON_BREAKING_SPACE = String.fromCharCode(160);

const valuesInMeter = {
    m: 1,
    km: 1000,
    mile: 1609.34,
    miles: 1609.34,
};

const toLocaleStringSupportsLocales = (() => {
    const number = 0;
    try {
        number.toLocaleString('i');
    } catch (e) {
        return e.name === 'RangeError';
    }
    return false;
})();

function formatNumber(number, locale) {
    if (toLocaleStringSupportsLocales) {
        return number.toLocaleString(locale, {
            maximumFractionDigits: 2,
        });
    }
    return number.toFixed();
}

export function convertDistance(distance, unit, targetUnit) {
    if (!valuesInMeter[unit]) {
        throw new Error('Unit is not supported');
    }
    if (!valuesInMeter[targetUnit]) {
        throw new Error('targetUnit is not supported');
    }
    return distance * (valuesInMeter[unit] / valuesInMeter[targetUnit]);
}

export function formatDistance(distance, unit, locale) {
    if (locale === 'en-GB') {
        const distanceInMiles = convertDistance(distance, unit, 'miles').toLocaleString();
        return `${formatNumber(distanceInMiles, locale)}${NON_BREAKING_SPACE}${
            distanceInMiles > 1 ? 'miles' : 'mile'
        }`;
    }

    const distanceInMeters = convertDistance(distance, unit, 'm');

    if (distanceInMeters < 1000) {
        return `${formatNumber(distanceInMeters, locale)}${NON_BREAKING_SPACE}m`;
    }

    const distanceInKilometers = convertDistance(distance, unit, 'km');

    return `${formatNumber(distanceInKilometers, locale)}${NON_BREAKING_SPACE}km`;
}
