import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import Grow from 'miniruche/ui/layout/Grow.jsx';
import Split from 'miniruche/ui/layout/Split.jsx';
import Text from 'miniruche/ui/content/Text.jsx';
import TimeInput from './TimeInput.jsx';

export class TimerangeInput extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.object,
        onChange: PropTypes.func,
        intervalInMinutes: PropTypes.number,
        trans: PropTypes.func.isRequired,
    };

    static defaultProps = {
        onChange: () => {},
        intervalInMinutes: 30,
    };

    constructor(props) {
        super(props);
        if (this.props.value === undefined) {
            this.state = {
                isControlled: false,
                value: {
                    start: null,
                    end: null,
                },
            };
        } else {
            this.state = {
                isControlled: true,
            };
        }
    }

    onChange = (name, value) => {
        this.setState(
            state => {
                if (state.isControlled) {
                    return state;
                }
                return {
                    ...state,
                    value: {
                        ...state.value,
                        [name]: value,
                    },
                };
            },
            () => {
                this.props.onChange({
                    name: this.props.name,
                    value: {
                        ...(this.state.isControlled ? this.props.value : this.state.value),
                        [name]: value,
                    },
                });
            }
        );
    };

    onStartChange = e => {
        this.onChange('start', e.target.value);
    };

    onEndChange = e => {
        this.onChange('end', e.target.value);
    };

    render() {
        const { isControlled } = this.state;

        const { intervalInMinutes, trans } = this.props;

        return (
            <Split align="center">
                <Grow weight={3}>
                    <TimeInput
                        value={isControlled ? this.props.value.start : this.state.value.start}
                        onChange={this.onStartChange}
                        intervalInMinutes={intervalInMinutes}
                    />
                </Grow>
                <Grow weight={1}>
                    <Text className="u-center">{trans('global.to')}</Text>
                </Grow>
                <Grow weight={3}>
                    <TimeInput
                        value={isControlled ? this.props.value.end : this.state.value.end}
                        onChange={this.onEndChange}
                        intervalInMinutes={intervalInMinutes}
                    />
                </Grow>
            </Split>
        );
    }
}

export default connectToI18n(TimerangeInput);
