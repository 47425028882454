import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getFeaturedProducts } from 'miniruche/services/product/product.service';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import { capitalizeFirstLetter } from 'modules/utils/string';
import { Text, Button, Heading } from 'miniruche/ui';
import RemainingDays from 'miniruche/components/sale/RemainingDays.jsx';
import FeaturedProducts from './FeaturedProducts.jsx';

export class FeaturedSaleSummary extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        sale: PropTypes.object.isRequired,
        i18n: PropTypes.string.isRequired,
        className: PropTypes.string,
        onClick: PropTypes.func,
        trans: PropTypes.func.isRequired,
    };

    state = {
        products: [],
        productStatus: INITIAL_LOADING,
    };

    componentDidMount() {
        getFeaturedProducts({ slug: this.props.slug, saleId: this.props.sale.id }, 10).then(
            products => {
                this.setState({
                    products,
                    productStatus: READY,
                });
            }
        );
    }

    render() {
        const { slug, sale, i18n, className, onClick, trans } = this.props;

        const saleUrl = `/${slug}/sales/${sale.id}`;

        return (
            <div className={className}>
                <Heading rank={2} className="u-spacing-stack-xxs" size="large">
                    {capitalizeFirstLetter(getLocalizedDate(i18n, 'I', sale.date))}
                </Heading>
                <Text className="u-spacing-stack-xxs" size="small">
                    {sale.assemblyName}
                </Text>
                <Text type="success" className="u-spacing-stack-l">
                    <RemainingDays saleEndDate={sale.saleEndDate} />
                </Text>
                <Button
                    to={saleUrl}
                    onClick={onClick}
                    block
                    variant="primary"
                    className="u-spacing-stack-l"
                >
                    {trans('distributions.chooseProducts')}
                </Button>
                <Heading rank={4} className="u-spacing-stack-s" size="small">
                    {trans('miniruche.sales.productsOfTheWeek')}
                </Heading>
                <FeaturedProducts
                    products={this.state.products}
                    isReady={this.state.productStatus === READY}
                    saleUrl={saleUrl}
                />
            </div>
        );
    }
}

export default connectToI18n(FeaturedSaleSummary);
