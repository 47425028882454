import React from 'react';
import { Section, Heading, Text, Box, Icon, Tag, Progress } from 'miniruche/ui';

class Content extends React.Component {
    render() {
        return (
            <div>
                <Section>
                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                        Typography
                    </Heading>
                    <Heading rank={2} size="medium" className="u-spacing-stack-m">
                        Heading sizes
                    </Heading>
                    <Box className="u-spacing-stack-xl">
                        <Heading rank={1} size="large" className="u-spacing-stack-m">
                            Large Heading
                        </Heading>
                        <Heading rank={1} size="medium" className="u-spacing-stack-m">
                            Medium heading
                        </Heading>
                        <Heading rank={1} size="small">
                            Small heading
                        </Heading>
                    </Box>
                    <Heading rank={2} size="medium" className="u-spacing-stack-m">
                        Text sizes
                    </Heading>
                    <div className="u-spacing-stack-xl">
                        <Text size="large" className="u-spacing-stack-m">
                            Large text. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Pellentesque feugiat tellus ac eros ornare venenatis. Integer a tellus a
                            nisl egestas posuere quis eget sem. Integer vitae diam sapien. Aliquam
                            sodales lectus ac magna sodales, eu dictum mauris bibendum. Phasellus
                            dictum eget dui et semper. Etiam auctor tempus arcu, et viverra ipsum
                            commodo nec. Proin ut fringilla massa. In est ligula, tristique a justo
                            eget, bibendum tincidunt eros. Nunc nec varius arcu.
                        </Text>
                        <Text size="medium" className="u-spacing-stack-m">
                            Medium text. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Pellentesque feugiat tellus ac eros ornare venenatis. Integer a tellus a
                            nisl egestas posuere quis eget sem. Integer vitae diam sapien. Aliquam
                            sodales lectus ac magna sodales, eu dictum mauris bibendum. Phasellus
                            dictum eget dui et semper. Etiam auctor tempus arcu, et viverra ipsum
                            commodo nec. Proin ut fringilla massa. In est ligula, tristique a justo
                            eget, bibendum tincidunt eros. Nunc nec varius arcu.
                        </Text>
                        <Text size="small">
                            Small text. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Pellentesque feugiat tellus ac eros ornare venenatis. Integer a tellus a
                            nisl egestas posuere quis eget sem. Integer vitae diam sapien. Aliquam
                            sodales lectus ac magna sodales, eu dictum mauris bibendum. Phasellus
                            dictum eget dui et semper. Etiam auctor tempus arcu, et viverra ipsum
                            commodo nec. Proin ut fringilla massa. In est ligula, tristique a justo
                            eget, bibendum tincidunt eros. Nunc nec varius arcu.
                        </Text>
                    </div>
                    <Heading rank={2} size="medium" className="u-spacing-stack-m">
                        Text variants
                    </Heading>
                    <div className="u-spacing-stack-xl">
                        <Text type="success" className="u-spacing-stack-m">
                            A "success" text.
                        </Text>
                        <Text type="warning" className="u-spacing-stack-m">
                            A "warning" text.
                        </Text>
                        <Text type="highlight" className="u-spacing-stack-m">
                            A "highlight" text.
                        </Text>
                        <Text strong className="u-spacing-stack-m">
                            A strong text.
                        </Text>
                        <Text linethrough>A linethrough text.</Text>
                    </div>
                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                        Icons
                    </Heading>
                    <Heading rank={2} size="medium" className="u-spacing-stack-m">
                        Icon types
                    </Heading>
                    <div className="u-spacing-stack-xl">
                        <p className="u-spacing-stack-s">
                            <Icon type="angle-left" /> angle-left
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="angle-right" /> angle-right
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="angle-up" /> angle-up
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="angle-down" /> angle-down
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="ok" /> ok
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="heart" /> heart
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="heart-on" /> heart-on
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="basket" /> basket
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="lock" /> lock
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="close-thin" /> close-thin
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="share-facebook" /> share-facebook
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="share-sms" /> share-sms
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="share-email" /> share-email
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="share-whatsapp" /> share-whatsapp
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="share-messenger" /> share-messenger
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="plus" /> plus
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="minus" /> minus
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="fresh" /> fresh
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="fragile" /> fragile
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="pause" /> pause
                        </p>
                        <p className="u-spacing-stack-s">
                            <Icon type="play" /> play
                        </p>
                    </div>
                    <Heading rank={2} size="medium" className="u-spacing-stack-m">
                        Icon sizes
                    </Heading>
                    <div className="u-spacing-stack-xl">
                        <Icon type="angle-left" size="xs" />
                        <Icon type="angle-left" size="s" />
                        <Icon type="angle-left" size="m" />
                        <Icon type="angle-left" size="l" />
                        <Icon type="angle-left" size="xl" />
                    </div>
                    <Heading rank={2} size="medium" className="u-spacing-stack-m">
                        Icon colors
                    </Heading>
                    <div className="u-spacing-stack-xl">
                        <Icon type="angle-left" color="white" />
                        <Icon type="angle-left" color="yellow" />
                        <Icon type="angle-left" color="blue" />
                    </div>
                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                        Tags
                    </Heading>
                    <div className="u-spacing-stack-xl">
                        <div className="u-spacing-stack-m">
                            <Tag>A tag</Tag>
                        </div>
                        <div className="u-spacing-stack-m">
                            <Tag type="warning">A warning tag</Tag>
                        </div>
                        <div>
                            <Tag block>A block tag</Tag>
                        </div>
                    </div>
                    <Heading rank={1} size="large" className="u-spacing-stack-m">
                        Progress
                    </Heading>
                    <div className="u-spacing-stack-m">
                        <Text className="u-spacing-inset-xs">-50 / 200</Text>
                        <Progress className="u-spacing-stack-m" value={-50} max={200} />
                        <Text className="u-spacing-inset-xs">0 / 200</Text>
                        <Progress className="u-spacing-stack-m" value={0} max={200} />
                        <Text className="u-spacing-inset-xs">1 / 200</Text>
                        <Progress className="u-spacing-stack-m" value={1} max={200} />
                        <Text className="u-spacing-inset-xs">120 / 200</Text>
                        <Progress className="u-spacing-stack-m" value={120} max={200} />
                        <Text className="u-spacing-inset-xs">199 / 200</Text>
                        <Progress className="u-spacing-stack-m" value={199} max={200} />
                        <Text className="u-spacing-inset-xs">200 / 200</Text>
                        <Progress className="u-spacing-stack-m" value={200} max={200} />
                        <Text className="u-spacing-inset-xs">400 / 200</Text>
                        <Progress value={400} max={200} />
                    </div>
                </Section>
            </div>
        );
    }
}

export default Content;
