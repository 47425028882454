import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import superToggle from 'miniruche/hoc/superToggle';
import Price from 'components/Price.jsx';
import classnames from 'classnames';
import { Text, Button, CheckButton, Split, Grow, Box } from 'miniruche/ui';

export class Conveyor extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        participant: PropTypes.object.isRequired,
        isConveyor: PropTypes.bool.isRequired,
        share: PropTypes.object.isRequired,
        toggle: PropTypes.func.isRequired,
    };

    toggleConveyor = () => {
        this.props.toggle('isConveyor');
    };

    render() {
        const { className, participant, isConveyor, share } = this.props;

        return (
            <Box
                type={isConveyor ? 'success' : null}
                className={classnames('u-clickable', className)}
                onClick={this.toggleConveyor}
            >
                <Split gutter="medium" align="center">
                    <Grow weight={0}>
                        <Button small icon="ok" />
                    </Grow>
                    <Grow weight={1}>
                        <Text>{participant.name}</Text>
                        <Text>{isConveyor ? <Price price={share} /> : '-'}</Text>
                    </Grow>
                </Split>
            </Box>
        );
    }
}

export default superToggle(connectToI18n(Conveyor));
