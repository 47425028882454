import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Page, Section, Heading, Text, Button, URLShare } from 'miniruche/ui';

export class Share extends React.Component {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const { slug, trans } = this.props;

        return (
            <Page blue>
                <Section small>
                    <Heading rank={1} size="large" className="u-spacing-stack-xl">
                        {trans('miniruche.admin.sales.invite')}
                    </Heading>
                    <Text className="u-spacing-stack-l">{trans('miniruche.create.shareText')}</Text>
                    <URLShare
                        className="u-spacing-stack-xl"
                        url={`https://miniruche.com/${this.props.slug}`}
                        messageTitle={trans('miniruche.admin.sales.inviteButtonTitle')}
                        messageContent={trans('miniruche.admin.sales.inviteButtonContent')}
                    />
                    <Button block variant="primary" to={`/${slug}/admin/sales`}>
                        {trans('global.seeDashboard')}
                    </Button>
                </Section>
            </Page>
        );
    }
}

export default connectToI18n(({ match, trans }) => (
    <Share slug={match.params.slug} trans={trans} />
));
