import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export class FullWidthBlock extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };

    render() {
        return (
            <div className={classnames('fullWidthBlock', this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}

export default FullWidthBlock;
