import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NukaCarousel from 'nuka-carousel';
import createResponsiveComponent from 'components/createResponsiveComponent';
import Button from 'miniruche/ui/actions/Button.jsx';

class Carousel extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        isSmallWidth: PropTypes.bool,
        slidesToShow: PropTypes.number,
        cellAlign: PropTypes.string,
    };

    static defaultProps = {
        cellAlign: 'center',
        slidesToShow: 1,
    };

    shouldComponentUpdate(nextProps) {
        return !R.equals(nextProps, this.props);
    }

    render() {
        const { children, className, isSmallWidth, slidesToShow, cellAlign, ...rest } = this.props;

        return (
            <div className={classnames(className, 'carousel')} {...rest}>
                <NukaCarousel
                    cellAlign={cellAlign}
                    cellSpacing={12}
                    slideWidth={isSmallWidth ? 0.8 : 1}
                    slidesToShow={slidesToShow}
                    slidesToScroll={slidesToShow}
                    renderCenterLeftControls={({ currentSlide, previousSlide }) => {
                        const disabled = currentSlide === 0;
                        return (
                            <Button
                                className="carousel-previousButton"
                                icon="angle-left"
                                onClick={disabled ? null : previousSlide}
                                disabled={disabled}
                            />
                        );
                    }}
                    renderCenterRightControls={({ currentSlide, slideCount, nextSlide }) => {
                        const disabled = currentSlide + slidesToShow >= slideCount;
                        return (
                            <Button
                                className="carousel-nextButton"
                                icon="angle-right"
                                onClick={disabled ? null : nextSlide}
                                disabled={disabled}
                            />
                        );
                    }}
                    renderBottomCenterControls={() => null}
                >
                    {children}
                </NukaCarousel>
            </div>
        );
    }
}

export default createResponsiveComponent(Carousel, { maxWidth: 636 });
