import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class FormInput extends React.Component {
    componentDidMount() {
        if (this.props.autofocus) {
            this.input.focus();
        }
    }

    render() {
        const { invalid, inverse, autofocus, ...rest } = this.props;

        const isInversed = inverse || this.context.inverse;

        return (
            <input
                ref={c => (this.input = c)}
                className={classnames(
                    'formInput',
                    { 'formInput--error': invalid },
                    { 'formInput--inverse': isInversed }
                )}
                {...rest}
            />
        );
    }
}

FormInput.propTypes = {
    invalid: PropTypes.bool,
    inverse: PropTypes.bool,
    autofocus: PropTypes.bool,
};

FormInput.defaultProps = {
    invalid: false,
};

FormInput.contextTypes = {
    inverse: PropTypes.bool,
};

export default FormInput;
