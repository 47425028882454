import * as R from 'ramda';
import { Translator } from 'modules/i18n';

const getTranslatedWeekdays = locale => {
    return [
        { value: 'mon', label: Translator.trans(locale, 'global.weekday.monday') },
        { value: 'tue', label: Translator.trans(locale, 'global.weekday.tuesday') },
        { value: 'wed', label: Translator.trans(locale, 'global.weekday.wednesday') },
        { value: 'thu', label: Translator.trans(locale, 'global.weekday.thursday') },
        { value: 'fri', label: Translator.trans(locale, 'global.weekday.friday') },
        { value: 'sat', label: Translator.trans(locale, 'global.weekday.saturday') },
        { value: 'sun', label: Translator.trans(locale, 'global.weekday.sunday') },
    ];
};

const dayName = (day, locale) =>
    R.prop('label', R.find(R.propEq('value', day), getTranslatedWeekdays(locale)));

const sortDays = locale =>
    R.sortBy(day => R.findIndex(R.propEq('value', day), getTranslatedWeekdays(locale)));

const extractAssemblies = R.pipe(R.indexBy(R.prop('dayOfWeek')), R.pluck('assemblies'));

const extractDays = (pickUpTimeSlots, locale) =>
    R.pipe(R.pluck('dayOfWeek'), sortDays(locale))(pickUpTimeSlots);

const extractTimeRanges = R.pipe(
    R.indexBy(R.prop('dayOfWeek')),
    R.map(timeslot => ({
        start: timeslot.timeStart,
        end: timeslot.timeEnd,
    }))
);

const extractTimeSlots = (miniruche, locale) => ({
    assemblies: extractAssemblies(miniruche.pickUpTimeSlots),
    days: extractDays(miniruche.pickUpTimeSlots, locale),
    timeRanges: extractTimeRanges(miniruche.pickUpTimeSlots),
});

const registerTimeSlot = (day, timeRange, assemblies, locale) =>
    R.evolve({
        assemblies: R.assoc(day, assemblies),
        days: R.pipe(R.union([day]), sortDays(locale)),
        timeRanges: R.assoc(day, timeRange),
    });

const unregisterTimeSlot = day =>
    R.evolve({
        assemblies: R.dissoc(day),
        days: R.without([day]),
        timeRanges: R.dissoc(day),
    });

export { dayName, extractTimeSlots, registerTimeSlot, unregisterTimeSlot, getTranslatedWeekdays };
