import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { debounce } from 'underscore';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Input } from 'miniruche/ui';
import { getAddressSuggestions, splitAddressComponents } from 'models/mapbox.js';

const isStreetAddressValid = R.where({
    street: R.complement(R.isNil),
    city: R.complement(R.isNil),
    zipCode: R.complement(R.isNil),
});

export class AddressAutocomplete extends React.Component {
    static propTypes = {
        i18n: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        allowCustomText: PropTypes.bool,
        onAddressChange: PropTypes.func.isRequired,
        placeholder: PropTypes.string,
        autofocus: PropTypes.bool,
        errors: PropTypes.array,
        label: PropTypes.string,
        className: PropTypes.string,
        suggestAllTypes: PropTypes.bool,
        onBlur: PropTypes.func,
        showErrors: PropTypes.bool,
    };

    static defaultProps = {
        allowCustomText: false,
        placeholder: null,
    };

    state = {
        suggestions: [],
    };

    onChange = suggestion => {
        const streetAddress = splitAddressComponents(suggestion);
        this.props.onAddressChange({
            address: suggestion.name,
            coordinates: {
                latitude: suggestion._geoloc.lat,
                longitude: suggestion._geoloc.lng,
            },
            country: suggestion.country,
            streetAddress: isStreetAddressValid(streetAddress) ? streetAddress : null,
        });
        this.setState({ suggestions: [] });
    };

    onCustomText = () => {
        this.setState({ suggestions: [] });
    };

    onInput = address => {
        this.props.onAddressChange({
            address,
            coordinates: null,
            streetAddress: null,
        });

        if (!address) {
            return this.setState({
                suggestions: [],
            });
        }

        return this._searchPlace(address);
    };

    _searchPlace = debounce(query => {
        const params = {
            query,
            hitsPerPage: 5,
            language: this.props.i18n.split('-')[0],
        };
        if (!this.props.suggestAllTypes) {
            params.type = 'address';
        }
        getAddressSuggestions(params)
            .then(hits => {
                const suggestions = hits.map(hit => ({
                    ...hit,
                    name: hit.place_name,
                }));
                this.setState({ suggestions });
            })
            .catch(() => {
                this.setState({ suggestions: [] });
            });
    }, 300);

    render() {
        const {
            address,
            allowCustomText,
            placeholder,
            autofocus,
            errors,
            label,
            className,
            onBlur,
            showErrors,
        } = this.props;

        return (
            <Input
                className={className}
                allowCustomText={allowCustomText}
                type="autocomplete"
                placeholder={placeholder}
                options={this.state.suggestions}
                onChange={this.onChange}
                onCustomText={this.onCustomText}
                onInput={this.onInput}
                value={address}
                autofocus={autofocus}
                errors={errors}
                label={label}
                onBlur={onBlur}
                showErrors={showErrors}
            />
        );
    }
}

export default connectToI18n(AddressAutocomplete);
