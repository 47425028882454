import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Price from 'components/Price.jsx';
import { getComputedFee } from 'miniruche/modules/shopping/store/basketSelectors';

export class BasketFee extends React.Component {
    static propTypes = {
        fee: PropTypes.object,
    };

    render() {
        const { fee } = this.props;

        if (!fee) {
            return null;
        }

        return <Price price={fee} />;
    }
}

function mapStateToProps(state) {
    return {
        fee: getComputedFee(state),
    };
}

export default connect(mapStateToProps)(BasketFee);
