import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Box extends React.Component {
    getChildContext() {
        return {
            inverse: this.props.type === 'success' || this.props.type === 'warning',
        };
    }

    render() {
        const { spacing, children, className, inverse, type, ...rest } = this.props;

        const isInversed = inverse || this.context.inverse;

        return (
            <div
                className={classnames(
                    'box',
                    `u-spacing-${spacing}`,
                    { 'box--inverse': isInversed },
                    { [`box--${type}`]: !!type },
                    className
                )}
                {...rest}
            >
                {children}
            </div>
        );
    }
}

Box.propTypes = {
    spacing: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    inverse: PropTypes.bool,
    type: PropTypes.string,
};

Box.defaultProps = {
    spacing: 'inset-m',
};

Box.contextTypes = {
    inverse: PropTypes.bool,
};

Box.childContextTypes = {
    inverse: PropTypes.bool,
};

export default Box;
