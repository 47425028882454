import React from 'react';
import { Heading, Text, Section } from 'miniruche/ui';

class PageSections extends React.Component {
    render() {
        return (
            <div>
                <Section>
                    <Heading rank={2} size="large">
                        Page sections
                    </Heading>
                </Section>
                <Section alternate>
                    <Text>An alternate page section.</Text>
                </Section>
                <Section>
                    <Text>A normal page section.</Text>
                </Section>
            </div>
        );
    }
}

export default PageSections;
