import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Input, FormErrors } from 'miniruche/ui';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
    }

    onEmailChange(event) {
        this.props.onChange('email', event.target.value);
    }

    onPasswordChange(event) {
        this.props.onChange('password', event.target.value);
    }

    render() {
        const { email, password, hideEmail, errors, className, trans } = this.props;

        return (
            <div className={className}>
                {!hideEmail && (
                    <Input
                        autofocus
                        label={trans('global.label.email')}
                        value={email}
                        onChange={this.onEmailChange}
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                    />
                )}
                <Input
                    autofocus={hideEmail}
                    type="password"
                    label={trans('global.password')}
                    value={password}
                    onChange={this.onPasswordChange}
                    errors={hideEmail ? errors : []}
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                />
                {!hideEmail && <FormErrors errors={errors} />}
            </div>
        );
    }
}

LoginForm.propTypes = {
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    hideEmail: PropTypes.bool,
    errors: PropTypes.array,
    className: PropTypes.string,
    trans: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
    hideEmail: false,
    errors: [],
};

export default connectToI18n(LoginForm);
