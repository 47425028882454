import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { addUniversalEvent } from 'modules/analytics/miniRucheGTM';
import { getItem } from 'miniruche/modules/shopping/store/basketSelectors';
import Price from 'components/Price.jsx';
import Quantity from 'components/Offers/Quantity.jsx';
import {
    BASKET_INCREMENT_QUANTITY,
    BASKET_DECREMENT_QUANTITY,
} from 'miniruche/modules/shopping/store/basketActionTypes';
import { Split, Grow, Button, Text } from 'miniruche/ui';

export class Offer extends PureComponent {
    onRemoveClicked = () => {
        addUniversalEvent({
            eventCategory: 'Sale',
            eventAction: 'offer_removed',
            eventLabel: this.props.offer.id,
            eventValue: this.props.offer.price.amount,
        });
        this.props.dispatch({
            type: BASKET_DECREMENT_QUANTITY,
            offer: this.props.offer,
        });
    };

    onAddClicked = () => {
        addUniversalEvent({
            eventCategory: 'Sale',
            eventAction: 'offer_added',
            eventLabel: this.props.offer.id,
            eventValue: this.props.offer.price.amount,
        });
        this.props.dispatch({
            type: BASKET_INCREMENT_QUANTITY,
            offer: this.props.offer,
        });
    };

    render() {
        const { offer, strategy, reverse, className, quantityInBasket } = this.props;

        return (
            <Split align="center" className={className}>
                <Grow weight={1}>
                    {reverse ? (
                        <div>
                            <Text className="u-spacing-stack-xxs">
                                <Quantity offer={offer} strategy={strategy} />
                            </Text>
                            <Text>
                                <Price price={offer.price} />
                            </Text>
                        </div>
                    ) : (
                        <div>
                            <Text className="u-spacing-stack-xxs">
                                <Price price={offer.price} />
                            </Text>
                            <Text>
                                <Quantity offer={offer} strategy={strategy} />
                            </Text>
                        </div>
                    )}
                </Grow>
                <Grow weight={0}>
                    {quantityInBasket ? (
                        <Split align="center">
                            <Grow weight={0}>
                                <Button
                                    icon="minus"
                                    small
                                    variant="outline"
                                    disabled={offer.locked}
                                    onClick={offer.locked ? null : this.onRemoveClicked}
                                />
                            </Grow>
                            <Grow weight={0}>
                                <div style={{ minWidth: '30px', textAlign: 'center' }}>
                                    <Text strong size="large">
                                        {quantityInBasket}
                                    </Text>
                                </div>
                            </Grow>
                            <Grow weight={0}>
                                <Button
                                    icon="plus"
                                    small
                                    variant="outline"
                                    disabled={offer.locked}
                                    onClick={offer.locked ? null : this.onAddClicked}
                                />
                            </Grow>
                        </Split>
                    ) : (
                        <Button
                            icon="plus"
                            small
                            variant="primary"
                            disabled={offer.locked}
                            onClick={offer.locked ? null : this.onAddClicked}
                        />
                    )}
                </Grow>
            </Split>
        );
    }
}

Offer.propTypes = {
    offer: PropTypes.object.isRequired,
    strategy: PropTypes.string.isRequired,
    reverse: PropTypes.bool.isRequired,
    quantityInBasket: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    className: PropTypes.string,
};

Offer.defaultProps = {
    horizontal: false,
    disabled: false,
};

function mapStateToProps(state, props) {
    return {
        quantityInBasket: getItem(state, {
            slug: props.slug,
            saleId: props.saleId,
            offerId: props.offer.id,
        }),
    };
}

export default connect(mapStateToProps)(connectToI18n(Offer));
