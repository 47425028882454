import React from 'react';
import PropTypes from 'prop-types';
import FormErrors from './FormErrors.jsx';
import FormField from './FormField.jsx';
import FormLabel from './FormLabel.jsx';
import InputSwitch from './InputSwitch.jsx';

class Input extends React.Component {
    render() {
        const { className, label, errors, type, only, showErrors, ...rest } = this.props;
        if (only) {
            return <InputSwitch type={type} {...rest} />;
        }

        return (
            <FormField className={className} invalid={errors.length > 0}>
                <FormLabel label={label} />
                <InputSwitch invalid={errors.length > 0} type={type} {...rest} />
                {showErrors && <FormErrors errors={errors} />}
            </FormField>
        );
    }
}

Input.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    errors: PropTypes.array,
    type: PropTypes.string,
    only: PropTypes.bool,
    showErrors: PropTypes.bool,
};

Input.defaultProps = {
    errors: [],
    options: [],
    type: 'text',
};

export default Input;
