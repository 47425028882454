import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { smsLink } from 'modules/utils/links';
import { Text, Split, Grow, Button } from 'miniruche/ui';

const nbsp = String.fromCharCode(160);

export class Participant extends React.Component {
    static propTypes = {
        participant: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const { participant, trans } = this.props;

        return (
            <div>
                <Split gutter="medium" align="center">
                    <Grow weight={1}>
                        <Text>{participant.fullName}</Text>
                        {participant.orderPickupConstraints && (
                            <Text>
                                {Math.max(
                                    Math.round(
                                        participant.orderPickupConstraints.totalWeightInMg / 1000000
                                    ),
                                    1
                                )}
                                {nbsp}
                                kg
                            </Text>
                        )}
                    </Grow>
                    <div className="u-hidden-mobile u-right">
                        <Button
                            variant="outline"
                            target="_blank"
                            href={`mailto:${participant.email}`}
                        >
                            {trans('miniruche.participant.sendmail')}
                        </Button>
                    </div>
                </Split>
                <div className="u-hidden-desktop" style={{ marginTop: '12px' }}>
                    <Split gutter="medium">
                        <Grow weight={1}>
                            <Button
                                block
                                variant="outline"
                                target="_blank"
                                href={smsLink(participant.phone)}
                            >
                                {trans('global.sms')}
                            </Button>
                        </Grow>
                        <Grow weight={1}>
                            <Button
                                block
                                variant="outline"
                                target="_blank"
                                href={`tel:${participant.phone}`}
                            >
                                {trans('miniruche.participant.call')}
                            </Button>
                        </Grow>
                    </Split>
                </div>
            </div>
        );
    }
}

export default connectToI18n(Participant);
