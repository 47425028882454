import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Banner extends React.Component {
    static propTypes = {
        color: PropTypes.string,
        children: PropTypes.node,
        small: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        color: 'white',
        small: false,
    };

    render() {
        const { children, color, small, className } = this.props;

        return (
            <div
                className={classnames(
                    'banner',
                    `banner--${color}`,
                    { 'banner--small': small },
                    className
                )}
            >
                <div className="banner-inner">{children}</div>
            </div>
        );
    }
}
