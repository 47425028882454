import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { lowerCaseAndUnaccent } from 'modules/utils/string';
import { getCountries } from 'miniruche/services/country/countries.service';
import { Input } from 'miniruche/ui';

const buildNationalitiesOptions = R.pipe(
    R.sortBy(R.pipe(R.prop('name'), lowerCaseAndUnaccent)),
    R.map(country => ({ value: country.code, label: country.name }))
);

class KycInfoForm extends React.Component {
    static propTypes = {
        birthday: PropTypes.object,
        className: PropTypes.string,
        errors: PropTypes.object,
        nationality: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
    };

    static defaultProps = {
        errors: {},
    };

    state = {
        nationalities: [],
    };

    componentDidMount() {
        getCountries().then(countries =>
            this.setState({
                nationalities: buildNationalitiesOptions(countries),
            })
        );
    }

    onBirthdayChange = value => {
        this.props.onChange('birthday', value);
    };

    onNationalityChange = event => {
        this.props.onChange('nationality', event.target.value);
    };

    render() {
        const { birthday, className, errors, nationality, trans } = this.props;

        const { nationalities } = this.state;

        return (
            <div className={className}>
                <Input
                    type="date"
                    label={trans('global.dateOfBirth')}
                    value={birthday}
                    onChange={this.onBirthdayChange}
                    errors={errors.birthday}
                />
                <Input
                    type="dropdown"
                    label={trans('global.nationality')}
                    placeholder={R.isEmpty(nationalities) ? '...' : ''}
                    disabled={R.isEmpty(nationalities)}
                    options={nationalities}
                    value={nationality}
                    onChange={this.onNationalityChange}
                    errors={errors.nationality}
                />
            </div>
        );
    }
}

export default connectToI18n(KycInfoForm);
