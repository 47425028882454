import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import Price from 'components/Price.jsx';
import { Split, Grow, Separator, Text } from 'miniruche/ui';

class SaleStats extends React.PureComponent {
    static propTypes = {
        sale: PropTypes.object.isRequired,
        className: PropTypes.string,
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
    };

    render() {
        const { sale, className, trans, transChoice } = this.props;

        return (
            <div className={className}>
                <Split className="u-spacing-stack-s">
                    <Grow weight={1}>
                        <Text>{transChoice('global.ordersPluralized', 1)}</Text>
                    </Grow>
                    <Grow weight={0}>
                        <Text className="u-right">
                            <Price price={sale.orderStats.purchasesTotal} />
                        </Text>
                    </Grow>
                </Split>
                <Separator className="u-spacing-stack-s" />
                <Split className="u-spacing-stack-s">
                    <Grow weight={1}>
                        <Text>{trans('miniruche.sale.stats.charges')}</Text>
                    </Grow>
                    <Grow weight={0}>
                        <Text className="u-right">
                            <Price price={sale.orderStats.feesTotal} />
                        </Text>
                    </Grow>
                </Split>
                <Separator />
            </div>
        );
    }
}

export default connectToI18n(SaleStats);
