import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { put } from 'miniruche/services/api';
import { Page, Section, Heading, Button, Input, Text, Link } from 'miniruche/ui';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.save = this.save.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.state = {
            password: '',
            status: 'ready',
        };
    }

    onPasswordChange(e) {
        this.setState({
            password: e.target.value,
        });
    }

    onSuccess() {
        this.props.history.push(`/login${this.props.location.search}`);
    }

    onError() {
        this.setState({
            status: 'error',
        });
    }

    save() {
        this.setState({
            status: 'ready',
        });
        put('miniruche/password_set/', {
            userId: this.props.userId,
            token: this.props.token,
            password: this.state.password,
        }).then(this.onSuccess, this.onError);
    }

    render() {
        const { trans } = this.props;

        return (
            <Page blue>
                <Section small>
                    <Heading rank={1} size="large" className="u-spacing-stack-l">
                        {trans('miniruche.login.changePassword.heading')}
                    </Heading>
                    {this.state.status === 'success' ? (
                        <Text>{trans('miniruche.login.changePassword.success')}</Text>
                    ) : (
                        <div>
                            <Input
                                autofocus
                                type="password"
                                className="u-spacing-stack-l"
                                label={trans('miniruche.login.changePassword.fillHere')}
                                value={this.state.password}
                                onChange={this.onPasswordChange}
                                errors={
                                    this.state.status === 'error'
                                        ? [
                                              <span>
                                                  {trans('miniruche.login.changePassword.expired')}{' '}
                                                  <Link to={`/forgot-password`}>
                                                      {trans(
                                                          'miniruche.login.changePassword.getNewEmail'
                                                      )}
                                                  </Link>
                                              </span>,
                                          ]
                                        : []
                                }
                            />
                            <Button block variant="primary" onClick={this.save}>
                                {trans('global.save')}
                            </Button>
                        </div>
                    )}
                </Section>
            </Page>
        );
    }
}

ChangePassword.propTypes = {
    trans: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default connectToI18n(({ location, history, match, trans }) => (
    <ChangePassword
        trans={trans}
        userId={match.params.userId}
        token={match.params.token}
        history={history}
        location={location}
    />
));
