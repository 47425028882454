import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from './Button.jsx';

class CheckButton extends React.Component {
    render() {
        const { className, inverse, checked, ...rest } = this.props;

        const isInversed = inverse || this.context.inverse;

        return (
            <Button
                icon="ok"
                className={classnames(
                    'checkButton',
                    { 'checkButton--inverse': isInversed },
                    { 'checkButton--checked': checked },
                    className
                )}
                {...rest}
            />
        );
    }
}

CheckButton.propTypes = {
    className: PropTypes.string,
    inverse: PropTypes.bool,
    checked: PropTypes.bool,
};

CheckButton.defaultProps = {
    checked: false,
};

CheckButton.contextTypes = {
    inverse: PropTypes.bool,
};

export default CheckButton;
