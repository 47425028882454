import React from 'react';
import PropTypes from 'prop-types';

class Stack extends React.Component {
    render() {
        const { children, gutter, ...rest } = this.props;

        const childrenArray = React.Children.toArray(children).filter(child => {
            return child !== null;
        });

        return (
            <div {...rest}>
                {childrenArray.map((child, index) => {
                    return (
                        <div
                            key={child.key}
                            className={
                                index === childrenArray.length - 1
                                    ? null
                                    : `u-spacing-stack-${gutter}`
                            }
                        >
                            {child}
                        </div>
                    );
                })}
            </div>
        );
    }
}

Stack.propTypes = {
    children: PropTypes.node,
    gutter: PropTypes.string,
};

Stack.defaultProps = {
    gutter: 'm',
};

export default Stack;
