import Globalize from 'globalize';
import './globalize.culture.es-ES';
Globalize.addCultureInfo('es', 'es-ES');

import moment from 'moment';
import 'moment/locale/es';
moment.defineLocale('es-ES', { parentLocale: 'es' });

export default {
    code: 'es-ES',

    // bcode is here for the "backCode" ... the code as seen/sent by the back :O
    bCode: 'es_ES',

    name: 'Español',
    shortcut: 'es',
    supportEmail: 'asistencia@lcqds.es',
    privacyEmail: 'privacy@lacolmenaquedicesi.es',

    trainingSite: {
        url: 'http://formacion.lacolmenaquedicesi.es',
        password: '100abejasfelices',
    },

    appStores: {
        apple: 'https://itunes.apple.com/es/app/la-colmena-que-dice-s%C3%AD/id1052198033?mt=8',
        google: 'https://play.google.com/store/apps/details?id=com.lrqdo&hl=es',
    },

    supportSite: {
        widgetDomain: 'lacolmenaquedicesi.zendesk.com',
        homepage: 'https://lacolmenaquedicesi.zendesk.com/hc/es',
        host:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/categories/200129422-RESPONSABLES-DE-COLMENA',
        farmer:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/categories/200129442-PRODUCTORES-Y-ELABORADORES',
        member:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/categories/200127541-CONSUMIDORES-DE-LAS-COLMENAS',
        user:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/categories/200127541-CONSUMIDORES-DE-LAS-COLMENAS',
        kycInformation:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/articles/208514805-Informaci%C3%B3n-complementaria-requerida-para-el-pago',
        proImprovementsHost:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/articles/208588405-Nuevas-mejoras-en-tu-espacio-profesional',
        proImprovementsProd:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/articles/207858469-Nuevas-mejoras-en-tu-carpeta-de-productor',
        farmerInterregionalCatalog:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/articles/115002220085',
    },

    logos: {
        brown: '/assets/images/logos/brown/logo-es.svg',
        turquoise: '/assets/images/logos/turquoise/logo-es.svg',
        white: '/assets/images/logos/white/logo-es.svg',
        whiteOutlined: '/assets/images/logos/whiteOutlined/logo-es.svg',
        blue: '/assets/images/logos/blue/logo-es.svg',
        christmas: '/assets/images/logos/christmas/logo-es.svg',
    },

    social: {
        facebook: 'https://www.facebook.com/lacolmenaquedicesi',
        twitter: 'https://twitter.com/ColmenaQdiceSi',
        blog: 'http://blog.lacolmenaquedicesi.es/',
        instagram: 'https://instagram.com/lacolmenaquedicesi',
        youtube: 'https://www.youtube.com/channel/UCdFX4fozPU3ZRbC2nAsSD_w',
        linkedin: 'https://www.linkedin.com/company/%C2%A1la-colmena-que-dice-s%C3%AD-/',
    },

    links: {
        about: 'https://nosotros.lacolmenaquedicesi.es/',
        press: 'https://nosotros.lacolmenaquedicesi.es/prensa/',
        joinUs: 'https://nosotros.lacolmenaquedicesi.es/equipo/',
        order: 'https://lacolmenaquedicesi.es/es/assemblies',
        giftCards: 'https://lacolmenaquedicesi.es/es/giftcards',
        openRuche: 'https://lacolmenaquedicesi.es/es/p/open',
        provideRuche: 'https://lacolmenaquedicesi.es/es/p/provide',
        proposeLocal: '',
    },

    proposeLocalForm:
        'https://docs.google.com/forms/d/e/1FAIpQLSc1DShLbOyI4Y-d8lU-8j-gOafFOEGbFTSop7iF8Ab9iRQ6Kw/viewform',

    dateFormats: {
        d: 'DD/MM/YYYY',
        D: 'dddd DD [de] MMMM [de] YYYY',
        I: 'dddd DD [de] MMMM',
        i: 'DD [de] MMMM [de] YYYY',
        t: 'HH:mm',
        T: 'HH:mm:ss',
        f: 'dddd, DD [de] MMMM [de] YYYY HH:mm',
        F: 'dddd, DD [de] MMMM [de] YYYY HH:mm:ss',
        M: 'DD MMMM',
        Y: 'MMMM [de] YYYY',
        shortDayDate: 'ddd DD [de] MMMM',
        dateWithoutYear: 'DD/MM',
    },

    inlineLogo: require('./logo.svg'),
    zendeskLocale: 'es',
    zendeskSearch: 'https://lacolmenaquedicesi.zendesk.com/api/v2/help_center/',
    zendeskCategories: {
        farmer: '200129442',
        member: '200127541',
        user: '200127541',
        host: '200129422',
    },

    cguPage: 'https://lacolmenaquedicesi.es/es/p/terms',
    homepage: 'https://lacolmenaquedicesi.es',
    minirucheHelpCenter:
        'https://lacolmenaquedicesi.zendesk.com/hc/es/categories/360002312937-MINI-COLMENAS',
};
